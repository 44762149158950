import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display:flex;
    flex-direction:column;
`;

export const Container = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    align-items:center;
`;

export const Select = styled.select`
    ${({ theme }) => css`
        width:100%;
        height: 40px;
        outline:none;
        border:0;
        border-bottom:1px solid ${theme.colors.lightGray};
        padding:0 ${theme.spacings.xxsmall};
        margin:${theme.spacings.xxxsmall};
        color:${theme.colors.primary};
        background-color:${theme.colors.white};

        &.no-selected {
            color:${theme.colors.gray}; 
        }

        &:focus {
            transition:.1s;
            border-bottom:1px solid ${theme.colors.primary}; 
        }
    `}
`;
