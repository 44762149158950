import React from 'react';
import moment from 'moment';
import * as Styles from './styles';
import { meteoblue } from '../../../../utils/helpers/weather';

const CardWeather = ({ item }) => {
    const {
        label,
        max_temp,
        type,
        is_day,
        precip_probabil,
        precip_intensity,
    } = item;

    return (
        <Styles.Container>
            <Styles.Date>{moment(new Date(label)).format('DD/MM')}</Styles.Date>
            <Styles.Hour>
                {moment(new Date(label)).format('HH')}
                h
            </Styles.Hour>
            <Styles.Card>
                <img src={meteoblue[is_day][type - 1]} alt="imagem de um sol" />
                <Styles.Temperature>
                    {max_temp}
                    º
                </Styles.Temperature>
                <Styles.Rain>
                    {precip_probabil}
                    %
                    {' '}
                    {precip_intensity}
                    mm
                </Styles.Rain>
            </Styles.Card>
        </Styles.Container>
    );
};

export default CardWeather;
