const VALIDATE_CODE = /* GraphQL */ `

query VALIDATE_CODE($email:String, $code:String){
    validateCode(email:$email, code:$code){
        error
        message
        code
    }
  }
`;

export default VALIDATE_CODE;
