import { gql } from 'graphql-request';

const REMOVE_USER = gql`
    mutation Remove($id:ID!){
        userRemove(id:$id){
            removed
        }
    }
`;

export default REMOVE_USER;
