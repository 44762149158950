import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import * as Styles from './styles';

const GroupFormButton = ({
    addButton = false, handleAction, handleSave, label = 'Cancelar', labelConfirm = 'Salvar', confirmDisabled = false, ...rest
}) => {
    const area = useSelector((state) => state.area);
    const farm = useSelector((state) => state.farm);
    const user = useSelector((state) => state.user);

    return (
        <Styles.Footer>
            {user.loading
                ? (
                    <button {...rest} type="submit" disabled>
                        <FaSpinner color="#FFF" size={16} />
                    </button>
                )
                : (
                    <>
                        {addButton && <button type="button" className="add" {...rest} onClick={handleAction}>{label}</button>}
                        {area.loadingAdd || farm.loading
                            ? (
                                <button {...rest} type="submit" disabled>
                                    <FaSpinner color="FFF" size={16} />
                                </button>
                            )
                            : <button {...rest} className="confirm" disabled={confirmDisabled} type="submit" onClick={handleSave}>{labelConfirm}</button>}
                    </>
                )}

        </Styles.Footer>
    );
};
export default GroupFormButton;
