import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display:flex;
    flex-direction:column;
`;

export const Container = styled.div`
    ${({ theme }) => css`
        
        label {
            display:flex;
            justify-content:center;
            font-size:${theme.font.sizes.xsmall};
            margin-bottom:${theme.spacings.small};
            color:${theme.colors.black};
            font-weight:bold;
            text-align:center;
        }
    `}
`;
