import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        display:flex;
        flex-direction:column;
        align-self:stretch;
        position:relative;
        border-radius:${theme.border.radius};
        box-shadow:.2rem .2rem .5rem rgba(0,0,0,0.1);
        background:${theme.colors.white};
        box-shadow:2px 2px 5px rgba(0,0,0,0.5);
        padding:${theme.spacings.medium} ${theme.spacings.xlarge};
    `}
`;

export const CardUp = styled.div`
    ${({ theme }) => css`
        position:absolute;
        top:-40px;
        left: 1rem;
        right: 1rem;
        box-shadow: ${theme.box.shadow};
        // height:120px;
        border-radius: ${theme.border.radius};
        background: rgb(16,49,62);
        background: linear-gradient(90deg, rgba(16,49,62,1) 0%, rgba(0,88,107,1) 100%);
        padding: ${theme.spacings.small};
        margin-bottom: ${theme.spacings.small};
        display: flex;
        align-items: center;
        justify-content:center;
        flex-direction: column;
    `}
`;

export const Form = styled.form`
    ${({ theme }) => css`
        margin-top:${theme.spacings.xxsmall};
        button { margin-top:${theme.spacings.xxsmall}; }
    `}
`;
