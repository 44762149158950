import React, { useCallback, useState } from 'react';
import { Button } from '@material-ui/core';
import { MdEdit } from 'react-icons/md';

import * as Styles from './styles';

import imageProfileDefault from '../../../../../assets/images/perfil.png';

const UpdateImage = () => {
    const [profileImage, setProfileImage] = useState(null);

    const loadProfileImage = useCallback(() => (profileImage != null ? profileImage : imageProfileDefault), [profileImage]);

    const handleProfileImageChange = (e) => {
        if (e && e.target && e.target.files != null) {
            const file = e.target.files[0];
            if (file?.type) {
                if (['image/jpeg', 'image/png'].includes(file.type)) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = () => {
                        setProfileImage(reader.result);
                    };
                }
            }
        }
    };

    return (
        <Styles.UpdateImageDiv>
            <label htmlFor="fileArchive">
                <Styles.ImageProfile src={loadProfileImage().toString()} />
            </label>
            <Button variant="contained" component="label" title="Editar imagem">
                <MdEdit size={22} />
                <input
                    type="file"
                    id="fileArchive"
                    style={{ display: 'none' }}
                    accept=".jpg,.png"
                    onChange={(e) => handleProfileImageChange(e)}
                />
            </Button>
        </Styles.UpdateImageDiv>
    );
};

export default UpdateImage;
