import styled, { css } from 'styled-components';

export const Container = styled.button`
    ${({ theme, hasBackground }) => css`
        cursor:pointer;
        background:${hasBackground ? theme.colors.danger : 'none'};
        padding:${theme.spacings.xsmall} ${theme.spacings.xxsmall};
        border-radius:${theme.border.radius};
        display:flex;
        align-items:center;
        justify-content:center;

        span {
            font-weight:normal;
            color:${hasBackground ? theme.colors.white : theme.colors.gray};
            font-size:${theme.font.sizes.small};
            margin-right:${theme.spacings.xxsmall};
        }
        &:hover {
            transition:.5s;
            opacity: ${hasBackground ? 0.7 : 'none'};
        }
    
    `}
`;

export const Label = styled.span`
    ${({ theme, hasBackground }) => css`
        font-weight:normal;
        color:${hasBackground ? theme.colors.white : theme.colors.gray};
        font-size:${theme.font.sizes.small};
        margin-right:${theme.spacings.xxsmall};
    `}
`;
