import styled, { css } from 'styled-components';
import { Field } from 'formik';

export const Wrapper = styled.div``;

export const Menu = styled.ul`
    ${({ theme }) => css`
        list-style-type:none;
        width:100%;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction:row;
        margin:${theme.spacings.small} 0;

        .active {
            color:${theme.colors.white};
            background:${theme.colors.primary};  
        }

        li {
            margin: 0 ${theme.spacings.small};
            cursor:pointer;
            flex:1;
            text-align:center;
            background: ${theme.spacings.lightGray};
            padding:${theme.spacings.small};
            color:${theme.colors.black};
            border-radius:${theme.border.radius};

            &:hover {
                transform:scale(1.05);
                transition:.5s;
                color:${theme.colors.white};
                background:${theme.colors.primary};
            }
        }

    `}
`;

export const SelectGround = styled.div`
    ${({ theme }) => css`
        span {
            display:block;
            margin-bottom:${theme.spacings.xsmall};
            color:${theme.colors.gray};
            font-size:${theme.font.sizes.xsmall};
        }
    `}
`;

export const Options = styled.div`
    display:flex;
    flex-direction:row;
    overflow-x:auto;
    flex-wrap:wrap;
    
`;

export const TextField = styled(Field)`
    width: 97.5%;
`;

export const WrapperInput = styled.div`
    ${({ theme }) => css`
        input {
            color:${theme.colors.primary};
        }
    `}
`;
