import React, { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2';

import { useDispatch, useSelector } from 'react-redux';
import Container from '../../../components/Container';
import Card from '../../../components/Card';
import Button from '../../../components/Button';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import * as Styles from './styles';

import iconEmail from '../../../assets/svgs/icon_email.svg';
import iconPassword from '../../../assets/svgs/icon_password.svg';

import { signInRequest } from '../../../store/modules/auth/actions';
import {
    userUpdatePassword, userClearState,
    userRequestPasswordResetCode,
    userValidatePasswordResetCode,
} from '../../../store/modules/user/actions';

const SignIn = () => {
    const dispatch = useDispatch();
    const { loading, integrity, response } = useSelector((state) => state.user);

    const [recover, setRecover] = useState(false);
    const [recoverStep, setRecoverStep] = useState(0);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const handleSubmit = useCallback(() => {
        if (email === '' && password === '') {
            Swal.fire({
                title: 'Ops!', text: 'Você esqueceu de preencher o e-mail e a senha.', icon: 'error', allowEnterKey: false,
            });
        } else if (email === '') {
            Swal.fire({
                title: 'Ops!', text: 'Você esqueceu de preencher o e-mail.', icon: 'error', allowEnterKey: false,
            });
        } else if (password === '') {
            Swal.fire({
                title: 'Ops!', text: 'Você esqueceu de preencher a senha.', icon: 'error', allowEnterKey: false,
            });
        } else dispatch(signInRequest(email, password));
    }, [dispatch, email, password]);

    const handleKeyPress = useCallback((event) => {
        const sweetAlert = document.querySelector('div.swal2-header');
        if (event.keyCode === 13 && !sweetAlert) handleSubmit();
    }, [handleSubmit]);

    const handleRequestCode = () => {
        if (email === '') Swal.fire('Ops!', 'Você esqueceu de preencher o e-mail.', 'error');
        else dispatch(userRequestPasswordResetCode(email));
    };

    const handleValidateCode = (value) => {
        setCode(value);
        if (value.length === 6) dispatch(userValidatePasswordResetCode(email, value.toUpperCase()));
    };

    const handleResetPassword = () => {
        if (newPassword === '' || confirmNewPassword === '') Swal.fire('Ops!', 'Você esqueceu de preencher a senha.', 'error');
        else if (newPassword !== confirmNewPassword) Swal.fire('Ops!', 'As senhas devem ser iguais.', 'error');
        else { dispatch(userUpdatePassword(newPassword, confirmNewPassword, integrity)); }
    };

    const handleClearState = useCallback(() => {
        dispatch(userClearState());
        setEmail('');
        setPassword('');
        setCode('');
        setNewPassword('');
        setConfirmNewPassword('');
        setRecoverStep(0);
        setRecover(false);
    }, [dispatch]);

    useEffect(() => {
        switch (response) {
        case 'REQUEST_CODE_ERROR':
            setRecoverStep(0);
            break;
        case 'REQUEST_CODE_SUCCESS':
            setRecoverStep(1);
            break;
        case 'VALIDATE_CODE_ERROR':
            setRecoverStep(1);
            break;
        case 'VALIDATE_CODE_SUCCESS':
            setRecoverStep(2);
            break;
        case 'UPDATE_PASSWORD_ERROR':
            setRecoverStep(2);
            break;
        case 'UPDATE_PASSWORD_SUCCESS':
            handleClearState();
            break;
        default:
            break;
        }
    }, [response, handleClearState]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => document.removeEventListener('keydown', handleKeyPress);
    }, [handleKeyPress]);

    return (
        <Styles.BgContainer>
            <Header />
            <Container>
                <Styles.Content>
                    <Card
                        cardUp
                        title={recover ? 'Redefinir senha' : 'Login'}
                        onSubmit={(e) => e.preventDefault()}
                    >
                        {recover
                            ? (
                                <>
                                    {recoverStep === 0
                                        && (
                                            <>
                                                <Styles.CustomInput
                                                    iconPath={iconEmail}
                                                    placeholder="E-mail"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    autocomplete="off"
                                                />

                                                <Styles.GroupButton>
                                                    {!loading && <Button label="Cancelar" onClick={handleClearState} />}
                                                    <Button label="Redefinir senha" onClick={handleRequestCode} loading={loading} />
                                                </Styles.GroupButton>

                                            </>
                                        )}
                                    {recoverStep === 1
                                        && (
                                            <Styles.ContainerCode>
                                                <p>Para prosseguir, entre com o código de recuperação recebido por e-mail.</p>
                                                <Styles.CustomInput
                                                    type="text"
                                                    iconPath={iconPassword}
                                                    maxLength={6}
                                                    value={code}
                                                    onChange={(e) => handleValidateCode(e.target.value)}
                                                    autoCapitalize="characters"
                                                    autocomplete="off"
                                                    placeholder="Código de verificação"
                                                />
                                                <Button label="Cancelar" onClick={handleClearState} />
                                            </Styles.ContainerCode>
                                        )}
                                    {recoverStep === 2
                                        && (
                                            <>
                                                <Styles.CustomInput
                                                    type="password"
                                                    iconPath={iconPassword}
                                                    value={newPassword}
                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                    autoCapitalize="characters"
                                                    autocomplete="off"
                                                    placeholder="Nova senha"
                                                />

                                                <Styles.CustomInput
                                                    type="password"
                                                    iconPath={iconPassword}
                                                    value={confirmNewPassword}
                                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                                    autocomplete="off"
                                                    placeholder="Confirmar nova senha"
                                                />
                                                <Styles.GroupButton>
                                                    {!loading && <Button label="Cancelar" onClick={handleClearState} />}
                                                    <Button label="Redefinir senha" onClick={handleResetPassword} loading={loading} />
                                                </Styles.GroupButton>

                                            </>
                                        )}
                                </>
                            ) : (
                                <>
                                    <Styles.CustomInput iconPath={iconEmail} placeholder="E-mail" onChange={(e) => setEmail(e.target.value)} />
                                    <Styles.CustomInput
                                        type="password"
                                        iconPath={iconPassword}
                                        placeholder="Senha"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <Styles.WrapperButton>
                                        <Button label="Entrar" onClick={handleSubmit} />
                                    </Styles.WrapperButton>
                                    <Styles.WrapperRecoverButton>
                                        <Styles.RecoverButton type="button" onClick={() => setRecover(true)}>
                                            Esqueci minha senha
                                        </Styles.RecoverButton>
                                    </Styles.WrapperRecoverButton>
                                </>
                            )}
                    </Card>
                </Styles.Content>
            </Container>
            <Footer />
        </Styles.BgContainer>
    );
};

export default SignIn;
