import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        margin-bottom:${theme.spacings.xsmall};
    `}
`;
export const Wrapper = styled.div`
    ${({ theme }) => css`
        margin-bottom:${theme.spacings.xxsmall};
    `}
`;
export const Label = styled.span`
    ${({ theme, borderColor }) => css`
        color:${theme.colors.black};
        font-size:${theme.font.sizes.xxsmall};
        border-left:4px solid ${borderColor};
        padding-left:.4rem;
    `}
`;
