import { gql } from 'graphql-request';

export const ADD_MANAGER = gql`
    mutation Add($name:String!, $email:String!, $phone:String!, $password:String!, $roleId:ID! $userCreatorId:ID!){
        userAdd(name:$name, email:$email, phone:$phone, password:$password, role_id:$roleId, reports_to:$userCreatorId){
            id
            name
            role {
                id
                name
            }
        }
    }
`;

export const ADD_CONSULTANT = gql`
mutation Add($name:String!, $email:String!, $phone:String!, $password:String!, $roleId:ID! $userCreatorId:ID!){
    userAdd(name:$name, email:$email, phone:$phone, password:$password, role_id:$roleId, reports_to:$userCreatorId){
        id
        name
        role {
            id
            name
        }
    }
}
`;

export const ADD_CUSTOMER = gql`
mutation Add($name:String!, $email:String!, $phone:String!, $password:String!, $roleId:ID! $userCreatorId:ID!){
    userAdd(name:$name, email:$email, phone:$phone, password:$password, role_id:$roleId, reports_to:$userCreatorId){
        id
        name
        role {
            id
            name
        }
    }
}
`;
