import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import * as Styles from './styles';

import GroupFormButton from '../../../../GroupFormButton';

import iconPassword from '../../../../../assets/svgs/icon_password.svg';
import imageProfileDefault from '../../../../../assets/images/perfil.png';

import { userValidatePasswordResetCode, userUpdatePassword } from '../../../../../store/modules/user/actions';

const ResetPassword = ({ handleBack }) => {
    const dispatch = useDispatch();
    const { data: user, response, integrity } = useSelector((state) => state.user);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        if (response === 'VALIDATE_CODE_SUCCESS') dispatch(userUpdatePassword(password, confirmPassword, integrity));
    }, [password, confirmPassword, dispatch, response, integrity]);

    const handleOnSubmit = useCallback((values) => {
        dispatch(userValidatePasswordResetCode(user.email, values.code.toUpperCase()));
        setPassword(values.password);
        setConfirmPassword(values.confirmPassword);
    }, [dispatch, user.email]);

    const validationSchemaFields = Yup.object().shape({
        code: Yup.string().min(6, 'O código deve ter 6 caracteres').required('Campo obrigatório'),
        password: Yup.string().required('Campo obrigatório'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Senha não corresponde').required('Campo obrigatório'),
    });

    return (
        <Formik
            initialValues={{
                code: '',
                password: '',
                confirmPassword: '',
            }}
            validationSchema={validationSchemaFields}
            onSubmit={handleOnSubmit}
        >
            {(form) => (
                <>
                    <Styles.Container>
                        <Styles.ImageProfile src={imageProfileDefault} />
                        <Styles.Form>
                            <Field
                                type="text"
                                name="code"
                                as={Styles.CustomInput}
                                iconPath={iconPassword}
                                onChange={form.handleChange}
                                value={form.values.code}
                                error={form.errors.code}
                                touched={form.touched.code}
                                placeholder="Código de verificação"
                                autoComplete="off"
                                maxLength={6}
                            />
                            <Field
                                type="password"
                                name="password"
                                as={Styles.CustomInput}
                                iconPath={iconPassword}
                                onChange={form.handleChange}
                                value={form.values.password}
                                error={form.errors.password}
                                touched={form.touched.password}
                                placeholder="Senha"
                                autoComplete="off"
                            />
                            <Field
                                type="password"
                                name="confirmPassword"
                                as={Styles.CustomInput}
                                iconPath={iconPassword}
                                onChange={form.handleChange}
                                value={form.values.confirmPassword}
                                error={form.errors.confirmPassword}
                                touched={form.touched.confirmPassword}
                                placeholder="Confirmar Senha"
                                autoComplete="off"
                            />
                        </Styles.Form>
                    </Styles.Container>
                    <GroupFormButton
                        addButton
                        label="Voltar"
                        handleAction={handleBack}
                        handleSave={form.handleSubmit}
                    />
                </>
            )}
        </Formik>
    );
};

export default ResetPassword;
