import React from 'react';
import IconSvg from '../IconSvg';
import * as Styles from './styles';

function CardGraph({ title, children, ...rest }) {
    return (
        <Styles.Container {...rest}>
            <Styles.Header>
                <IconSvg {...rest} />
                <Styles.Title>{title}</Styles.Title>
            </Styles.Header>
            <Styles.Body>
                {children}
            </Styles.Body>
        </Styles.Container>
    );
}

export default CardGraph;
