import React, { useMemo, Fragment } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import * as Styles from './styles';

import IconSvg from '../IconSvg';
import tetaIcon from '../../assets/svgs/teta.svg';
import imageUser from '../../assets/images/perfil.png';
import { accessRole } from '../../utils/functions';

const ContainerFarm = () => {
    const { dataArea, loadingAverage } = useSelector((state) => state.area);
    const { data: user } = useSelector((state) => state.user);

    const { bh_yesterday } = dataArea;

    const sumTotalRain = dataArea?.water?.reduce((acc, currentValue) => acc + currentValue.rain, 0);
    const sumTotalIrrigation = dataArea?.water?.reduce((acc, currentValue) => acc + currentValue.irrigation, 0);
    const memoIrrigationType = useMemo(() => dataArea?.area_irrigation_system?.irrigation_type?.name, [dataArea]);

    const bh = [bh_yesterday] ?? [];

    const twoDecimalPlaces = (value) => {
        const number = Number(value)?.toFixed(2);
        return String(number).replace(/\.00$/, '');
    };

    const dataFarm = [
        {
            id: 2,
            title: 'Condições atuais de cultivo',
            data: [

                {
                    title: 'Chuva Total',
                    value: `${sumTotalRain > 0 ? sumTotalRain?.toFixed(2).toString().replace('.', ',') : '0'} mm`,
                },

                {
                    title: 'Irrigação Total',
                    value: `${sumTotalIrrigation > 0 ? sumTotalIrrigation?.toFixed(2).toString().replace('.', ',') : '0'} mm`,
                },

                accessRole(user.role.name) && {
                    title: 'Temperatura média no ciclo:',
                    value: loadingAverage ? <Skeleton height={15} width={80} /> : `${(Math.round(dataArea?.average_temp_since * 100) / 100).toString().replace('.', ',')} ºC`,
                },
                accessRole(user.role.name) && {
                    title: 'ETc (ontem)',
                    value: `${bh_yesterday ? (Math.round(bh_yesterday?.etc * 100) / 100).toString().replace('.', ',') : 0} mm`,
                },
                {
                    title: 'ETc acumulada:',
                    value: `${(Math.round(dataArea?.etc_sum_since * 100) / 100).toString().replace('.', ',')} mm`,
                },

                accessRole(user.role.name) && {
                    title: 'Água de fácil absorção',
                    value: bh_yesterday ? `${(Math.round(bh[0]?.AFDf * 100) / 100).toString().replace('.', ',')} mm` : '0 mm',

                },
                accessRole(user.role.name) && {
                    title: 'Água de fácil absorção máxima',
                    value: bh_yesterday ? `${(Math.round(bh[0]?.AFD * 100) / 100).toString().replace('.', ',')} mm` : '0 mm',
                },
                accessRole(user.role.name) && {
                    title: 'CAD radicular',
                    value: bh_yesterday ? `${(Math.round(bh[0]?.cadRoot * 100) / 100).toString().replace('.', ',')} mm` : '0 mm',
                },
            ],
        },

        {
            id: 3,
            title: 'Irrigação',
            data: [
                {
                    title: 'Tipo de irrigação',
                    value: memoIrrigationType,
                },
                (memoIrrigationType === 'Gotejamento' || memoIrrigationType === 'Pivô central' || memoIrrigationType === 'Pivô central (LEPA)') && {
                    title: memoIrrigationType === 'Gotejamento' ? 'Lâmina (mm/h)' : 'Lâmina (percentímetro a 100%)',
                    value: dataArea?.area_irrigation_system?.irrigation_type?.name === 'Gotejamento'
                        ? `${twoDecimalPlaces(dataArea?.area_irrigation_system?.leaf ?? 0).toString().replace('.', ',')} mm/h`
                        : `${twoDecimalPlaces(dataArea?.area_irrigation_system?.leaf ?? 0).toString().replace('.', ',')} mm`,
                },
                {
                    title: 'Eficiência',
                    value: `${twoDecimalPlaces((dataArea?.area_irrigation_system?.efficiency ?? 0) * 100)}%`,
                },
                accessRole(user.role.name) && {
                    title: dataArea?.area_irrigation_system?.irrigation_type?.name === 'Gotejamento' ? 'Espaçamento entre gotejadores' : null,
                    value: `${twoDecimalPlaces(dataArea?.area_irrigation_system?.drippingSpacing ?? 0).toString().replace('.', ',')} m`,
                },
                accessRole(user.role.name) && {
                    title: dataArea?.area_irrigation_system?.irrigation_type?.name === 'Gotejamento' ? 'Vazão do gotejador' : null,
                    value: `${twoDecimalPlaces(dataArea?.area_irrigation_system?.drippingFlow ?? 0).toString().replace('.', ',')} l/h`,
                },

                accessRole(user.role.name) && {
                    title: dataArea?.area_irrigation_system?.irrigation_type?.name === 'Gotejamento' || dataArea?.area_irrigation_system?.irrigation_type?.name === 'Pivô central (LEPA)' ? 'PAS' : null,
                    value: `${twoDecimalPlaces(dataArea?.pas ?? 0).toString().replace('.', ',')}%`,
                },
                accessRole(user.role.name) && {
                    title: dataArea?.area_irrigation_system?.irrigation_type?.name === 'Gotejamento' || dataArea?.area_irrigation_system?.irrigation_type?.name === 'Pivô central (LEPA)' ? 'PAM' : null,
                    value: `${twoDecimalPlaces(dataArea?.pam ?? 0).toString().replace('.', ',')}%`,
                },
                accessRole(user.role.name) && {
                    title: dataArea?.area_irrigation_system?.irrigation_type?.name === 'Gotejamento' || dataArea?.area_irrigation_system?.irrigation_type?.name === 'Pivô central (LEPA)' ? 'Kl' : null,
                    value: `${twoDecimalPlaces((dataArea?.kl ?? 0) * 100).toString().replace('.', ',')}%`,
                },
                accessRole(user.role.name) && {
                    title: dataArea?.area_irrigation_system?.irrigation_type?.name === 'Gotejamento' ? 'Ks' : null,
                    value: `${twoDecimalPlaces((dataArea?.ks ?? 0) * 100).toString().replace('.', ',')}%`,
                },
            ],
        },

        {
            id: 4,
            title: 'Características do solo',
            data: [
                {
                    title: 'Tipo de Solo',
                    value: dataArea?.soil?.soil_type?.name,
                },
                accessRole(user.role.name) && {
                    title: 'tetaCC',
                    value: `${dataArea?.soil?.tetaCC?.toString().replace('.', ',')} cm³/cm³`,
                },
                accessRole(user.role.name) && {
                    title: 'tetaPMP',
                    value: `${dataArea?.soil?.tetaPMP?.toString().replace('.', ',')} cm³/cm³`,
                },
                accessRole(user.role.name) && {
                    title: 'Umidade do solo inicial',
                    value: `${dataArea?.soil?.soil_humidity}%`,
                },
                accessRole(user.role.name) && {
                    title: 'Profundidade do solo',
                    value: `${dataArea?.soil?.soil_depth} cm`,
                },

            ],
        },

        {
            id: 5,
            title: 'Outros',
            data: [
                {
                    title: 'Variedade',
                    value: dataArea?.area_crops?.variety,
                },
                {
                    title: 'População',
                    value: `${dataArea?.area_crops?.population.toString().replace('.', ',')} plantas/ha`,
                },
                {
                    title: 'Espaçamento da cultura',
                    value: `${dataArea?.area_crops?.spacing?.toString().replace('.', ',')} m`,
                },

            ],
        },

        {
            id: 1,
            title: dataArea?.farm?.name,
            data: [
                {
                    image: dataArea?.farm?.consultant?.profile_image ? dataArea?.farm?.consultant?.profile_image : imageUser,
                    title: 'Consultor',
                    value: dataArea?.farm?.consultant?.name,
                },
                {
                    image: dataArea?.farm?.customer?.profile_image ? dataArea?.farm?.customer?.profile_image : imageUser,
                    title: 'Produtor',
                    value: dataArea?.farm?.customer?.name,
                },
            ],
        },

    ];

    return (
        <Styles.Wrapper>
            {dataFarm?.map((farm) => (
                <Styles.Container key={farm?.id}>
                    <Styles.Section>
                        <Styles.Title>{farm?.title}</Styles.Title>
                    </Styles.Section>

                    <Styles.Content>
                        {farm?.data.map((data, index) => (
                            <Fragment key={index}>
                                {data?.title ? (
                                    <Styles.Group key={data?.title}>
                                        {data?.title === 'tetaCC' || data?.title === 'tetaPMP' || data?.title === 'Consultor' || data?.title === 'Produtor'
                                            ? (
                                                <>
                                                    {data?.title === 'tetaCC' || data?.title === 'tetaPMP'
                                                        ? (
                                                            <Styles.WrapperIcon>
                                                                <IconSvg svgPath={tetaIcon} />
                                                                <Styles.Label>{data?.title === 'tetaCC' ? 'cc' : 'pmp'}</Styles.Label>
                                                            </Styles.WrapperIcon>
                                                        )
                                                        : (
                                                            <>
                                                                {/* <Styles.ImgPerfil
                                                                    src={data?.image}
                                                                /> */}
                                                                <Styles.Label>{data?.title}</Styles.Label>
                                                            </>
                                                        )}

                                                </>
                                            ) : <Styles.Label>{data?.title}</Styles.Label>}
                                        <Styles.Value>{data?.value}</Styles.Value>
                                    </Styles.Group>
                                ) : null}
                            </Fragment>
                        ))}
                    </Styles.Content>

                </Styles.Container>
            ))}

        </Styles.Wrapper>
    );
};

export default ContainerFarm;
