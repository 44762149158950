import React from 'react';
import * as Styles from './styles';

const CardUp = (title) => (
    <Styles.CardUp>
        <h4>{title}</h4>
    </Styles.CardUp>
);

const Card = ({
    cardUp = true, title, onSubmit, children,
}) => (
    <Styles.Container>
        {cardUp && CardUp(title)}
        <Styles.Form onSubmit={onSubmit}>
            {children}
        </Styles.Form>
    </Styles.Container>
);

export default Card;
