import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        background:${theme.colors.white};
        border-radius:${theme.border.radius};
    `}
`;
export const Header = styled.div`
    ${({ theme }) => css`
        display:flex;
        align-items:center;
        flex-direction:column;
        justify-content:center;
        padding:${theme.spacings.medium};
    `}
`;
export const Img = styled.img`
        width:230px;
        height:230px;
        border-radius:150px;
        box-shadow:2px 2px 5px rgba(0,0,0,0.5);
`;

export const Name = styled.p`
    ${({ theme }) => css`
        margin-top:${theme.spacings.small};
        color:${theme.colors.black};
        font-size:${theme.font.sizes.large};
    `}
`;
export const Perfil = styled.p`
    ${({ theme }) => css`
        color:${theme.colors.gray};
        font-size:${theme.font.sizes.small};
    `}
`;

export const Nav = styled.nav`
    ${({ theme }) => css`
        display:flex;
        flex-direction:row;
        justify-content:space-evenly;
        background:#EAEAEA;
        margin-top:${theme.spacings.small};
        
    `}
`;
export const Menu = styled.a`
    ${({ theme, active }) => css`
        cursor:pointer;
        text-decoration:none;
        text-transform:uppercase;
        background:${active ? theme.colors.primary : 'none'};
        padding:${theme.spacings.small};
        width:100%;
        text-align:center;
        border-radius:${active ? theme.border.radius : '0px'};
        color:${active ? theme.colors.white : theme.colors.black};
        font-size:${theme.font.sizes.xsmall};
        box-shadow:${active ? '2px 2px 5px rgba(0,0,0,0.3)' : 'none'};
        transform: ${active ? 'scaleY(1.05)' : 'scaleY(1)'};

        &:first-child{
            border-radius:0px ${theme.border.radius} ${theme.border.radius} 0px;
        }

        &:hover {
            transition:.5s;
            border-radius:${theme.border.radius};
            box-shadow:${'2px 2px 5px rgba(0,0,0,0.3)'};
            transform: scaleY(1.05);
            color:${theme.colors.white};
            background:${theme.colors.primary};
            
            &:first-child{
                border-radius:0px ${theme.border.radius} ${theme.border.radius} 0px;
            }
            &:last-child {
                border-radius:${theme.border.radius} 0px 0px ${theme.border.radius};
            }
        }
    `}
`;

export const Body = styled.div`
    ${({ theme }) => css`
        padding:${theme.spacings.small};
    `}
`;
