import React from 'react';
import * as Styles from './styles';

const Icon = ({
    iconName, iconSize = 28, style, color = 'white', ...rest
}) => (
    <Styles.CustomIcon
        className="material-icons"
        style={{ fontSize: iconSize, color, ...style }}
        {...rest}
    >
        {iconName}
    </Styles.CustomIcon>
);

export default Icon;
