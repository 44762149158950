import produce from 'immer';

const INITIAL_STATE = {
    data: [],
    loading: false,
};

export default function consultant(state = INITIAL_STATE, action) {
    switch (action.type) {
    case '@consultant/CONSULTANTS_USER_REQUEST':
        return produce(state, (draft) => {
            draft.loading = true;
        });

    case '@consultant/CONSULTANTS_USER_SUCCESS':
        return produce(state, (draft) => {
            draft.loading = false;
            draft.data = action.payload.data;
        });

    case '@consultant/CONSULTANTS_USER_ERROR':
        return produce(state, (draft) => {
            draft.loading = false;
            draft.message = action.payload.message;
        });

    default:
        return state;
    }
}
