export const layoutLine = {
    width: '100%',
    autosize: false,
    images: [{
        source: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHwAAABiCAIAAAAdoCACAAABCUlEQVR4nO3SUQ2DQABEwV5Dg6kaxQQ+kICDpsFD64L5uDcKNi87tvP7yL2eesCMig4UHSg6UHSg6EDRgaIDRQeW17rpDdPp6UDRgaIDRQeKDhQdKDpQdKDoQNGBogNFB4oOFB0oOlB0oOjA+FxvvWE6PR0oOlB0oOhA0YGiA0UHig4UHSg6UHSg6EDRgaIDRQeKDhQdKDpQdGD89kNvmE5PB4oOFB0oOlB0oOhA0YGiA0UHig4UHSg6UHSg6EDRgaIDRQeKDhQdKDpQdKDoQNGBogNFB4oOFB0oOlB0oOhA0YGiA0UHig4UHSg6UHSg6EDRgaIDRQeKDhQdKDpQdKDoQNGBogNFB/7aUQhLoDuipgAAAABJRU5ErkJggg==',
        sizing: 'stretch',
        x: 0,
        opacity: 0.5,
        y: 1,
        sizex: 1,
        sizey: 1,
        xref: 'paper',
        yref: 'paper',
        layer: 'below',
    }],
    xaxis: {
        linecolor: '#636363',
    },
    yaxis: {
        linecolor: '#636363',
        title: 'Umidade do solo (%), Temperatura do ar(°C)',
        range: [0, 100],
    },
    yaxis2: {
        linecolor: '#636363',
        overlaying: 'y',
        title: 'Etc (mm)',
        side: 'right',
        range: [0, 10],
    },
    clickmode: 'none',
    dragmode: false,
    legend: { orientation: 'h', x: 0, y: 1.3 },
};

export const layoutBar = {
    legend: { orientation: 'h', x: 0, y: 1.3 },
    autosize: false,
    clickmode: 'none',
    dragmode: false,
    yaxis: {
        range: [0, 20],
        fixedrange: true,
        linecolor: '#636363',
        title: 'Irrigação (mm)',
    },
    yaxis2: {
        overlaying: 'y',
        fixedrange: true,
        range: [0, 125],
        linecolor: '#636363',
        title: 'Chuva (mm)',
        side: 'right',
    },
};
