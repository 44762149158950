import React, { useEffect, useState } from 'react';
import * as Styles from './styles';

import IconToggle from '../../../../../assets/svgs/subtitles/humidity/icon_toggle.svg';
import IconExtra from '../../../../../assets/svgs/subtitles/humidity/icon_extra.svg';
import IconIdeal from '../../../../../assets/svgs/subtitles/humidity/icon_ideal.svg';
import IconWarning from '../../../../../assets/svgs/subtitles/humidity/icon_warning.svg';
import IconAlert from '../../../../../assets/svgs/subtitles/humidity/icon_alert.svg';

const SubtitleHumidity = ({ value, handleLegend }) => {
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        if (value) {
            setToggle(false);
            handleLegend(false);
        }
    }, [value, handleLegend]);

    return (
        <Styles.Container expanded={toggle} onClick={() => setToggle(!toggle)}>

            {!toggle
            && (
                <Styles.Group first style={{ display: toggle ? 'none' : 'flex' }}>
                    <img src={IconToggle} alt="gota de água" />
                    <span>Legenda</span>
                </Styles.Group>
            )}

            {toggle
            && (
                <Styles.List>

                    <Styles.Group expanded={toggle}>
                        <img src={IconExtra} alt="gota de água" />
                        <span>Umidade solo extra</span>
                    </Styles.Group>

                    <Styles.Group expanded={toggle}>
                        <img src={IconIdeal} alt="gota de água" />
                        <span>Umidade solo ideal</span>
                    </Styles.Group>

                    <Styles.Group expanded={toggle}>
                        <img src={IconWarning} alt="gota de água" />
                        <span>Umidade solo atenção</span>
                    </Styles.Group>

                    <Styles.Group expanded={toggle}>
                        <img src={IconAlert} alt="gota de água" />
                        <span>Déficit hídrico</span>
                    </Styles.Group>

                    <Styles.Group expanded={toggle}>
                        <img src={IconToggle} alt="gota de água" />
                        <span>Sem informações</span>
                    </Styles.Group>

                </Styles.List>
            )}
        </Styles.Container>
    );
};

export default SubtitleHumidity;
