import React from 'react';

import * as Styles from './styles';

export const CardIrrigation = ({ color, count, label }) => (
    <Styles.Container color={color}>
        <Styles.Count>{count}</Styles.Count>
        <Styles.Label>{label}</Styles.Label>
    </Styles.Container>
);
