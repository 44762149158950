import { gql } from 'graphql-request';
import { FRAGMENT_GET_USER } from '../fragments/user';

const GET_LOGIN = gql`
query Login($username:String!,$password:String!){
  userLogin(email:$username, password:$password){
      ${FRAGMENT_GET_USER}
   }
 }
`;

export default GET_LOGIN;
