import styled, { keyframes, css } from 'styled-components';

const animate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

export const Footer = styled.div`
    ${({ theme, add }) => css`
        display:flex;
        flex:1;
        justify-content:flex-end;
        padding:${theme.spacings.medium};
        .add {
            background:${'transparent'};
            color:${theme.colors.primary};
        }
        
        button {
            background:${add ? 'transparent' : theme.colors.primary};
            border-radius:${theme.border.radius};
            padding:${theme.spacings.xsmall} ${theme.spacings.medium};
            color:${add ? theme.colors.primary : theme.colors.white};
            outline:none;

            &:disabled{
                opacity: 0.6;
                cursor:not-allowed;
                width: 10.18rem; 
                height: 4.7rem;
                svg{
                    animation:  ${animate} 2s linear infinite;
                }
            }

            &.confirm:disabled{
                width: auto; 
                height: auto;
            }
        }
    `}
`;
