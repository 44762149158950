import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './config/ReactotronConfig';

import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { store, persistor } from './store';

import theme from './styles/theme';
import GlobalStyle from './styles/globalStyles';

import Routes from './routes';
import history from './services/history';

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Router history={history}>
                    <Routes />
                </Router>
            </ThemeProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);
