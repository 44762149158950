import styled, { css } from 'styled-components';

export const Container = styled.div`
        text {
            tspan {
            font-size:12px !important;
            }
        }
`;

export const Label = styled.span`
    ${({ theme }) => css`
        font-size:${theme.font.sizes.xxsmall};
    `}
`;
