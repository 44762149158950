import Swal from 'sweetalert2';
import { employeUpdate } from '../../../../store/modules/farm/actions';

export const handleOnActiveUser = (user, active, dispatch, userLogged) => {
    Swal.fire({
        title: `Deseja mesmo ${active ? 'ativar' : 'desativar'} o(a) ${user.name}?`,
        width: '43em',
        text: user.role.name !== 'Cliente'
            ? `Você está ${active ? 'ativando' : 'desativando'} um ${user.role.name}.`
            : '',
        icon: 'warning',
        confirmButtonText: 'Sim',
        confirmButtonColor: '#f1003b',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
    }).then((result) => {
        if (result.isConfirmed) {
            dispatch(employeUpdate(user.id, user.name, user.phone, active, userLogged));
        }
    });
};
