import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    background:#fff;
    padding:2rem;
    height:100%;
    width:100%;
`;

export const Separator = styled.div`
    ${({ theme }) => css`
        margin:${theme.spacings.large} 0;
    
    `}
`;

export const Grid = styled.div`
    ${({ theme }) => css`
        display:grid;
        grid-template-columns: 1.2fr 1fr;
        gap:${theme.spacings.large};
    `}
`;

export const Footer = styled.div`
    ${({ theme, add }) => css`
        display:flex;
        justify-content:flex-end;
        padding:${theme.spacings.medium};

        .add {
            background:${'transparent'};
            color:${theme.colors.primary};

        }
        button {
            background:${add ? 'transparent' : theme.colors.primary};
            border-radius:${theme.border.radius};
            padding:${theme.spacings.xsmall} ${theme.spacings.medium};
            color:${add ? theme.colors.primary : theme.colors.white};
            outline:none;
        }
    `}
`;
