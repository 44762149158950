import client from '../../../graphql/client';
import GET_FARMS_CONSULTANT from '../../../graphql/queries/getFarmsConsultant';
import GET_FARMS_CUSTOMER from '../../../graphql/queries/getFarmsCustomer';
import DISABLE_FARM from '../../../graphql/mutations/farm/disableById';
import REQUEST_EMPLOYEES from '../../../graphql/queries/farm/requestEmployees';
import FARM_ADD from '../../../graphql/mutations/farm/farmAdd';
import FARM_UPDATE from '../../../graphql/mutations/farm/farmUpdate';
import { handleSuccess, handleError } from '../../../utils/functions';
import GET_WEAHTHER_BY_FARM from '../../../graphql/queries/farm/getWeatherByFarm';
import UPDATE_USER from '../../../graphql/mutations/user/updateUser';

export async function requestFarmsConsultant({ payload }) {
    const { consultantId } = payload;
    try {
        const data = await client.request(GET_FARMS_CONSULTANT, { consultantId });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestFarmsCustomer({ payload }) {
    const { customerId } = payload;
    try {
        const data = await client.request(GET_FARMS_CUSTOMER, { customerId });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestDisableFarm({ payload }) {
    const { farmId } = payload;
    try {
        const data = await client.request(DISABLE_FARM, { farmId });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestEmployees({ payload }) {
    const { id } = payload;
    try {
        const data = await client.request(REQUEST_EMPLOYEES, { id });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestFarmAdd({ payload }) {
    const {
        name, cityId, consultantId, customerId, partners,
    } = payload;
    try {
        const data = await client.request(FARM_ADD, {
            name, cityId, consultantId, customerId, partners,
        });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestFarmUpdate({ payload }) {
    const {
        id, name, cityId, consultantId, customerId, partners,
    } = payload;
    try {
        const data = await client.request(FARM_UPDATE, {
            id, name, cityId, consultantId, customerId, partners,
        });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestWeatherByFarm({ payload }) {
    const {
        userId,
    } = payload;
    try {
        const data = await client.request(GET_WEAHTHER_BY_FARM, {
            userId,
        });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestEmployeeUpdate({ payload }) {
    const {
        idUser,
        name,
        phone,
        active,
    } = payload;
    try {
        const data = await client.request(UPDATE_USER, {
            id: idUser,
            name,
            phone,
            active,
        });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}
