import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import * as Styles from './styles';

function Button({ onClick, label, loading }) {
    return (
        <Styles.Container>
            {loading
                ? (
                    <button type="submit" disabled>
                        <FaSpinner color="FFF" size={16} />
                    </button>
                )
                : <button type="button" onClick={onClick}>{label}</button>}
        </Styles.Container>
    );
}

export default Button;
