import styled, { keyframes } from 'styled-components';

const animate = keyframes`
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
`;

export const FormContainer = styled.div`
    padding-top: 2rem;

    & .buttons-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin-top: 2rem;

        & button {
            outline: none;
            padding: 1.6rem 3.2rem;
            margin-top: 1rem;
            border-radius: 0.4rem;
            
            &.cancel {
                background-color: transparent;
                color: #43A047;
            }
            &.confirm {
                background-color: #43A047;
                color: white;
            }

            &:hover {
                opacity: .8;
                transition: .2s;
            }

            &:disabled{
                opacity: 0.6;
                cursor: default;
                width: 10.18rem; 
                height: 4.7rem;
                svg{ animation: ${animate} 2s linear infinite }
            }
        }
    }
`;

export const InputWrapper = styled.div`
    display:flex;
    flex-direction:column;
`;

export const InputContainer = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;

    & img {
        width: 20px;
        height: 20px;
    }
`;

export const InputContent = styled.div`
    width:100%;
    & input {
        width: 97.5%;
        height: 40px;
        outline:none;
        border: 0;
        border-bottom:1px solid #EEEEEE;
        padding: 0 1.2rem;
        margin: 0.4rem;
        color: #7B7373;

        &:focus {
            transition:.1s;
            border-bottom:1px solid #43A047; 
            &::placeholder {
                color: #43A047; 
            }
        }
    }
`;

export const InputErrors = styled.span`
    display:flex;
    height: 15px;
    width: 100%;
    color: #f1003b;
    font-size: 1.2rem;
    padding:0 0.8rem;
    margin: 0.8rem;
    margin-top: 0;
`;
