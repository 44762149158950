import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Styles from './styles';
import IconSvg from '../IconSvg';

import iconHome from '../../assets/svgs/icon_home.svg';
import iconReports from '../../assets/svgs/icon_reports.svg';
import iconExit from '../../assets/svgs/icon_exit.svg';
import iconReportIrrigation from '../../assets/svgs/icon_report_irrigation.svg';
import { showModal } from '../../store/modules/modal/actions';

const Menu = ({ fullScreen }) => {
    const dispatch = useDispatch();
    const { data: user } = useSelector((state) => state.user);
    const [extendedSideMenu, setExtendedSideMenu] = useState(false);
    const imgStyles = {
        width: fullScreen ? '120px' : '40px',
        height: fullScreen ? '120px' : '40px',
        borderRadius: fullScreen ? '60px' : '20px',
    };

    const handleLogout = useCallback(async () => {
        Swal.fire({
            title: 'Sair',
            html: '<p style="padding: 1.6rem 0;">Você deseja sair?<p>',
            allowOutsideClick: false,
            showCloseButton: false,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            showConfirmButton: true,
            confirmButtonText: 'Sair',
            confirmButtonColor: '#00586B',
            reverseButtons: true,
            padding: 32,
            preConfirm: () => {
                localStorage.clear();
                window.location.reload();
            },
        });
    }, []);

    const memoMenuReports = useMemo(() => {
        if (fullScreen) {
            return (
                <>

                    <Styles.ListFullScreen>
                        <Styles.MenuItemFullScreen fullScreen={fullScreen}>
                            <NavLink to="/reports" activeClassName="actived-link">
                                <IconSvg svgPath={iconReportIrrigation} />
                                <p className="label-full">Relatório Irrigação</p>
                            </NavLink>

                            <NavLink to="/reportsbh" activeClassName="actived-link">
                                <IconSvg svgPath={iconReportIrrigation} />
                                <p className="label-full">Relatório BH</p>
                            </NavLink>
                        </Styles.MenuItemFullScreen>

                        <Styles.Divider fullScreen={fullScreen} />

                        <Styles.MenuItemFullScreen>
                            <div onClick={handleLogout}>
                                <IconSvg svgPath={iconExit} />
                                <p className="label-full">Logout</p>
                            </div>
                        </Styles.MenuItemFullScreen>
                    </Styles.ListFullScreen>

                </>
            );
        }
        return null;
    }, [fullScreen, handleLogout]);

    const handleModalUser = useCallback(() => dispatch(showModal('ModalCustomer', { isOpen: true })), [dispatch]);

    return (
        <Styles.Container
            fullScreen={fullScreen}
            className={extendedSideMenu && !fullScreen ? 'extended-side-menu' : ''}
            onMouseOver={() => setExtendedSideMenu(true)}
            onMouseOut={() => setExtendedSideMenu(false)}
        >
            <Styles.ImgPerfil
                fullScreen={fullScreen}
                className={extendedSideMenu && !fullScreen ? 'extendedMarginImg' : ''}
                style={{ ...imgStyles }}
                onClick={handleModalUser}
                src={require('../../assets/images/perfil.png')}
            />
            <p className="user-name">{user.name}</p>

            <Styles.List fullScreen={fullScreen}>
                <Styles.MenuItem className={extendedSideMenu && !fullScreen ? 'content-menu' : ''}>
                    <NavLink exact to="/" activeClassName="actived-link">
                        <IconSvg svgPath={iconHome} />
                        <p className="labels">Home</p>
                    </NavLink>

                </Styles.MenuItem>

                {user.role.name !== 'Cliente'
                    && (
                        <Styles.MenuItem className={extendedSideMenu && !fullScreen ? 'content-menu' : ''}>
                            <NavLink to="/reports" activeClassName="actived-link">
                                <IconSvg svgPath={iconReports} />
                                <p className="labels">Reports</p>
                            </NavLink>
                        </Styles.MenuItem>
                    )}

                {!fullScreen
                    ? (
                        <>
                            <Styles.Divider className={extendedSideMenu && !fullScreen ? 'content-divider' : ''} />
                            <Styles.MenuItem className={extendedSideMenu && !fullScreen ? 'content-menu' : ''}>
                                <div onClick={handleLogout}>
                                    <IconSvg svgPath={iconExit} />
                                    <p className="labels">Logout</p>
                                </div>
                            </Styles.MenuItem>
                        </>
                    )
                    : <></>}
            </Styles.List>

            {/* Screen of reports */}
            {memoMenuReports}

        </Styles.Container>
    );
};

export default Menu;
