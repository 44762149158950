import React from 'react';
import * as Styles from './styles';
import Menu from '../Menu';

function Layout({ fullScreen, children }) {
    return (
        <Styles.Wrapper>
            <Menu fullScreen={fullScreen} />

            <Styles.Container fullScreen={fullScreen}>
                {children}
            </Styles.Container>
        </Styles.Wrapper>
    );
}

export default Layout;
