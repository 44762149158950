import { gql } from 'graphql-request';
import { FRAGMENT_GET_FARMS } from '../../fragments/farms';

const FARM_UPDATE = gql`
    mutation FARM_UPDATE($id: ID!, $name: String, $cityId: ID, $consultantId: ID, $customerId: ID, $partners: [ID]) {
        farmUpdate(id: $id, name: $name, city_id: $cityId, consultant_id: $consultantId, producer_id: $customerId, partners: $partners) {
            ${FRAGMENT_GET_FARMS}
        }
    }
`;

export default FARM_UPDATE;
