import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { FiFilter } from 'react-icons/fi';
import { GrCheckboxSelected, GrCheckbox } from 'react-icons/gr';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import * as Styles from './styles';

import PrivateRoute from '../../routes/PrivateRoute';
import PivotModal from './PivotModal';
import ModalMap from '../../components/Modals/ModalMap';

import InfoCard from '../../components/InfoCard';
import Area from '../../components/Area';
import CardAreaSkeleton from '../../components/Skeletons/CardAreaSkeleton';
import ButtonFloatMain from '../../components/ButtonFloatMain';

import iconGraph from '../../assets/svgs/icon_graph.svg';
import iconPin from '../../assets/svgs/icon_pin.svg';

import { areaRequestConsultant, areasMapRequest, cropsStartRequest } from '../../store/modules/area/actions';
import Layout from '../../components/Layout';
import { CardIrrigation } from '../../components/CardIrrigation';
import {
    COLOR_ATENCAO, COLOR_DEFICIT, COLOR_EXTRA, COLOR_IDEAL, irrigationCondition,
} from '../../utils/functions/area';

import { CardTemperature } from '../../components/CardTemperature';
import { weatherByFarm } from '../../store/modules/farm/actions';

const Main = () => {
    const dispatch = useDispatch();
    const { data: user } = useSelector((state) => state.user);
    const {
        data: area, loading, customers, dataCrops,
    } = useSelector((state) => state.area);

    const [waterDeficit, setWaterDeficit] = useState([]);
    const [active, setActivedAreas] = useState(true);
    const [customer, setCustomer] = useState([]);
    const [crops, setCrops] = useState([]);

    useEffect(() => {
        dispatch(areasMapRequest(user.id));
    }, []);

    useEffect(() => {
        if (!dataCrops.length) {
            dispatch(cropsStartRequest());
        } else {
            setCrops(dataCrops.map((crop) => Number(crop.id)));
        }
    }, [dataCrops]);

    useEffect(() => {
        dispatch(areaRequestConsultant(user.id, {
            waterDeficit: waterDeficit.length ? waterDeficit : null,
            active,
            crops: crops.length ? crops : null,
            producer: customer.length ? customer : null,
        }));
    }, [user.id,
        dispatch,
        user.role,
        waterDeficit,
        crops,
        customer,
        active]);

    useEffect(() => {
        dispatch(weatherByFarm(user.id));
    }, [user.id, dispatch]);

    const handleFilter = ({ target: { value } }) => {
        let isActive;
        switch (value) {
        case 'true':
            isActive = true;
            break;

        case 'false':
            isActive = false;
            break;
        default:
            isActive = null;
            break;
        }
        setActivedAreas(isActive);
    };

    const memoAreaTotal = useMemo(() => {
        const areaTotal = area.reduce((total, currentValue) => total + Number(currentValue.size), 0);
        return `≅ ${(areaTotal * 100).toFixed(2).replace('.', ',')} ha`;
    }, [area]);

    const memoCard = useMemo(() => area.map((area) => ({
        color: irrigationCondition(area.bh_yesterday?.ATD, area.bh_yesterday?.cadRoot).color,
        type: irrigationCondition(area.bh_yesterday?.ATD, area.bh_yesterday?.cadRoot).type,
    })), [area]);

    const getUniqueDataCount = (objArr, propName) => {
        const data = [];
        objArr.forEach((d) => {
            if (d[propName]) {
                data.push(d[propName]);
            }
        });
        const uniqueList = [...new Set(data)];

        const dataSet = {};
        for (let i = 0; i < uniqueList.length; i++) {
            dataSet[uniqueList[i]] = data.filter((x) => x === uniqueList[i]).length;
        }

        return dataSet;
    };

    const handleCheckFilters = (value) => {
        if (waterDeficit.includes(value)) {
            setWaterDeficit(waterDeficit.filter((label) => label !== value));
        } else {
            setWaterDeficit([...waterDeficit, value]);
        }
    };

    const handleCheckFiltersCrops = (event, crops) => {
        setCrops(crops.map((crop) => Number(crop.id)));
    };

    const handleFilterAreaByCustomer = (event, value) => {
        setCustomer(value.map((user) => Number(user.id)));
    };

    return (
        <Layout>

            <PrivateRoute path="/pivot/:id" component={PivotModal} />
            <Styles.Wrapper>
                <ModalMap styleMap={{ width: '65%', marginLeft: 'auto', marginRight: 'auto' }} hasMainPage />
                <CardTemperature />
            </Styles.Wrapper>

            <Styles.ContainerCards>

                <InfoCard
                    iconPath={iconGraph}
                    title="Área Total"
                    description={loading ? <Skeleton width={120} /> : memoAreaTotal}
                />
                <InfoCard
                    iconPath={iconPin}
                    title="Áreas"
                    description={loading ? <Skeleton width={120} /> : area.length}
                />

                {area.length ? (
                    <CardIrrigation
                        color={COLOR_DEFICIT}
                        label="Déficit hídrico"
                        count={getUniqueDataCount(memoCard, 'type')?.deficit ?? 0}
                    />
                ) : <Skeleton width={150} height={80} style={{ marginLeft: '1rem' }} />}

                {area.length ? (
                    <CardIrrigation
                        color={COLOR_IDEAL}
                        label="Umidade Ideal"
                        count={getUniqueDataCount(memoCard, 'type')?.ideal ?? 0}
                    />
                ) : <Skeleton width={150} height={80} style={{ marginLeft: '1rem' }} />}

                {area.length ? (
                    <CardIrrigation
                        color={COLOR_EXTRA}
                        label="Umidade Extra"
                        count={getUniqueDataCount(memoCard, 'type')?.extra ?? 0}
                    />
                ) : <Skeleton width={150} height={80} style={{ marginLeft: '1rem' }} />}

                {area.length ? (
                    <CardIrrigation
                        color={COLOR_ATENCAO}
                        label="Atenção"
                        count={getUniqueDataCount(memoCard, 'type')?.atencao ?? 0}
                    />
                ) : <Skeleton width={150} height={80} style={{ marginLeft: '1rem' }} />}
            </Styles.ContainerCards>

            <Styles.FiltersContainer>
                <Styles.FormControl>
                    <div className="auto-complete">
                        <Autocomplete
                            id="multiple-limit-tags"
                            multiple
                            disabled={loading}
                            options={dataCrops.map((crop) => crop)}
                            onChange={handleCheckFiltersCrops}
                            getOptionLabel={(option) => option.name}
                            style={{ width: '100%' }}
                            placeholder="Cultivos"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="cultivos"
                                />
                            )}
                        />
                    </div>
                </Styles.FormControl>

                <Styles.FormControl>
                    <div className="auto-complete">
                        <Autocomplete
                            id="multiple-limit-tags"
                            multiple
                            disabled={loading}
                            options={customers}
                            onChange={handleFilterAreaByCustomer}
                            onInputChange={() => setCustomer(false)}
                            getOptionLabel={(option) => option.name}
                            style={{ width: '100%' }}
                            placeholder="Encontrar produtor"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="produtores"
                                />
                            )}
                        />
                    </div>
                </Styles.FormControl>

                <Styles.FormControl>
                    <FormControlLabel
                        control={<Checkbox icon={<GrCheckbox />} checkedIcon={<GrCheckboxSelected />} />}
                        checked={waterDeficit.includes('deficit')}
                        onChange={() => handleCheckFilters('deficit')}
                        label="Filtrar por Déficit hidrico"
                    />
                </Styles.FormControl>

                <Styles.FormControl>
                    <FormControlLabel
                        control={<Checkbox icon={<GrCheckbox />} checkedIcon={<GrCheckboxSelected />} />}
                        checked={waterDeficit.includes('ideal')}
                        label="Filtrar por Umidade ideal"
                        onChange={() => handleCheckFilters('ideal')}
                    />
                </Styles.FormControl>

                <Styles.FormControl>
                    <FormControlLabel
                        control={<Checkbox icon={<GrCheckbox />} checkedIcon={<GrCheckboxSelected />} />}
                        checked={waterDeficit.includes('extra')}
                        onChange={() => handleCheckFilters('extra')}
                        label="Filtrar por Umidade extra"
                    />
                </Styles.FormControl>

                <Styles.FormControl>
                    <FormControlLabel
                        control={<Checkbox icon={<GrCheckbox />} checkedIcon={<GrCheckboxSelected />} />}
                        checked={waterDeficit.includes('attention')}
                        onChange={() => handleCheckFilters('attention')}
                        label="Filtrar por Atenção"
                    />
                </Styles.FormControl>

            </Styles.FiltersContainer>

            <Styles.FormControl>
                <div id="filter-active">
                    <label>
                        <FiFilter />
                        Filtro de áreas ativadas
                    </label>
                    <select onChange={handleFilter}>
                        <option value="">Todas</option>
                        <option value="true" selected>Ativadas</option>
                        <option value="false">Desativadas</option>
                    </select>
                </div>
            </Styles.FormControl>

            {loading ? <CardAreaSkeleton /> : <Area />}

            <ButtonFloatMain />
        </Layout>
    );
};

export default Main;
