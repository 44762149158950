import styled, { css } from 'styled-components';

export const Container = styled.div`
        tspan:9px !important;
`;

export const Label = styled.span`
    ${({ theme }) => css`
        font-size:${theme.font.sizes.xxsmall};
    `}
`;
