import React, { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import * as Styles from './styles';
import { areaRequestCustomer, requestCalculateBh } from '../../../../store/modules/area/actions';

export const HeaderPlot = ({ changeStartValue, changeEndValue }) => {
    const dispatch = useDispatch();
    const { data: areas, loading } = useSelector((state) => state.area);
    const { data: user } = useSelector((state) => state.user);

    const [selectedArea, setSelectedArea] = useState(null);

    const [calendarInitialActive, setCalendarInitialActive] = useState(false);
    const [valueInitial, setValueInitial] = useState(new Date(new Date() - (7 * 24 * 60 * 60 * 1000))); // 7 days ago

    const [calendarEndActive, setCalendarEndActive] = useState(false);
    const [valueEnd, setValueEnd] = useState(new Date());

    useEffect(() => {
        changeStartValue(new Date(new Date() - (7 * 24 * 60 * 60 * 1000)));
        changeEndValue(new Date());
    }, []);

    const labelLoading = loading ? 'Carregando' : 'Selecione uma área';

    useEffect(() => {
        if (!areas.length) {
            dispatch(areaRequestCustomer(user.id));
        }
    }, [dispatch, user.id]);

    useEffect(() => {
        if (selectedArea && valueInitial && valueEnd) {
            dispatch(requestCalculateBh(selectedArea?.id, valueInitial, valueEnd));
        }
    }, [valueInitial, selectedArea?.id, valueEnd]);

    const handleChangeCalendarInital = (date) => {
        setValueInitial(date);
        changeStartValue(date);
        setCalendarInitialActive(false);
    };

    const handleChangeCalendarEnd = (date) => {
        setValueEnd(date);
        changeEndValue(date);
        setCalendarEndActive(false);
    };

    const handleSelectedArea = async (event) => {
        const areaId = event.target.value;
        const filteredArea = areas.filter((area) => area.id === areaId)[0];
        await setSelectedArea(filteredArea);
    };

    return (
        <Styles.Container>

            <Styles.HeaderContent>
                <Styles.GroupSelect>
                    <label>Área:</label>
                    <select onChange={handleSelectedArea}>
                        {<option>{labelLoading}</option>}
                        {areas.map((area) => <option key={area.id} value={area.id}>{area.name}</option>)}
                    </select>
                </Styles.GroupSelect>

                <Styles.GroupSelect>
                    <label>Data Inicial:</label>
                    <Styles.GroupCalendar>
                        <input
                            value={format(valueInitial, 'dd/MM/yyyy')}
                            onFocus={setCalendarInitialActive}
                            readOnly
                        />

                        {calendarInitialActive && (
                            <Styles.CalendarContent>
                                <Calendar
                                    onChange={(e) => handleChangeCalendarInital(e)}
                                    value={valueInitial}
                                />
                            </Styles.CalendarContent>
                        )}
                    </Styles.GroupCalendar>
                </Styles.GroupSelect>

                <Styles.GroupSelect>
                    <label>Data Final:</label>
                    <Styles.GroupCalendar>
                        <input
                            value={format(valueEnd, 'dd/MM/yyyy')}
                            onFocus={setCalendarEndActive}
                            readOnly
                        />

                        {calendarEndActive && (
                            <Styles.CalendarContent>
                                <Calendar
                                    minDate={valueInitial}
                                    onChange={(e) => handleChangeCalendarEnd(e)}
                                    value={valueEnd}
                                />
                            </Styles.CalendarContent>
                        )}
                    </Styles.GroupCalendar>
                </Styles.GroupSelect>
            </Styles.HeaderContent>

            <Styles.Footer>
                <Styles.GroupData>
                    <span>Cultura: </span>
                    <span>{!selectedArea ? 'Selecione uma area' : selectedArea?.area_crops?.crop?.culture_name}</span>
                </Styles.GroupData>

                <Styles.GroupData>
                    <span>Data de plantio: </span>
                    <span>{!selectedArea ? 'Selecione uma area' : format(new Date(selectedArea?.area_crops?.sowing_date), 'dd/MM/yyyy')}</span>

                </Styles.GroupData>

                <Styles.GroupData>
                    <span>Inicio do monitoramento: </span>
                    <span>{!selectedArea ? 'Selecione uma area' : format(new Date(selectedArea?.created_at), 'dd/MM/yyyy')}</span>

                </Styles.GroupData>
            </Styles.Footer>

        </Styles.Container>
    );
};
