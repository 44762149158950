// import isWithinInterval from 'date-fns/isWithinInterval';

import { isWithinInterval, isBefore } from 'date-fns';

export function normalizeString(str) {
    return str.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function listToMatrix(list, elementsPerSubArray) {
    const matrix = [];
    let i;
    let k;

    for (i = 0, k = -1; i < list.length; i++) {
        if (i % elementsPerSubArray === 0) {
            k++;
            matrix[k] = [];
        }
        matrix[k].push(list[i]);
    }

    return matrix;
}

export function timestampToDateTimeShort(timestamp) {
    return new Date(parseInt(timestamp)).toLocaleDateString('pt-BR', { dateStyle: 'short' });
}

export function checkForHarvest(date) {
    if (date !== undefined) {
        const theDate = new Date(date);
        const start = new Date(theDate.getFullYear(), 6, 1);
        const end = new Date(theDate.getFullYear() + 1, 5, 30);

        if (isWithinInterval(theDate, { start, end })) {
            return {
                error: false,
                result: `${theDate.getFullYear()}/${theDate.getFullYear() + 1}`,
            };
        }
        if (isBefore(theDate, start)) {
            return {
                error: false,
                result: `${theDate.getFullYear() - 1}/${theDate.getFullYear()}`,
            };
        }
        return {
            error: false,
            result: `${theDate.getFullYear() + 1}/${theDate.getFullYear() + 2}`,
        };
    }
    return {
        error: false,
        result: `${new Date().getFullYear()}/${new Date().getFullYear()}`,
    };
}

export function fromNormalizedtoPercent(value) {
    if (!value) {
        return null;
    }
    return `${(parseFloat(value.replace(',', '.')) * 100).toString()} %`;
}

export function fromPercentToNormalized(value) {
    if (!value) {
        return null;
    }
    return (parseFloat(value.replace(',', '.')) / 100).toString();
}
