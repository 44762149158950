import styled from 'styled-components';

export const FormContainer = styled.div`
    & input {
        outline: none;
        padding: 1rem;
        border-bottom: 1px solid #00586B;
        color: #030517; 
        margin: 1rem 0;
        width: 100%;
    }

    & .buttons-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 1rem;

        & button {
            outline: none;
            padding: 1.6rem 3.2rem;
            margin: 1rem 0;
            border-radius: 0.4rem;
            
            &.cancel {
                background-color: transparent;
                color: #00586B;
            }
            &.confirm {
                background-color: #00586B;
                color: white;
            }

            &:hover {
                opacity: .8;
                transition: .2s;
            }
        }
    }
`;

export const Errors = styled.span`
    display: flex;
    text-align: left;
    height: 15px;
    width: 100%;
    color: #f1003b;
    font-size: 1.2rem;
    padding:0 0.8rem;
    margin-top: 0;
`;
