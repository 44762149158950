import React from 'react';
import * as Styles from './styles';
import Icon from '../Icon';

const ButtonAction = ({
    hasBackground = true, label, iconName, iconColor, iconSize = 16, ...rest
}) => (
    <Styles.Container {...rest} hasBackground={hasBackground}>
        <Icon iconName={iconName} color={iconColor} iconSize={iconSize} />
        {label && <Styles.Label>{label}</Styles.Label>}
    </Styles.Container>
);

export default ButtonAction;
