import React from 'react';

import * as Styles from './styles';

const Temperature = ({ temperature = '--' }) => (
    <Styles.Container>
        <Styles.Wrapper>
            <Styles.Label large>{temperature}</Styles.Label>
            <Styles.Label small>°C</Styles.Label>

        </Styles.Wrapper>
    </Styles.Container>
);

export default Temperature;
