import { gql } from 'graphql-request';

const DISABLE_FARM = gql`
    mutation Farm($farmId:ID!) {
      farmRemove(id:$farmId) {
          removed
      }
    }
`;

export default DISABLE_FARM;
