import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        padding:${theme.spacings.small};
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
    `}
`;

export const Icon = styled.img`
   ${({ theme }) => css`
        width:15%;
        margin-bottom: ${theme.spacings.xxsmall};
  `}
`;

export const Text = styled.h3`
    ${({ theme }) => css`
        color:${theme.colors.texts};
    `}
`;
