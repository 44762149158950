import React from 'react';
import Skeleton from 'react-loading-skeleton';
import * as Styles from './styles';

function CardWeatherSkeleton() {
    return (
        <Styles.Container>
            <Styles.Date><Skeleton width={48} height={20} /></Styles.Date>
            <Styles.Hour><Skeleton width={32} height={20} /></Styles.Hour>
            <Styles.Card>
                <Skeleton width={70} height={142} />
            </Styles.Card>
        </Styles.Container>
    );
}

export default CardWeatherSkeleton;
