import {
    all, takeLatest, call, put,
} from 'redux-saga/effects';
import { requestAll, requestCityByState } from './utils';
import {
    callRequest, endRequest, errorState, getCitiesSuccess, getStatesSuccess,
} from './actions';

export function* index(action) {
    yield put(callRequest());
    const res = yield call(requestAll, action);
    if (!res.ok) {
        yield put(errorState(res.response?.errors[0]?.message));
    } else {
        yield put(getStatesSuccess(res.states));
    }
    yield put(endRequest());
}

export function* citiesByStateId(action) {
    yield put(callRequest());
    const res = yield call(requestCityByState, action);
    if (!res.ok) {
        yield put(errorState(res.response?.errors[0]?.message));
    } else {
        yield put(getCitiesSuccess(res.cities));
    }
    yield put(endRequest());
}

export default all([
    takeLatest('@state/GET_STATE', index),
    takeLatest('@state/GET_CITIES_BY_STATE', citiesByStateId),

]);
