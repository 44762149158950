import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Styles from './styles';

import history from '../../../services/history';

import Pivot from '../Pivot';

import IconSvg from '../../../components/IconSvg';
import IconExit from '../../../assets/svgs/icon_close.svg';

import { clearDataArea } from '../../../store/modules/area/actions';
import ErrorBoundary from '../../../components/ErrorBoundary';

const PivotModal = () => {
    const dispatch = useDispatch();
    const { props: { isEdit } } = useSelector((state) => state.modal);
    const [closeModal, setCloseModal] = useState(false);

    useEffect(() => {
        const body = window.document.querySelector('body');

        setTimeout(() => { body.style.overflow = 'hidden'; }, 100);
        return () => {
            body.style.overflow = 'auto';
            return true;
        };
    }, []);

    const handleKeyPress = useCallback((event) => {
        if (event.keyCode === 27) {
            setCloseModal(true);
            dispatch(clearDataArea());
            setTimeout(() => { history.push('/'); }, 800);
        }
    }, [dispatch]);

    const handleCloseModal = () => {
        setCloseModal(true);
        dispatch(clearDataArea());
        setTimeout(() => { history.push('/'); }, 800);
    };

    useEffect(() => {
        if (isEdit) {
            document.removeEventListener('keydown', handleKeyPress);
        } else {
            document.addEventListener('keydown', handleKeyPress);
        }

        return () => document.removeEventListener('keydown', handleKeyPress);
    }, [handleKeyPress, isEdit]);

    const handleClickOutsideModal = useCallback((event) => {
        const modal = document.getElementById('modal');
        if (!modal.contains(event.target)) {
            setCloseModal(true);
            dispatch(clearDataArea());
            setTimeout(() => { history.push('/'); }, 800);
        }
    }, [dispatch]);

    return (
        <Styles.Modal onClick={handleClickOutsideModal} className={closeModal ? 'close-modal' : ''}>
            <div id="modal">
                <Styles.Container>
                    <Styles.Button type="button" onClick={handleCloseModal}>
                        <IconSvg svgPath={IconExit} />
                    </Styles.Button>
                    <ErrorBoundary>
                        <Pivot />
                    </ErrorBoundary>
                </Styles.Container>
            </div>
        </Styles.Modal>
    );
};

export default PivotModal;
