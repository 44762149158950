import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import modal from './modal/reducer';
import states from './states/reducers';
import area from './area/reducer';
import farm from './farm/reducer';
import consultant from './consultant/reducer';

export default combineReducers({
    auth,
    user,
    modal,
    states,
    area,
    farm,
    consultant,
});
