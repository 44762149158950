import styled, { css } from 'styled-components';

export const Container = styled.div`
    width:100%;
    margin:0 auto;
    position:absolute;
    bottom:5rem;
    z-index:900;
    display:flex;
    align-items:center;
    flex-direction:row;
    justify-content:center;
`;

export const BackButton = styled.button`
    ${({ theme }) => css`
        display:flex;
        align-items:center;
        color:${theme.colors.black};
        font-size:${theme.font.sizes.xxsmall};
        background:${theme.colors.white};
        padding:${theme.spacings.xxsmall};
        border-radius:0.3rem;
    `}
`;

export const SizeArea = styled.div`
    ${({ theme }) => css`
        color:${theme.colors.primary};
        font-size:${theme.font.sizes.small};
        background:${theme.colors.white};
        padding:${theme.spacings.xxsmall};
        margin-right:${theme.spacings.xxsmall};
        margin-left:${theme.spacings.xxsmall};
        font-weight:bold;
        border-radius:.5rem;
    `}
`;

export const NextButton = styled.button`
    ${({ theme }) => css`
        display:flex;
        align-items:center;
        color:${theme.colors.black};
        padding:${theme.spacings.xxsmall};
        font-size:${theme.font.sizes.xxsmall};
        background:${theme.colors.white};
        border-radius:.5rem;
    `}
`;
