import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${() => css`
    `}
`;

export const HeaderContent = styled.div`
        ${({ theme }) => css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            width:100%;
            margin-bottom:${theme.spacings.small};
        `}
`;

export const GroupSelect = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right:2rem;
        width:100%;
        label {
            color:${theme.colors.black};
            margin-right:1rem;
            font-size:1.4rem;
            font-weight: bold;
        }
        
        input, select {
            width:100%;
            min-height: 3rem;
            background:#eee;
            border-radius:1rem;
        }
    `}
`;

export const GroupCalendar = styled.div`
        ${() => css`
        position: relative;
        width:100%;
    `}
`;

export const CalendarContent = styled.div`
    position: absolute;
    z-index: 100;
`;

export const Footer = styled.div`
    ${() => css`
        display:flex;
        align-items: center;
        justify-content: space-between;
    `}
`;
export const GroupData = styled.div`
    ${({ theme }) => css`
        color:${theme.colors.black};
        font-size:1.4rem;

        span {
            &:first-child {
                font-weight:bold;
            }
        }
    `}
`;
