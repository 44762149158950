import styled from 'styled-components';
import BgAuth from '../../assets/images/bg.jpg';

const Background = styled.div`
    width:100%;
    position: absolute;
    background:url(${BgAuth});
    height:100%;
    background-size:cover;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: .4; 
    }
`;

export default Background;
