export function showModal(name, props) {
    return {
        type: '@modal/SHOW_MODAL',
        payload: {
            name,
            props,
        },
    };
}

export function hideModal(name, props) {
    return {
        type: '@modal/HIDE_MODAL',
        payload: {
            name,
            props,
        },
    };
}
