import React from 'react';
import Skeleton from 'react-loading-skeleton';
import * as Styles from '../../ContainerGraph/styles';

const ContainerGraphSkeleton = () => (
    <Styles.Wrapper>
        <Styles.Container>
            <Skeleton height={400} width={600} style={{ marginRight: 100 }} />
        </Styles.Container>

    </Styles.Wrapper>
);

export default ContainerGraphSkeleton;
