// ICONS --------------------------------------------------------
import cornIcon from '../../assets/images/culture/annual/corn.png';
import soyIcon from '../../assets/images/culture/annual/soy.png';
import cottonIcon from '../../assets/images/culture/annual/cotton.png';
import coffeIcon from '../../assets/images/culture/annual/coffe.png';
import wheatIcon from '../../assets/images/culture/annual/wheat.png';
import tomatoIcon from '../../assets/images/culture/annual/tomato.png';
import potatoIcon from '../../assets/images/culture/annual/potato.png';
import beetIcon from '../../assets/images/culture/annual/beet.png';
import cabbageIcon from '../../assets/images/culture/annual/cabbage.png';
import riceIcon from '../../assets/images/culture/annual/rice.png';
import carrotIcon from '../../assets/images/culture/annual/carrot.png';
import onionIcon from '../../assets/images/culture/annual/onion.png';
import peanutIcon from '../../assets/images/culture/annual/peanut.png';
import garlicIcon from '../../assets/images/culture/annual/garlic.png';
import beansIcon from '../../assets/images/culture/annual/beans.png';
import sugarCaneIcon from '../../assets/images/culture/annual/sugar_cane.png';
import sorghumIcon from '../../assets/images/culture/annual/sorghum.png';

import avocadoIcon from '../../assets/images/culture/perennial/avocado.png';
import passionFruitIcon from '../../assets/images/culture/perennial/passion-fruit.png';
import citrusIcon from '../../assets/images/culture/perennial/citrus.png';
import bananaIcon from '../../assets/images/culture/perennial/banana.png';
import papayaIcon from '../../assets/images/culture/perennial/papaya.png';
import grapeIcon from '../../assets/images/culture/perennial/grape.png';
import lycheeIcon from '../../assets/images/culture/perennial/lychee.png';

import sandIcon from '../../assets/images/soil/sand.png';
import medianIcon from '../../assets/images/soil/median.png';
import semiIcon from '../../assets/images/soil/semi.png';
import clayIcon from '../../assets/images/soil/clay.png';

import centralPivotIcon from '../../assets/images/irrigation/central_pivot.png';
import sprinklingIcon from '../../assets/images/irrigation/sprinkling.png';
import drippingIcon from '../../assets/images/irrigation/dripping.png';

import waterIconImport from '../../assets/images/water-can.png';

export const annual = [
    {
        label: 'Milho', icon: cornIcon, type: 'Milho', id: '2',
    },
    {
        label: 'Soja', icon: soyIcon, type: 'Soja', id: '14',
    },
    {
        label: 'Algodão', icon: cottonIcon, type: 'Algodão', id: '8',
    },
    {
        label: 'Trigo', icon: wheatIcon, type: 'Trigo', id: '15',
    },
    {
        label: 'Tomate', icon: tomatoIcon, type: 'Tomate', id: '7',
    },
    {
        label: 'Batata', icon: potatoIcon, type: 'Batata', id: '12',
    },
    {
        label: 'Beterraba', icon: beetIcon, type: 'Beterraba', id: '17',
    },
    {
        label: 'Repolho', icon: cabbageIcon, type: 'Repolho', id: '10',
    },
    {
        label: 'Arroz', icon: riceIcon, type: 'Arroz', id: '11',
    },
    {
        label: 'Cenoura', icon: carrotIcon, type: 'Cenoura', id: '5',
    },
    {
        label: 'Cebola', icon: onionIcon, type: 'Cebola', id: '6',
    },
    {
        label: 'Amendoim', icon: peanutIcon, type: 'Amendoim', id: '9',
    },
    {
        label: 'Alho', icon: garlicIcon, type: 'Alho', id: '4',
    },
    {
        label: 'Feijão', icon: beansIcon, type: 'Feijão', id: '3',
    },
    {
        label: 'Sorgo', icon: sorghumIcon, type: 'Sorgo', id: '16',
    },
];

export const perennial = [
    {
        label: 'Abacate', icon: avocadoIcon, type: 'Abacate', id: '18',
    },
    {
        label: 'Maracuja', icon: passionFruitIcon, type: 'Maracujá', id: '19',
    },
    {
        label: 'Citrus', icon: citrusIcon, type: 'Citrus', id: '21',
    },
    {
        label: 'Cana-de-Açúcar', icon: sugarCaneIcon, type: 'Cana-de-açúcar', id: '13',
    },
    {
        label: 'Banana', icon: bananaIcon, type: 'Banana', id: '22',
    },
    {
        label: 'Mamao', icon: papayaIcon, type: 'Mamão', id: '23',
    },
    {
        label: 'Uva', icon: grapeIcon, type: 'Uva', id: '24',
    },
    {
        label: 'Lichia', icon: lycheeIcon, type: 'Lichia', id: '25',
    },
    {
        label: 'Cafe', icon: coffeIcon, type: 'Café', id: '20',
    },
];

export const cultures = {
    annual,
    perennial,
};

export const soil = [
    {
        label: 'Arenoso', icon: sandIcon, type: 'Arenoso', value: '0.87', id: '1',
    },
    {
        label: 'Franco-Arenoso', icon: medianIcon, type: 'Franco-Arenoso', value: '0.91', id: '2',
    },
    {
        label: 'Franco-Argiloso', icon: semiIcon, type: 'Franco-Argiloso', value: '0.95', id: '3',
    },
    {
        label: 'Argiloso', icon: clayIcon, type: 'Argiloso', value: '1', id: '4',
    },
];

export const irrigationType = [
    {
        label: 'Pivô central', icon: centralPivotIcon, type: 'Pivô central', value: '0.8', id: '1',
    },
    {
        label: 'Gotejamento', icon: drippingIcon, type: 'Gotejamento', value: '0.95', id: '2',
    },
    {
        label: 'Pivô central (LEPA)', icon: centralPivotIcon, type: 'Pivô central (LEPA)', value: '0.9', id: '3',
    },
    {
        label: 'Aspersão convencional', icon: sprinklingIcon, type: 'Aspersão convencional', value: '0.75', id: '4',
    },

];

export const waterIcon = waterIconImport;
