// Requests
export function callRequest() {
    return {
        type: '@state/START_REQUEST',
    };
}

export function endRequest() {
    return {
        type: '@state/END_REQUEST',
    };
}

export function errorState(message) {
    return {
        type: '@state/STATE_ERROR',
        payload: {
            message,
        },
    };
}

// GET STATES
export function getStates() {
    return {
        type: '@state/GET_STATE',
    };
}

export function getStatesSuccess(data) {
    return {
        type: '@state/GET_STATE_SUCCESS',
        payload: {
            data,
        },
    };
}

// GET CITIES
export function getCities(stateId) {
    return {
        type: '@state/GET_CITIES_BY_STATE',
        payload: { stateId },
    };
}

export function getCitiesSuccess(data) {
    return {
        type: '@state/GET_CITIES_SUCCESS',
        payload: {
            data,
        },
    };
}
