import React from 'react';
import * as Styles from './styles';

const LegendChart = ({ color, title }) => (
    <Styles.Container>
        <Styles.Label borderColor={color}>{title}</Styles.Label>
    </Styles.Container>
);

export default LegendChart;
