import React, { forwardRef } from 'react';

import IconSvg from '../IconSvg';

import * as Styles from './styles';

const Input = ({
    iconPath, error, touched, ...rest
}, ref) => (
    <Styles.Wrapper>
        <Styles.Container>
            {iconPath && <IconSvg svgPath={iconPath} />}
            <Styles.WrapperInput>
                <input ref={ref} {...rest} />
            </Styles.WrapperInput>
        </Styles.Container>
        {touched && error ? <Styles.Errors>{error}</Styles.Errors> : <Styles.Errors />}
    </Styles.Wrapper>
);

export default forwardRef(Input);
