import React, {
    useCallback, useEffect, useState,
} from 'react';
import * as Styles from './styles';

const ProgressBar = ({ percent = 0, bgColor = '#eee', style }) => {
    const [percentOne, setPercentOne] = useState(0);
    const [percentTwo, setPercentTwo] = useState(0);
    const [percentThree, setPercentThree] = useState(0);
    const [percentFour, setPercentFour] = useState(0);

    const calculatePercent = useCallback(() => {
        if (percent === 0) setPercentOne(0);
        if (percent === 25) {
            setPercentOne(100);
        }
        if (percent === 50) {
            setPercentOne(100);
            setPercentTwo(100);
        }
        if (percent === 75) {
            setPercentOne(100);
            setPercentTwo(100);
            setPercentThree(100);
        }

        if (percent === 100) {
            setPercentOne(100);
            setPercentTwo(100);
            setPercentThree(100);
            setPercentFour(100);
        }
    }, [percent]);

    useEffect(() => {
        calculatePercent();
    }, [calculatePercent]);

    return (
        <Styles.Container style={style}>
            <Styles.Block>
                <Styles.Progress percent={percentOne} bgColor={bgColor} />
            </Styles.Block>
            <Styles.Block>
                <Styles.Progress percent={percentTwo} bgColor={bgColor} />
            </Styles.Block>
            <Styles.Block>
                <Styles.Progress percent={percentThree} bgColor={bgColor} />
            </Styles.Block>

            <Styles.Block>
                <Styles.Progress percent={percentFour} bgColor={bgColor} />
            </Styles.Block>
        </Styles.Container>
    );
};

export default ProgressBar;
