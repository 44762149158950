import {
    all, takeLatest, call, put,
} from 'redux-saga/effects';
import Swal from 'sweetalert2';

import {
    userLoading, userError, userAddFinished,
    userRequestPasswordResetCodeSuccess,
    userValidatePasswordResetCodeSuccess,
    userUpdatePasswordSuccess, userSuccess, userRemoveFinished,
} from './actions';
import {
    addManager, addConsultant, addCustomer,
    updateUser, resetPassword, validateCode,
    updatePassword,
    removeUser,
} from './utils';

const messageError = 'Algo não ocorreu como o esperado, tente novamente mais tarde.';
export function* userUpdate(action) {
    const res = yield call(updateUser, action);

    if (!res.ok) {
        Swal.fire('Ops!', `${res.response.errors[0].message}`, 'error');
    } else {
        yield put(userLoading());
        yield put(userSuccess(res.userUpdate));
        Swal.fire('Sucesso!', 'Usuário editado!', 'success');
    }
}

export function* requestPasswordResetCode(action) {
    yield put(userLoading());

    const res = yield call(resetPassword, action);
    if (!res || res?.response) {
        Swal.fire('Ops!', `${messageError}`, 'error');
        yield put(userError(messageError));
    } else if (!res.ok) {
        const error = res?.response?.errors[0]?.message ?? messageError;
        Swal.fire('Ops!', `${error}`, 'error');
        yield put(userError('REQUEST_CODE_ERROR'));
    } else if (res.resetPassword.error) {
        Swal.fire('Ops!', `${res.resetPassword.message}`, 'error');
        yield put(userError('REQUEST_CODE_ERROR'));
    } else {
        Swal.fire('Código enviado!', 'Verifique seu e-mail.', 'success');
        yield put(userRequestPasswordResetCodeSuccess());
    }
}

export function* validatePasswordResetCode(action) {
    yield put(userLoading());
    const res = yield call(validateCode, action);

    if (!res.ok) {
        Swal.fire('Ops!', `${res.response.errors[0].message}`, 'error');
        yield put(userError('VALIDATE_CODE_ERROR'));
    } else if (res.validateCode.error) {
        Swal.fire('Ops!', `${res.validateCode.message}`, 'error');
        yield put(userError('VALIDATE_CODE_ERROR'));
    } else yield put(userValidatePasswordResetCodeSuccess(res.validateCode.message));
}

export function* requestUpdatePassword(action) {
    yield put(userLoading());
    const res = yield call(updatePassword, action);

    if (!res.ok) {
        Swal.fire('Ops!', `${res.response.errors[0].message}`, 'error');
        yield put(userError('UPDATE_PASSWORD_ERROR'));
    } else if (res.updatePassword.error) {
        Swal.fire('Ops!', `${res.updatePassword.message}`, 'error');
        yield put(userError('UPDATE_PASSWORD_ERROR'));
    } else {
        Swal.fire('Sucesso!', 'Sua senha foi alterada.', 'success');
        yield put(userUpdatePasswordSuccess());
    }
}

export function* userAdd(action) {
    yield put(userLoading());

    let res;
    let requestRole;
    switch (action.type) {
    case '@user/USER_ADD_MANAGER':
        res = yield call(addManager, action);
        requestRole = 'userAdd';
        break;
    case '@user/USER_ADD_CONSULTANT':
        res = yield call(addConsultant, action);
        requestRole = 'userAdd';
        break;
    case '@user/USER_ADD_CUSTOMER':
        res = yield call(addCustomer, action);
        requestRole = 'userAdd';
        break;
    default: break;
    }

    if (!res.ok) {
        Swal.fire('Ops!', `${res.response.errors[0].message}`, 'error');
    } else {
        const { role, name } = res[requestRole];
        let newUserRole;
        switch (role.name) {
        case 'Gerente':
            newUserRole = 'Gerente';
            break;
        case 'Consultor':
            newUserRole = 'Consultor';
            break;
        case 'Cliente':
            newUserRole = 'Cliente';
            break;
        default: break;
        }
        Swal.fire('Sucesso!', `${newUserRole} ${name} adicionado!`, 'success');
    }

    yield put(userAddFinished());
}

export function* userRemove(action) {
    yield put(userLoading());
    const res = yield call(removeUser, action);

    if (!res.ok) {
        Swal.fire('Ops!', `${res.response.errors[0].message}`, 'error');
        return;
    } if (res.userRemove) {
        const { removed } = res.userRemove;

        if (removed) {
            Swal.fire('Sucesso!', 'Usuário foi removido com sucesso', 'success');
        } else {
            Swal.fire('Ops!', 'Não foi possível remover este usuário, tente novamente mais tarde', 'error');
        }
    }

    yield put(userRemoveFinished());
}

export default all([
    takeLatest('@user/USER_UPDATE', userUpdate),
    takeLatest('@user/USER_ADD_MANAGER', userAdd),
    takeLatest('@user/USER_ADD_CONSULTANT', userAdd),
    takeLatest('@user/USER_ADD_CUSTOMER', userAdd),
    takeLatest('@user/USER_REMOVE', userRemove),
    takeLatest('@user/USER_REQUEST_PASSWORD_RESET_CODE', requestPasswordResetCode),
    takeLatest('@user/USER_VALIDATE_PASSWORD_RESET_CODE', validatePasswordResetCode),
    takeLatest('@user/USER_UPDATE_PASSWORD', requestUpdatePassword),
]);
