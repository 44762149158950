import React from 'react';
import { TextField } from 'formik-material-ui';

import * as Styles from './styles';

import CardGraph from '../../CardGraph';
import iconGraph from '../../../assets/svgs/icon_compost.svg';

const Others = () => (
    <CardGraph
        style={{ height: 'fit-content' }}
        title="Outros"
        svgPath={iconGraph}
    >
        <Styles.Wrapper>
            <Styles.Container>
                <Styles.TextField
                    component={TextField}
                    name="variety"
                    label="Variedades"
                />
            </Styles.Container>
        </Styles.Wrapper>
    </CardGraph>
);

export default Others;
