import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTable, useExpanded } from 'react-table';

import Cell from './Cell';

import IconSvg from '../IconSvg';
import IconDown from '../../assets/svgs/icon_arrow_down.svg';
import IconRight from '../../assets/svgs/icon_arrow_right.svg';

import * as Styles from './styles';

import { farmRequestEmployees } from '../../store/modules/farm/actions';
import { handleOnClickAdd } from './Cell/utils/addUser';
import prepareData from './prepareData';

const PeoplesTable = ({ form, className = '' }) => {
    const dispatch = useDispatch();

    const { data: user, loading: loadingUser } = useSelector((state) => state.user);
    const { employees, loading } = useSelector((state) => state.farm);
    const [newUserRole, setNewUserRole] = useState(null);

    const [data, setData] = useState([]);
    const [retrievedData, setRetrievedData] = useState(false);
    const [expanded, setExpanded] = useState({});

    useEffect(() => {
        dispatch(farmRequestEmployees(user.id));
    }, [loadingUser, dispatch, user.id]);

    useEffect(() => {
        if (employees) {
            const data = prepareData(employees, form?.values?.farmEdit);
            if (form) form.values.allUsers = data;
            setData(data);
            setRetrievedData(true);
        }
    // eslint-disable-next-line
    }, [employees]);

    useEffect(() => {
        const roles = {
            Administrador: 'Gerente',
            Gerente: 'Consultor',
            Consultor: 'Cliente',
        };
        setNewUserRole(roles[user.role.name]);
    }, [user.role]);

    const handleOnClick = useCallback((row, toggleRowExpanded) => {
        const currentState = expanded[row.id] || false;
        const initialExpanded = {};
        initialExpanded[row.id] = !currentState;
        toggleRowExpanded(row.id);
        if (row.subRows) {
            row.subRows.map((row) => {
                const currentState = expanded[row.id] || false;
                toggleRowExpanded(row.id, currentState);
                initialExpanded[row.id] = currentState;
                return true;
            });
        }
        setExpanded((value) => ({ ...value, ...initialExpanded }));
    }, [expanded]);

    const columns = useMemo(() => [{
        id: 'expander',
        Cell: ({ row, toggleRowExpanded }) => (row.canExpand ? (
            <span
                {...row.getToggleRowExpandedProps({
                    style: { paddingLeft: `${row.depth * 2}rem` },
                    onClick: () => handleOnClick(row, toggleRowExpanded),
                })}
            >
                {row.isExpanded ? <IconSvg svgPath={IconDown} /> : <IconSvg svgPath={IconRight} />}
            </span>
        ) : null),
    },
    { Header: '', accessor: 'id' },
    { Header: 'Nome', accessor: 'name' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Cargo', accessor: 'role' },
    { Header: 'Ações', accessor: 'actions' },
    ], [handleOnClick]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        toggleRowExpanded,
        prepareRow,
    } = useTable({ columns, data }, useExpanded);

    useEffect(() => {
        const initialExpanded = {};
        rows.map((row) => {
            const currentState = expanded[row.id] || false;
            initialExpanded[row.id] = currentState;
            toggleRowExpanded(row.id, currentState);
            if (row.subRows) {
                row.subRows.map((row) => {
                    const currentState = expanded[row.id] || false;
                    toggleRowExpanded(row.id, currentState);
                    initialExpanded[row.id] = currentState;
                    return true;
                });
            }
            return true;
        });
        setExpanded((value) => ({ ...value, ...initialExpanded }));

        // eslint-disable-next-line
    }, [data, toggleRowExpanded]);

    if (!data || !(data?.length > 0) || loadingUser) {
        return (
            <Styles.Container>
                {loading || !retrievedData || loadingUser
                    ? <Styles.Loader />
                    : (
                        <>
                            <p>Não temos consultores cadastrados.</p>
                            <Styles.Button
                                type="button"
                                onClick={() => handleOnClickAdd(user, dispatch)}
                            >
                                {`Adicionar ${newUserRole}`}
                            </Styles.Button>
                        </>
                    )}
            </Styles.Container>
        );
    }

    return (
        <Styles.Table className={className} {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => {
                            if (!form && column.id === 'id') return null;
                            return <th {...column.getHeaderProps()}>{column.render('Header')}</th>;
                        })}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell, index) => (
                                <Cell
                                    key={index}
                                    cell={cell}
                                    form={form}
                                    data={data}
                                    setData={setData}
                                />
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </Styles.Table>
    );
};

export default PeoplesTable;
