import styled, { css } from 'styled-components';

const containerModifier = {
    show: css`
        display:flex;
        z-index:99;
        transform: translateX(0px);
    `,
};

export const Wrapper = styled.div`
    ${({ theme }) => css`
        display:flex;
        flex-direction:row;
        position:absolute;
        z-index:9999999;
        bottom:9rem;
        right:2rem;



        button {
            right:0;
            background:${theme.colors.white};
            box-shadow:.2rem .2rem .3rem #3333;
            border:.1rem solid #f1f1f1;
            width:6rem;
            height:6rem;
            border-radius:3rem;
        }
    `}
`;

export const Container = styled.div`
    ${({ theme, show }) => css`
        background:${theme.colors.white};
        max-height:40rem;
        display: none;
        overflow-y:auto;
        border-radius:${theme.border.radius};
        box-shadow:.2rem .2rem .4rem #3333;
        max-width:22rem;
        border:.1rem solid #f1f1f1;
        position:absolute;
        flex-direction:column;
        z-index:-1;
        transform: translateX(-100px);
        transition:transform  .5s;
        top: -28rem;
        right: -1rem;

        ${!!show && containerModifier.show};

        .closeNDVI {
            cursor:pointer;
            color:${theme.colors.green};
            font-size:1.2rem;
            padding:1rem;
        }

    `}
`;

export const Area = styled.div`
    ${({ theme }) => css`
        cursor:pointer;
        display:flex;
        flex-direction:row;
        align-items:center;

        padding:${theme.spacings.xxsmall};

        &:hover {
            transition:.5s;
            background:#f1f1f1;
        }
    `}
`;

export const Image = styled.img`
    ${() => css`
        border-radius:3rem;
        background:#f1f1f1;
        width:6rem;
        height:6rem;
    `}
`;

export const Date = styled.span`
    ${({ theme }) => css`
        color:${theme.colors.black};
        margin-left:${theme.spacings.small};
        font-size:${theme.font.sizes.small};
    `}
`;
