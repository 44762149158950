import { gql } from 'graphql-request';

export const RAIN_ADD = gql`
    mutation irrigationAdd($rain:Float, $_areaId:Int!, $_createdBy:Int!, $_createdAt:String!){
        irrigationAdd(rain:$rain, area_id:$_areaId, created_by:$_createdBy, date:$_createdAt){
            error,
            message
        }
    }
`;

export const RAIN_ADD_AREAS = gql`
    mutation irrigationAdd($rain:Float, $areas:[Int], $_createdBy:Int!, $_createdAt:String!){
        irrigationAdd(rain:$rain, areas:$areas, created_by:$_createdBy, date:$_createdAt){
            error,
            message
        }
    }
`;
