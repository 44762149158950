import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display:flex;
        justify-content:space-between;
        padding:${theme.spacings.small} ${theme.spacings.medium};
    `}
`;

export const Menus = styled.ul`
    width: 100%;
    list-style-type:none;
    display:flex;
    padding:0px;
    justify-content: flex-end;
`;

export const Menu = styled.li`
    ${({ theme }) => css`
        text-transform:uppercase;
        line-height:3rem;
        margin-right:2rem;
        cursor:pointer;
        font-size:${theme.font.sizes.xxsmall};
        border-bottom: 1px solid transparent;
        
        &:hover {
            border-bottom: 1px solid ${({ copyright }) => (!copyright ? theme.colors.white : 'none')};
        }
    `}
`;
