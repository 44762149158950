import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import modal from './modal/sagas';
import states from './states/sagas';
import area from './area/sagas';
import farm from './farm/sagas';
import consultant from './consultant/sagas';

export default function* rootSaga() {
    return yield all([
        auth,
        user,
        modal,
        states,
        area,
        farm,
        consultant,
    ]);
}
