import React from 'react';
import Skeleton from 'react-loading-skeleton';
import * as Styles from '../../ContainerFarm/styles';

const ContainerFarmSkeleton = () => {
    const stylesTitle = { marginBottom: '1.6rem', borderRadius: '10rem' };
    return (
        <Styles.Wrapper>

            <Styles.Container>
                <Skeleton height={30} width={208} style={stylesTitle} />
                <Styles.Content>
                    <Styles.Group>
                        <Styles.Label><Skeleton height={18.67} width={201} /></Styles.Label>
                        <Styles.Value><Skeleton height={17.33} width={54} /></Styles.Value>
                    </Styles.Group>

                    <Styles.Group>
                        <Styles.Label><Skeleton height={18.67} width={88} /></Styles.Label>
                        <Styles.Value><Skeleton height={17.33} width={54} /></Styles.Value>
                    </Styles.Group>

                    <Styles.Group>
                        <Styles.Label><Skeleton height={18.67} width={110} /></Styles.Label>
                        <Styles.Value><Skeleton height={17.33} width={71} /></Styles.Value>
                    </Styles.Group>

                    <Styles.Group>
                        <Styles.Label><Skeleton height={18.67} width={165} /></Styles.Label>
                        <Styles.Value><Skeleton height={17.33} width={54} /></Styles.Value>
                    </Styles.Group>

                    <Styles.Group>
                        <Styles.Label><Skeleton height={18.67} width={226} /></Styles.Label>
                        <Styles.Value><Skeleton height={17.33} width={54} /></Styles.Value>
                    </Styles.Group>

                    <Styles.Group>
                        <Styles.Label><Skeleton height={18.67} width={99} /></Styles.Label>
                        <Styles.Value><Skeleton height={17.33} width={54} /></Styles.Value>
                    </Styles.Group>
                </Styles.Content>
            </Styles.Container>

            <Styles.Container>
                <Skeleton height={30} width={96} style={stylesTitle} />
                <Styles.Content>
                    <Styles.Group>

                        <Styles.Label><Skeleton height={18.67} width={121} /></Styles.Label>
                        <Styles.Value><Skeleton height={17.33} width={106} /></Styles.Value>
                    </Styles.Group>

                    <Styles.Group>
                        <Styles.Label><Skeleton height={18.67} width={226} /></Styles.Label>
                        <Styles.Value><Skeleton height={17.33} width={52} /></Styles.Value>
                    </Styles.Group>

                    <Styles.Group>
                        <Styles.Label><Skeleton height={18.67} width={70} /></Styles.Label>
                        <Styles.Value><Skeleton height={17.33} width={26} /></Styles.Value>
                    </Styles.Group>
                </Styles.Content>
            </Styles.Container>

            <Styles.Container>
                <Skeleton height={30} width={187} style={{ ...stylesTitle, marginTop: 50 }} />
                <Styles.Content>
                    <Styles.Group>
                        <Styles.Label><Skeleton height={18.67} width={90} /></Styles.Label>
                        <Styles.Value><Skeleton height={17.33} width={54} /></Styles.Value>
                    </Styles.Group>

                    <Styles.Group>
                        <Styles.Label><Skeleton height={18.67} width={25.88} /></Styles.Label>
                        <Styles.Value><Skeleton height={17.33} width={86} /></Styles.Value>
                    </Styles.Group>

                    <Styles.Group>
                        <Styles.Label><Skeleton height={18.67} width={41} /></Styles.Label>
                        <Styles.Value><Skeleton height={17.33} width={86} /></Styles.Value>
                    </Styles.Group>

                    <Styles.Group>
                        <Styles.Label><Skeleton height={18.67} width={167} /></Styles.Label>
                        <Styles.Value><Skeleton height={17.33} width={30} /></Styles.Value>
                    </Styles.Group>

                    <Styles.Group>
                        <Styles.Label><Skeleton height={18.67} width={159} /></Styles.Label>
                        <Styles.Value><Skeleton height={17.33} width={30} /></Styles.Value>
                    </Styles.Group>

                </Styles.Content>

            </Styles.Container>

            <Styles.Container>
                <Skeleton height={30} width={78} style={stylesTitle} />
                <Styles.Content>
                    <Styles.Group>
                        <Styles.Label><Skeleton height={18.67} width={72.69} /></Styles.Label>
                        <Styles.Value><Skeleton height={17.33} width={54} /></Styles.Value>
                    </Styles.Group>

                    <Styles.Group>
                        <Styles.Label><Skeleton height={18.67} width={76} /></Styles.Label>
                        <Styles.Value><Skeleton height={17.33} width={110} /></Styles.Value>
                    </Styles.Group>

                    <Styles.Group>
                        <Styles.Label><Skeleton height={18.67} width={173} /></Styles.Label>
                        <Styles.Value><Skeleton height={17.33} width={19} /></Styles.Value>
                    </Styles.Group>
                </Styles.Content>
            </Styles.Container>

        </Styles.Wrapper>
    );
};

export default ContainerFarmSkeleton;
