import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const CustomContainer = styled.div`
    ${({ theme }) => css`
        padding: 0 calc(${theme.grid.gutter} / 2);
        margin: 0 auto;

        ${media.greaterThan('medium')`
            padding: 0 calc(${theme.grid.gutter} / 2);
        `}
    `}
`;

export const Loading = styled.h2`
    color:#333;
`;

export const Header = styled.div`
    display: flex;
    overflow: hidden;
`;

export const InfosAreaContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        width:50%;

        position:relative;

        & > .image {
            width: 50rem;
            height: 50rem;
            object-fit: cover;
            padding-bottom: ${theme.spacings.xxsmall};
        }

        & > span {
            display:flex;
            margin-top:-40px;
            justify-content:flex-end;
            font-size:${theme.font.sizes.xxsmall};
            color:${theme.colors.gray};
            padding: 0 ${theme.spacings.small};
            padding-bottom:${theme.spacings.small};
        }
    `}

    .labelNDVI {
        position: absolute;
        bottom: 8rem;
        width: 17rem;
        z-index: 9999;
        left: 2rem;
        background: #fff;
        padding: 1rem;
        border-radius: 1rem;
    }
`;

export const DescriptionContainer = styled.div`
    ${({ theme }) => css`
        width: 100%;
        padding: ${theme.spacings.small};
        padding-right: 0;
        padding-bottom: 0;
    `}
`;

export const ContainerInfos = styled.div`
    ${({ theme }) => css`
        display:grid;
        grid-template-columns: 1fr 4fr;
        margin-top: ${theme.spacings.small};
    `}
`;

export const ContainerGraphs = styled.div`
    ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    margin-top: ${theme.spacings.small};

    label {
        color: #000;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 21px;
        display: flex;
        align-self: center;
    }

        ${media.lessThan('medium')`
            flex-direction: column;
        `}
    `}
`;

export const ActionsContainer = styled.div`
    ${() => css`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
     
    `}
`;

export const GroupButton = styled.div`
    ${({ theme }) => css`
        position:relative;

        .dropdown {
            display:flex;
            flex-direction:column;
            align-items:flex-start;
            list-style-type:none;
            border-radius:.4rem;
            box-shadow:.1rem .2rem .5rem #333;
            position:absolute;
            z-index:99999;
            background:${theme.colors.white};
            padding:${theme.spacings.small};


        }
    `}
`;

export const InfosContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: ${theme.spacings.small};

        & img {
            width: ${theme.font.sizes.xxlarge};
            height: ${theme.font.sizes.xxlarge};
            border-radius: ${theme.font.sizes.xxlarge};
            border:1px solid ${theme.colors.black};    
            margin-right:${theme.spacings.xxsmall};
            padding: 0.3rem;

            &:last-child { margin-right:0; }
        }

        & p {
            display: flex;
            align-items: center;
            margin-left:${theme.spacings.xxsmall};
            font-size:${theme.font.sizes.large};
            color:${theme.colors.gray};
            font-weight: 300;
        }
    `}
`;

export const CardsContainer = styled.div`
    ${({ theme }) => css`
        display:flex;
        flex-direction: column;
        margin-top: ${theme.spacings.xsmall};
    `}
`;

export const SectionData = styled.div`
        ${({ theme }) => css`
            padding-top: ${theme.spacings.xlarge};
            padding-left:${theme.spacings.xlarge};
            display:flex;
            flex-direction:row;
            justify-content:space-between;
        `}
`;

export const Group = styled.div`
        ${({ theme }) => css`
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            margin-bottom: ${theme.spacings.small};
            
        `}
`;
