import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ ...rest }) => {
    const { path } = rest;
    const auth = useSelector((state) => state.auth);
    const { signed } = auth;

    return signed
        ? <Route {...rest} />
        : <Redirect from={path} to="/login" />;
};

export default PrivateRoute;
