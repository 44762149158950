import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${() => css`
        background:#fff;
        width:100%;
        height:1.4rem;
        border-radius:1rem;
        display:flex;
        flex:1;
        position:relative;
    `}
`;

export const Block = styled.div`
    ${() => css`
        background:#eee;
        border-radius:1rem;
        width:50%;
        height:100%;
        margin-right:.2rem;
    `}
`;

export const Progress = styled.div`
    ${({ percent, bgColor }) => css`
        background:${bgColor};
        width:${percent}%;
        height:100%;
        border-radius:1rem;
    `}
`;
