import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Styles from './styles';

import CardFarm from './CardFarm';
import AddFarmModal from './AddFarmModal';
import NotFound from '../../../NotFound';
import CardFarmSkeleton from '../../../Skeletons/CardFarmSkeleton';

import imageActive from '../../../../assets/images/header/image-active.png';
import imageDisabled from '../../../../assets/images/header/image-disabled.png';

import noImage from '../../../../assets/images/header/no-image-active.png';
import noImageDisabled from '../../../../assets/images/header/no-image-disabled.png';

import { farmsConsultantRequest, farmsCustomerRequest } from '../../../../store/modules/farm/actions';
import Modal from '../../../ButtonFloatMain/Modal';

const Farm = ({ modalIsVisible, setModalIsVisible }) => {
    const dispatch = useDispatch();
    const { data: user } = useSelector((state) => state.user);
    const { data: farms, loading } = useSelector((state) => state.farm);
    const { loadingArea } = useSelector((state) => state.area);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [farmAreas, setFarmAreas] = useState([]);

    const [compact, setCompact] = useState(false);

    const CardCompacts = useCallback(() => (
        <Styles.WrapperImage>
            {!compact ? <Styles.Image src={imageActive} onClick={() => setCompact(true)} /> : <Styles.Image src={imageDisabled} onClick={() => setCompact(false)} />}
            {compact ? <Styles.Image src={noImage} onClick={() => setCompact(false)} /> : <Styles.Image src={noImageDisabled} onClick={() => setCompact(true)} />}
        </Styles.WrapperImage>
    ), [compact]);

    useEffect(() => {
        switch (user.role.name) {
        case 'Cliente':
            dispatch(farmsCustomerRequest(user.id));
            break;
        default: dispatch(farmsConsultantRequest(user.id));
        }
    }, [user.id, dispatch, user.role]);

    const handleSelectFarmsToArea = (areas) => {
        setFarmAreas(areas.filter((area) => area.active));
        setModalType('Chuva');
        setModalIsOpen((value) => !value);
    };

    return (
        <>
            {modalIsOpen && <Modal isVisible={modalIsOpen} setIsVisible={setModalIsOpen} type={modalType} farmAreas={farmAreas} />}
            <AddFarmModal isVisible={modalIsVisible} setIsVisible={setModalIsVisible} />
            <CardCompacts />
            {loading || loadingArea
                ? <CardFarmSkeleton />
                : (
                    <>
                        {farms.length === 0
                            ? <NotFound text="Nenhuma fazenda encontrada" />
                            : (
                                <Styles.Container>
                                    {farms.map((farm) => (
                                        <CardFarm
                                            key={farm.id}
                                            farm={farm}
                                            compact={compact}
                                            handleSelectFarmsToArea={handleSelectFarmsToArea}
                                        />
                                    ))}
                                </Styles.Container>
                            )}
                    </>
                )}
        </>
    );
};

export default Farm;
