import styled, { css } from 'styled-components';

const titleStyles = (bgColor) => css`
    background-color:${bgColor};
    font-size:1.4rem;
    text-align:center;
    padding:.5rem;
    border-radius:.5rem;
    margin-bottom:1rem;
`;

const colorTitle = {
    white: () => css`
      color:#fff;
    `,

    black: () => css`
      color:#000;
      
    `,

};

export const Container = styled.div`
    ${({ theme }) => css`
        height: 100%;
        background: ${theme.colors.white};
        box-shadow:2px 2px 10px rgba(0,0,0,0.1);
        border-radius: ${theme.border.radius};
        display:flex;
        overflow:hidden;
        flex-direction: column;
    `}
`;

export const PivotContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        width: 100%;
        padding:${theme.spacings.xsmall};
        padding-top:${theme.spacings.xxsmall};
    `}
`;

export const InfosContainer = styled.div`
    width: 60%;
`;

export const ChartContainer = styled.div`
    ${({ theme }) => css`
        width: 40%;
        padding-left: ${theme.spacings.xsmall};
    `}
`;

export const IconsContainer = styled.div`
    ${() => css`
        display: flex;
        justify-content: center;

        .column {
            flex-direction:column;
        }
    `}
`;

export const Area = styled.img`
    cursor:pointer;
    width:100%;
    height:220px;
    object-fit:cover;
`;

export const AreaInfo = styled.p`
    ${({ theme }) => css`
        padding:${theme.spacings.xxsmall} ${theme.spacings.xxsmall};
        font-size:${theme.font.sizes.xxxsmall};
        color:${theme.colors.gray};
        width: 100%;
        text-align: right;
    `}
`;

export const Title = styled.p`
    ${({ theme, backgroundColor, color }) => css`
        font-size:${theme.font.sizes.small};
        margin-bottom:${theme.spacings.xxxsmall};
        color:${theme.colors.texts};
        font-weight: bold;
        width: 100%;

        ${!!backgroundColor && titleStyles(backgroundColor)};
        ${!!color && colorTitle[color]};
    `}
`;

export const Label = styled.p`
    ${({ theme }) => css`
        display:flex;
        font-size:${theme.font.sizes.xxxsmall};
        margin-bottom:${theme.spacings.xxxsmall};
        flex-direction:row;
        color:${theme.colors.gray};
        font-weight:normal;

        &.irrigation {
            color: #32b0f4;
            & span { color: #32b0f4 !important; }
        }
    `}
`;

export const Value = styled.span`
    ${({ theme }) => css`
        color:${theme.colors.black};
        margin-left:${theme.spacings.xxxsmall};
        font-size:${theme.font.sizes.xxxsmall};

        &.size {
            color:${theme.colors.black};
            margin-left: 0;
            display:flex;
            margin-bottom:${theme.spacings.xxxsmall};
            font-weight:bold;
        }
    `}
`;

export const Type = styled.img`
    width:35px;
    height:35px;
    border-radius:20px;
    border:1px solid ${({ theme }) => theme.colors.black};    
    margin-right:${({ theme }) => theme.spacings.xxsmall};
    padding:0.3rem;

    &:last-child{
        margin-right:0;
    }
`;
