import React, { useState } from 'react';
import { TextField } from 'formik-material-ui';

import { Grid } from '@material-ui/core';
import * as Styles from './styles';

import CardGraph from '../../CardGraph';
import Selector from '../../Selector';

import * as AREA from '../../../utils/helpers/area';
import * as MASK from '../../../utils/helpers/mask';

import iconShovel from '../../../assets/svgs/icon_shovel.svg';

const Ground = ({ form }) => {
    const [, setTeta] = useState({
        cm: true,
        g: false,
    });

    const [, setType] = useState('');

    const handleTetaUnit = (unit, value) => {
        form.values.tetaUnit = unit;
        setTeta(value);
        form.setFieldValue('soilType', '');
        form.setFieldValue('soilDensity', null);
        setType('');
    };

    const isTetaCCBiggerThenTetaPMP = () => {
        if (!form.values.tetaCC || !form.values.tetaPMP) {
            return false;
        }
        if (parseFloat(form.values.tetaCC.toString().replace(',', '.')) > parseFloat(form.values.tetaPMP.toString().replace(',', '.'))) {
            return true;
        }

        form.errors.tetaPMP = 'TetaPMP precisar ser inferior a TetaCC';
        return false;
    };

    const handleSoilSelect = (type, index) => {
        form.values.soilType = type;
        form.setFieldValue('ks', AREA.soil[index].value * 100);
        setType(type);
    };

    return (
        <CardGraph
            title="Características do solo"
            svgPath={iconShovel}
        >
            <Styles.Wrapper>
                <Styles.Menu>
                    <li
                        className={form.values.tetaUnit === 'cm' ? 'active' : ''}
                        onClick={(val) => handleTetaUnit('cm', { cm: val, g: !val })}
                    >
                        cm³/cm³
                    </li>
                    <li className={form.values.tetaUnit === 'g' ? 'active' : ''} onClick={(val) => handleTetaUnit('g', { cm: !val, g: val })}>g/g</li>
                </Styles.Menu>

                <Styles.SelectGround>
                    <span>Selecine o tipo de solo</span>
                    <Styles.Options>
                        {
                            AREA.soil.map((item, index) => (
                                <Selector key={index} on={item.type === form.values.soilType} onClick={() => handleSoilSelect(item.type, index)}>
                                    <img src={item.icon} alt={item.label} />
                                    <span>{item.label}</span>
                                </Selector>
                            ))
                        }

                    </Styles.Options>
                </Styles.SelectGround>

                <Styles.WrapperInput>
                    <Grid container spacing={2}>
                        {form.values.tetaUnit === 'cm'
                        && (
                            <>
                                <Grid item xs={12}>
                                    <Styles.TextField
                                        component={TextField}
                                        name="tetaCC"
                                        label="0cc (0 a 1)"
                                        onBlur={(e) => {
                                            form.setFieldTouched('tetaCC', true);
                                            if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('tetaCC', parseFloat(e.target.value).toString());
                                        }}
                                        InputProps={{
                                            inputComponent: MASK.maskTetaWithCm,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Styles.TextField
                                        component={TextField}
                                        name="tetaPMP"
                                        label="0pmp (0 a 1)"
                                        onBlur={(e) => {
                                            form.setFieldTouched('tetaPMP', true);
                                            if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('tetaPMP', parseFloat(e.target.value).toString());
                                        }}
                                        InputProps={{
                                            inputComponent: MASK.maskTetaWithCm,
                                        }}
                                        validate={isTetaCCBiggerThenTetaPMP()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Styles.TextField
                                        component={TextField}
                                        name="soilHumidity"
                                        label="Umidade do solo inicial(%) (5 a 100)"
                                        onBlur={(e) => {
                                            form.setFieldTouched('soilHumidity', true);
                                            if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('soilHumidity', parseFloat(e.target.value).toString());
                                        }}
                                        InputProps={{
                                            inputComponent: MASK.maskPercent,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Styles.TextField
                                        component={TextField}
                                        name="soilDepth"
                                        label="Profundidade do solo (cm) (0 a 500)"
                                        onBlur={(e) => {
                                            form.setFieldTouched('soilDepth', true);
                                            if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('soilDepth', parseFloat(e.target.value).toString());
                                        }}
                                        InputProps={{
                                            inputComponent: MASK.maskCm,
                                        }}
                                    />
                                </Grid>
                            </>
                        )}

                        {form.values.tetaUnit === 'g'
                        && (
                            <>
                                <Grid item xs={12}>
                                    <Styles.TextField
                                        component={TextField}
                                        name="tetaCC"
                                        label="0cc (0 a 1)"
                                        onBlur={(e) => {
                                            form.setFieldTouched('tetaCC', true);
                                            if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('tetaCC', parseFloat(e.target.value).toString());
                                        }}
                                        InputProps={{
                                            inputComponent: MASK.maskTetaWithG,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Styles.TextField
                                        component={TextField}
                                        name="tetaPMP"
                                        label="0pmp (0 a 1)"
                                        onBlur={(e) => {
                                            form.setFieldTouched('tetaPMP', true);
                                            if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('tetaPMP', parseFloat(e.target.value).toString());
                                        }}
                                        InputProps={{
                                            inputComponent: MASK.maskTetaWithG,
                                        }}
                                        validate={isTetaCCBiggerThenTetaPMP()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Styles.TextField
                                        component={TextField}
                                        name="soilDensity"
                                        label="Densidade do solo (g/cm³)"
                                        onBlur={(e) => {
                                            form.setFieldTouched('soilDensity', true);
                                            if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('soilDensity', parseFloat(e.target.value).toString());
                                        }}
                                        InputProps={{
                                            inputComponent: MASK.maskSoilDensity,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Styles.TextField
                                        component={TextField}
                                        name="soilHumidity"
                                        label="Umidade do solo inicial(%) (5 a 100)"
                                        onBlur={(e) => {
                                            form.setFieldTouched('soilHumidity', true);
                                            if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('soilHumidity', parseFloat(e.target.value).toString());
                                        }}
                                        InputProps={{
                                            inputComponent: MASK.maskPercent,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Styles.TextField
                                        component={TextField}
                                        name="soilDepth"
                                        label="Profundidade do solo (cm) (0 a 500)"
                                        onBlur={(e) => {
                                            form.setFieldTouched('soilDepth', true);
                                            if (Number.isFinite(parseFloat(e.target.value))) form.setFieldValue('soilDepth', parseFloat(e.target.value).toString());
                                        }}
                                        InputProps={{
                                            inputComponent: MASK.maskCm,
                                        }}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Styles.WrapperInput>
            </Styles.Wrapper>
        </CardGraph>
    );
};

export default Ground;
