import styled, { css } from 'styled-components';
import { Popup } from 'react-leaflet';

export const CustomPopup = styled(Popup)`
    ${({ theme }) => css`
        cursor: pointer;

        & .leaflet-popup-content {
            margin: ${theme.spacings.small};
            margin-bottom: ${theme.spacings.medium};
            & p { margin: ${theme.spacings.xxsmall} 0; }
        }

        & .leaflet-popup-content-wrapper {
            background-color: ${theme.colors.white};
            border-radius: ${theme.border.radius};
        }

        & .leaflet-popup-tip {
            background-color: ${theme.colors.white};
        }

        & .custom-popup-icons-wrapper {
            & > img {
                width: 32px;
                height: 32px;
            }
        }
    `}
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > p { text-align: center; }
`;

export const IconsContainer = styled.div`
    & > img {
        width: 32px;
        height: 32px;
    }
`;

export const InfosContainer = styled.div`
    width: 100%;
`;
