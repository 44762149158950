import { createGlobalStyle, css } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Roboto';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  * {
    margin: 0;
    padding: 0;
    border: 0;
    outline:none;
    box-sizing: border-box;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
    /* CSS TOGGLE */
    .react-toggle-track {
      width: 36px !important;
      height: 18px !important;
    }

    .react-toggle-thumb{
      width:20px !important;
      height:20px !important;
      top:-1px !important;
      left:0px !important;
    }

    .react-toggle--checked .react-toggle-thumb {
      left:17px !important;
    }
/* CSS TOGGLE */

/* Graph Plotly */
.modebar-container{
  display:none !important;
}

  #root, body, html {
    height:100%;
  }

  .swal2-container {
    z-index: 10000;
  }

  ${({ theme }) => css`
    html {
      font-family: ${theme.font.family};
      font-size: 62.5%;
      scroll-behavior: smooth;
    }

    body {
      color: ${theme.colors.white};
      font-size: ${theme.font.sizes.small};
    }

    button {
      cursor:pointer;
    }

    .group-label {
      display:flex;
      flex-direction:row;
      align-items:center;
      padding:20px 0px;
      text-align:left;

      input[type=checkbox]:checked + label {
        &:before {
          content:"✔";
          color:white;
          width:15px;
          height:15px;
          display:flex;
          align-items:center;
          justify-content:center;
        }
        background:${theme.colors.danger};
      }

      span {
        margin-left:40px;
        font-size:16px;
      }
    }

    .label-modal {
      display:flex;
      align-items:center;
      justify-content:center;
      width: 30px;
      height: 30px;
      background: #fff;
      position: absolute;
      border-radius: 5px;
      border: 2px solid #ddd;

    }
  
    .img-responsive {
      width:100%;
    }


    
  `}
`;

export default GlobalStyle;
