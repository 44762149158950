import React from 'react';

import theme from '../../../styles/theme';

import * as Styles from './styles';

const ButtonChart = ({ active, title, setActive }) => (
    <Styles.Button
        style={{ backgroundColor: active ? theme.colors.green : 'transparent' }}
        onClick={setActive}
    >
        <Styles.Label style={{ color: active ? theme.colors.white : theme.colors.gray }}>{title}</Styles.Label>
    </Styles.Button>
);

export default ButtonChart;
