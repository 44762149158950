import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';

import GroupFormButton from '../../GroupFormButton';
import CardGraph from '../../CardGraph';
import CardAreaSkeleton from '../../Skeletons/CardAreaSkeleton';
import CardAreaSelect from '../components/CardAreaSelect';
import NotFound from '../../NotFound';
import IconSvg from '../../IconSvg';
import IrrigationAddForm from '../../IrrigationAddForm';

import * as Styles from './styles';
import * as StylesModal from '../../Modals/styles';

import IconClose from '../../../assets/svgs/icon_close.svg';
import iconRain from '../../../assets/svgs/icon_rain_pivot.svg';
import iconIrrigation from '../../../assets/svgs/icon_irrigation_pivot.svg';

import { areaIrrigationAddAreas } from '../../../store/modules/area/actions';

const Modal = ({
    isVisible, setIsVisible, type = 'Chuva', farmAreas = null,
}) => {
    const dispatch = useDispatch();

    const ReactSwal = withReactContent(Swal);

    const { data: areas, loading, message } = useSelector((state) => state.area);
    const { data: user } = useSelector((state) => state.user);
    const [areasList, setAreasList] = useState([]);

    const toggleVisible = (status) => {
        status = status || !isVisible;

        const body = window.document.querySelector('body');

        if (status) {
            body.style.overflow = 'hidden';
        } else {
            body.style.overflow = 'auto';
        }

        setIsVisible(status);
    };

    useEffect(() => {
        toggleVisible(true);
    }, [message, setIsVisible]);

    if (!isVisible) return true;

    const handleOnClickChildButton = async () => {
        const handleOnSubmit = (values) => {
            const date = moment(values.date).toISOString();
            const mm = parseFloat(values.mm);
            const areas = areasList.map((areaId) => parseInt(areaId));
            const userId = parseInt(user.id);

            if (farmAreas?.length) {
                dispatch(areaIrrigationAddAreas(mm, null, areas, userId, date));
            } else {
                if (type === 'Chuva') dispatch(areaIrrigationAddAreas(mm, null, areas, userId, date));
                if (type === 'Irrigação') dispatch(areaIrrigationAddAreas(null, mm, areas, userId, date));
            }

            toggleVisible();

            ReactSwal.clickConfirm();
        };

        const handleOnCancel = () => ReactSwal.clickCancel();

        ReactSwal.fire({
            title: <p style={{ padding: '1rem', paddingBottom: '0', fontWeight: 'bold' }}>{`Dados de ${type}`}</p>,
            html: <IrrigationAddForm type={type} handleOnSubmit={handleOnSubmit} handleOnCancel={handleOnCancel} />,
            width: 360,
            allowOutsideClick: false,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            reverseButtons: true,
        });
    };

    const handleCloseModal = () => {
        if (areasList.length !== 0) {
            Swal.fire({
                title: 'Você tem certeza?',
                text: 'Após fechar o modal, você irá perder todos os dados.',
                icon: 'warning',
                allowOutsideClick: false,
                showCloseButton: false,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                showConfirmButton: true,
                confirmButtonText: 'Sim',
                confirmButtonColor: '#f1003b',
                reverseButtons: true,
                padding: 32,
                preConfirm: () => {
                    toggleVisible();
                },
            });
            return false;
        }
        toggleVisible();
        return true;
    };

    return (
        <StylesModal.Modal>
            <Styles.Wrapper>
                <StylesModal.Header>
                    <div onClick={() => handleCloseModal()}>
                        <IconSvg svgPath={IconClose} />
                    </div>
                </StylesModal.Header>

                <Styles.Container>
                    <CardGraph
                        className="card"
                        title={`Selecione as áreas para adicionar a ${type?.toLowerCase()}`}
                        svgPath={type === 'Chuva' ? iconRain : iconIrrigation}
                    >
                        {loading
                            ? (
                                <Styles.ListAreaSkeleton>
                                    <CardAreaSkeleton />
                                </Styles.ListAreaSkeleton>
                            )
                            // Se não existir fazendas pré-selecionadas
                            : !farmAreas ? (
                                <>
                                    {areas.length === 0
                                        ? <NotFound text="Nenhuma área cadastrada" />
                                        : (
                                            <Styles.ListArea>
                                                {areas.map((area) => <CardAreaSelect key={area.id} area={area} setAreasList={setAreasList} farmAreas={farmAreas} />)}
                                            </Styles.ListArea>
                                        )}
                                </>
                            )
                                : (
                                    <>
                                        {farmAreas?.length === 0
                                            ? <NotFound text="Nenhuma área cadastrada nesta fazenda" />
                                            : (
                                                <Styles.ListArea>
                                                    {farmAreas?.map((area) => <CardAreaSelect key={area.id} area={area} setAreasList={setAreasList} farmAreas={farmAreas} />)}
                                                </Styles.ListArea>
                                            )}
                                    </>
                                )}
                    </CardGraph>
                </Styles.Container>

                <Styles.Footer>
                    <GroupFormButton
                        addButton
                        labelConfirm={`Adicionar ${type}`}
                        confirmDisabled={areasList.length === 0}
                        handleAction={() => handleCloseModal()}
                        handleSave={handleOnClickChildButton}
                    />
                </Styles.Footer>
            </Styles.Wrapper>
        </StylesModal.Modal>
    );
};

export default Modal;
