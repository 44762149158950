import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display:flex;
    flex-direction:column;
`;

export const Container = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;

    & img {
        width: 20px;
        height: 20px;
    }
`;

export const WrapperInput = styled.div`
    ${({ theme }) => css`
        width:100%;
        & input {
            width:97.5%;
            height: 40px;
            outline:none;
            border:0;
            border-bottom:1px solid ${theme.colors.lightGray};
            padding:0 ${`calc(${theme.spacings.xxsmall} + ${theme.spacings.xxxsmall})`};
            margin:${theme.spacings.xxxsmall};
            color:${theme.colors.primary};

            &::placeholder {
                color:${theme.colors.gray}; 
            }

            &:focus {
                transition:.1s;
                border-bottom:1px solid ${theme.colors.primary}; 
                color:${theme.colors.primary};
            }
        }
    `}
`;

export const Errors = styled.span`
    ${({ theme }) => css`
        display:block;
        height: 15px;
        width: 100%;
        color:${theme.colors.danger};
        font-size:${theme.font.sizes.xxxsmall};
        padding:0 ${theme.spacings.xxsmall};
        margin: ${theme.spacings.xxsmall};
        margin-top: 0;
    `}
`;
