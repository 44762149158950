import { FRAGMENT_GET_AREA } from '../fragments/area';

export const GET_AREAS_MAP = /* GraphQL */ `
query GET_ALL_AREA($id:ID!){
    users(id:$id){
        areas {
            ${FRAGMENT_GET_AREA}
        }
  }
}
`;
