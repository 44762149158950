import styled, { css } from 'styled-components';

export const Modal = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 1100;
`;

export const Wrapper = styled.div`
   ${({ theme }) => css`
        border-radius: ${theme.border.radius};
        max-height: 90vh;
        min-height: 90vh;
        min-width: 90vw;
        max-width: 90vw;
        background-color: ${theme.colors.white};
        overflow: hidden;
        z-index: 1110;
    `}
`;

export const Header = styled.div`
   ${({ theme }) => css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        min-height: 56px;
        max-height: 56px;
        width: 100%;
        background-color: ${theme.colors.primary};
        padding: 0 ${theme.spacings.xsmall};

        & img:hover {
            cursor: pointer;
            opacity: .6;
            transition: .5s;
        }
  `}
`;

export const Container = styled.div`
   ${({ theme }) => css`
        background-color: ${theme.colors.white};
        width: 90vw;
        max-height: calc(90vh - 56px);
        overflow: auto;
        overflow-x: hidden;
  `}
`;
