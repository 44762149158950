export const handleError = (error) => {
    const err = JSON.parse(error);
    return {
        ...err,
        error: true,
    };
};

export const handleSuccess = (data) => {
    const res = JSON.parse(data);
    return {
        ...res,
        ok: true,
    };
};

export const accessRole = (role) => {
    if (role === 'Cliente') return false;
    return true;
};
