import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import * as Styles from './styles';

import GroupFormButton from '../../../GroupFormButton';
import UpdateImage from './UpdateImage';
import IconSvg from '../../../IconSvg';
import { MaskPhone } from '../../../../utils/helpers/mask';

import iconPerfil from '../../../../assets/svgs/icon_perfil.svg';
import iconEmail from '../../../../assets/svgs/icon_email.svg';
import iconPhone from '../../../../assets/svgs/icon_phone.svg';

import { userUpdate, userRequestPasswordResetCode, userClearState } from '../../../../store/modules/user/actions';

import ResetPassword from './ResetPassword';

const Info = () => {
    const CustomMaskedInput = ({
        iconPath, error, touched, ...rest
    }) => (
        <Styles.InputWrapper>
            <Styles.InputContainer>
                {iconPath && <IconSvg svgPath={iconPath} />}
                <Styles.InputContent>
                    <MaskPhone {...rest} />
                </Styles.InputContent>
            </Styles.InputContainer>
            {touched && error ? <Styles.InputErrors>{error}</Styles.InputErrors> : <Styles.InputErrors />}
        </Styles.InputWrapper>
    );

    const dispatch = useDispatch();
    const { data: user, response } = useSelector((state) => state.user);

    const [recoverStep, setRecoverStep] = useState(0);
    const RegexWithoutSpecialCharacter = new RegExp(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ' ']+$/);

    const handleClearState = useCallback(() => {
        setRecoverStep(0);
        dispatch(userClearState(user));
    }, [dispatch, user]);

    useEffect(() => {
        switch (response) {
        case 'REQUEST_CODE_SUCCESS':
            setRecoverStep(1);
            break;
        case 'UPDATE_PASSWORD_SUCCESS':
            handleClearState();
            break;
        default:
            break;
        }
    }, [response, dispatch, user, handleClearState]);

    const validationSchemaFields = Yup.object().shape({
        name: Yup.string().matches(RegexWithoutSpecialCharacter, 'Caracteres especiais não são permitidos').required('Campo obrigatório'),
        phone: Yup.string().min(10, 'Complete o número').required('Campo Obrigatório'),
    });

    const handleOnSubmit = (values) => {
        dispatch(userUpdate(user.id, values.name, values.phone));
    };

    const handleReset = () => {
        Swal.fire({
            title: 'Você tem certeza?',
            text: 'Após confirmar, você irá receber um código em seu e-mail para resetar a senha.',
            icon: 'warning',
            showCloseButton: false,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            showConfirmButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: '#f1003b',
            reverseButtons: true,
            preConfirm: () => {
                dispatch(userRequestPasswordResetCode(user.email));
            },
        });
    };

    return (
        <>
            {recoverStep === 0
                ? (

                    <Formik
                        initialValues={{
                            name: user.name,
                            email: user.email,
                            phone: user.phone,
                        }}
                        validationSchema={validationSchemaFields}
                        onSubmit={handleOnSubmit}
                    >
                        {(form) => (
                            <>
                                <Styles.Container>
                                    <UpdateImage />
                                    <Styles.Form>
                                        <Field
                                            type="text"
                                            name="name"
                                            as={Styles.CustomInput}
                                            iconPath={iconPerfil}
                                            onChange={form.handleChange}
                                            value={form.values.name}
                                            error={form.errors.name}
                                            touched={form.touched.name}
                                            placeholder="Nome"
                                            autoComplete="off"
                                        />
                                        <Field
                                            type="text"
                                            name="email"
                                            as={Styles.CustomInput}
                                            iconPath={iconEmail}
                                            onChange={form.handleChange}
                                            value={form.values.email}
                                            error={form.errors.email}
                                            touched={form.touched.email}
                                            placeholder="Email"
                                            disabled
                                        />
                                        <Field
                                            type="text"
                                            name="phone"
                                            as={CustomMaskedInput}
                                            iconPath={iconPhone}
                                            onChange={(e) => {
                                                form.setFieldValue('phone', e.target.value.replace(/[^0-9,]+/g, ''));
                                            }}
                                            value={form.values.phone}
                                            error={form.errors.phone}
                                            touched={form.touched.phone}
                                            placeholder="Telefone"
                                            autoComplete="off"
                                        />
                                    </Styles.Form>
                                </Styles.Container>
                                <GroupFormButton
                                    addButton
                                    label="Resetar Senha"
                                    handleAction={handleReset}
                                    handleSave={form.handleSubmit}
                                />

                            </>
                        )}
                    </Formik>

                )
                : (
                    <ResetPassword handleBack={() => handleClearState()} />
                )}
        </>

    );
};

export default Info;
