import client from '../../../graphql/client';
import GET_CITIES from '../../../graphql/queries/cities/getCities';
import GET_STATES from '../../../graphql/queries/states/getStates';
import { handleSuccess, handleError } from '../../../utils/functions';

export async function requestAll() {
    try {
        const data = await client.request(GET_STATES, { id: 1 });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestCityByState({ payload }) {
    const { stateId } = payload;
    try {
        const data = await client.request(GET_CITIES, { id: stateId });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}
