import moment from 'moment';

export const lastDays = (days) => {
    const dates = [];

    const dateStart = moment().subtract(days, 'days');
    const dateEnd = moment();

    while (dateStart.format('DD/MM') !== dateEnd.format('DD/MM')) {
        dates.push(dateStart.format('DD/MM'));
        dateStart.add(1, 'days');
    }

    return dates;
};
