import React, { forwardRef } from 'react';
import * as Styles from './styles';

const Checkbox = ({ size = 16, ...rest }, ref) => (
    <Styles.Checkbox
        {...rest}
        ref={ref}
        size={size}
        type="checkbox"
    />
);

export default forwardRef(Checkbox);
