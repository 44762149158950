import produce from 'immer';

const INITIAL_STATE = { signed: false };

export default function auth(state = INITIAL_STATE, action) {
    switch (action.type) {
    case '@user/USER_SUCCESS':
        return produce(state, (draft) => { draft.signed = true; });
    default:
        return state;
    }
}
