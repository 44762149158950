import React, { useEffect, useState, useRef } from 'react';
import { ErrorMessage } from 'formik';
import { TextField } from 'formik-material-ui';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Swal from 'sweetalert2';

import CardGraph from '../../CardGraph';
import * as Styles from './styles';

import iconPin from '../../../assets/svgs/icon_pin.svg';

import { farmsConsultantRequest, farmsCustomerRequest } from '../../../store/modules/farm/actions';

const Area = ({ form, field, ...rest }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const farm = useSelector((state) => state.farm);
    const modal = useSelector((state) => state.modal);
    const [customerFarm, setCustomerFarm] = useState([]);
    const [consultantFarm, setConsultantFarm] = useState('');
    const errorCustomer = useRef(true);

    useEffect(() => {
        switch (user.data.role.name) {
        case 'Cliente':
            dispatch(farmsCustomerRequest(user.data.id));
            break;
        default: dispatch(farmsConsultantRequest(user.data.id));
        }
    }, [user.data.id, dispatch, user.data.role]);

    useEffect(() => {
        if (farm.data.length !== 0) {
            const farmCustomer = farm.data.filter(((item) => item.id === form.values.farmId));
            if (form.values.farmId !== '' && farmCustomer.length !== 0) {
                form.values.customerId = farmCustomer[0]?.customer?.id;
                if (!form.values.customerId) {
                    Swal.fire('Ops!', 'Não existe produtor para esta fazenda', 'error');
                    form.values.farmId = '';
                }
                setCustomerFarm(farmCustomer);
            } else if (!form.values.customerId && errorCustomer.current && modal.props.isEdit) {
                Swal.fire('Ops!', 'A fazenda atual não possui produtor', 'error');
                errorCustomer.current = false;
                form.setFieldValue('farmId', '');
            }
        }
    }, [form.values.farmId, farm.data, form.values.customerId, form.values.consultantId, user.data.id, form, modal.props.isEdit]);

    useEffect(() => {
        if (form.values.farmId) {
            const farmConsultant = farm.data.find(((item) => item.id === form.values.farmId));
            if (farmConsultant) {
                setConsultantFarm(farmConsultant?.consultant.name);
                form.setFieldValue('consultantId', farmConsultant?.consultant.id);
            }
        }
    // eslint-disable-next-line
    }, [form.values.farmId, farm.data]);

    return (
        <CardGraph
            {...rest}
            title="Área"
            svgPath={iconPin}
        >
            <Styles.WrapperInput>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Styles.TextField
                            component={TextField}
                            name="name"
                            type="text"
                            label="Nome"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl style={{ width: '97.5%' }}>
                            <InputLabel id="select-farmId" htmlFor="select" error={!!(form.errors.farmId && form.touched.farmId)}>Fazenda</InputLabel>
                            <Select
                                labelId="select-farmId"
                                name="farmId"
                                value={form.values.farmId}
                                onChange={(e) => {
                                    form.handleChange(e);
                                }}
                                autoWidth
                                error={!!(form.errors.farmId && form.touched.farmId)}
                                style={{ color: '#00586B' }}
                            >
                                {farm.data.map((item) => (
                                    <MenuItem key={item.id} value={item.id} style={{ color: '#00586B' }}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Styles.ErrorMessageSelect>
                                <ErrorMessage name="farmId" />
                            </Styles.ErrorMessageSelect>

                        </FormControl>

                    </Grid>
                    <Grid item xs={6}>
                        <Styles.TextField
                            component={TextField}
                            name="consultantId"
                            type="text"
                            label="Consultor"
                            value={consultantFarm}
                            disabled
                            error={false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Styles.TextField
                            component={TextField}
                            name="customerId"
                            type="text"
                            label="Produtor"
                            value={customerFarm[0]?.customer?.name ?? ''}
                            disabled
                            error={false}
                        />
                    </Grid>
                </Grid>
            </Styles.WrapperInput>
        </CardGraph>
    );
};

export default Area;
