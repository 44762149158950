import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CardArea from '../CardArea';
import NotFound from '../NotFound';
import * as Styles from './styles';

const Area = () => {
    const history = useHistory();

    const { data, filter } = useSelector((state) => state.area);

    const handleSelected = useCallback((id) => {
        history.push(`pivot/${id}`);
    }, [history]);

    const memoFilter = useCallback((area) => {
        if (filter === 'hideArchived') {
            return area.active && (
                <CardArea
                    key={area.id}
                    onClick={() => handleSelected(area.id)}
                    data={area}
                />
            );
        } if (filter === 'showArchived') {
            return !area.active && (
                <CardArea
                    key={area.id}
                    onClick={() => handleSelected(area.id)}
                    data={area}
                />
            );
        }

        return (
            <CardArea
                key={area.id}
                onClick={() => handleSelected(area.id)}
                data={area}
            />
        );
    }, [filter, handleSelected]);

    return (
        <>
            {data.length === 0
                ? <NotFound text="Nenhuma área cadastrada" />
                : <Styles.ListArea>{data.map((area) => memoFilter(area))}</Styles.ListArea>}
        </>
    );
};

export default Area;
