import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        table {
        th {
            font-size:${theme.font.sizes.xxxsmall};
        }
        td {
            font-size:${theme.font.sizes.xxsmall};
        }
    }
    `}
`;
