import { isEmpty } from 'lodash';

const hasCustomerChecked = (user) => {
    if (user.checked) return true;
    if (!user?.employees) return false;
    const result = user.employees.filter((subUser) => (subUser.role.name === 'Cliente' ? subUser.checked : hasCustomerChecked(subUser)));
    if (result.length > 0) return true;
    return false;
};

const isChecked = (id, farmPeople) => {
    const checked = farmPeople.filter((people) => people.id === id);
    return !isEmpty(checked);
};

const replaceObjectKey = (user, farmPeople) => {
    user.employees.map((subUser) => replaceObjectKey(subUser, farmPeople));
    user.subRows = user.employees;
    user.checked = isChecked(user.id, farmPeople);
    if (user.role.name !== 'Cliente') user.checked = hasCustomerChecked(user);
    delete user.employees;
    return true;
};

export default (employees, farm) => {
    let farmPeople = [];
    if (farm) {
        farmPeople = [
            ...(farm?.partners ?? []),
            { id: farm?.consultant?.id },
            { id: farm?.customer?.id },
        ];
    }

    const newEmployees = JSON.parse(JSON.stringify(employees));
    newEmployees.map((user) => replaceObjectKey(user, farmPeople));
    return newEmployees;
};
