import styled, { css } from 'styled-components';

export const Button = styled.button`
    ${({ theme }) => css`
        position:fixed;
        cursor:pointer;
        right:0.9rem;
        bottom:0.9rem;
        background: rgb(16,49,62);
        background: linear-gradient(90deg, rgba(16,49,62,1) 0%, rgba(0,88,107,1) 100%);
        width:50px;
        height:50px;
        border-radius:25px;
        color:#fff;
        font-size:${theme.font.sizes.xlarge};

        &:hover{
            transition:.5s;
            opacity: .8;
        }
    `}
`;
