import { gql } from 'graphql-request';
import { FRAGMENT_GET_FARMS } from '../../fragments/farms';

const FARM_ADD = gql`
    mutation FARM_ADD($name: String!, $cityId: ID!, $consultantId: ID!, $customerId: ID!, $partners: [ID]) {
        farmAdd(name: $name, city_id: $cityId, consultant_id: $consultantId, producer_id: $customerId, partners: $partners) {
            ${FRAGMENT_GET_FARMS}
        }
    }
`;

export default FARM_ADD;
