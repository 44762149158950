import React from 'react';
import * as Styles from './styles';

import iconFarm from '../../assets/svgs/icon_farm.svg';

const Error404 = ({ message }) => (
    <Styles.Container>
        <img src={iconFarm} alt="imagem de terra com planta" />
        <h2>Ops! Algo aconteceu!</h2>
        <p>{message}</p>
    </Styles.Container>
);

export default Error404;
