import React, { useMemo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import CardWeather from './components/CardWeather';
import * as Styles from './styles';

const ContainerWeather = () => {
    const { forecast } = useSelector((state) => state?.area?.dataArea);

    const NotFound = () => (
        <Styles.NotFound>
            <img src={require('../../assets/images/not_found.png')} alt="sem imagem de previsão" />
        </Styles.NotFound>
    );

    const memoCard = useMemo(() => {
        if (forecast && forecast?.length) { return forecast.map((data, index) => <CardWeather key={index} item={data} />); }
        return undefined;
    }, [forecast]);

    if (!forecast || !forecast?.length) return <NotFound />;

    return (
        <Styles.Container>
            <Scrollbars style={{ height: 210 }}>
                <Styles.Content>
                    {memoCard}
                </Styles.Content>
            </Scrollbars>
        </Styles.Container>
    );
};

export default ContainerWeather;
