import styled, { css } from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    right: calc(5vw + 2.6rem);
    bottom: calc(5vh + 0.9rem);
`;

export const Button = styled.button`
    ${({ theme }) => css`
        cursor: pointer;
        background: rgb(16,49,62);
        background: linear-gradient(90deg, rgba(16,49,62,1) 0%, rgba(0,88,107,1) 100%);
        width:50px;
        height:50px;
        border-radius:25px;
        color:white;
        font-size:${theme.font.sizes.xlarge};
        z-index: 2;

        &:after { content: '+' }
        
        &.open {
            transition:.5s;
            transform:rotate(45deg);
        }

        &.close {
            transition:.5s;
            transform:rotate(0deg);
        }
    `}
`;

export const ChildButtonsContainer = styled.div`
    ${({ theme }) => css`
        position: absolute;
        bottom: 0;
        display: none;
        opacity: 0;
        animation: fade-in .5s;
        border-radius: calc(${theme.border.radius} * 3);
        z-index: 1;

        &.open {
            bottom: calc(50px - 12px);
            display: flex;
            flex-direction: column;
            opacity: 1;
        }

        @keyframes fade-in {
            0% {
                bottom: 0;
                opacity: 0; 
            } 
            10% { opacity: 0; }
            100% {
                bottom: calc(50px - 12px);
                opacity: 1; 
            }
        }

        &.close {
            display: none;
            bottom: 0;
            opacity: 0;
        }
    `}
`;

export const ChildButtonsContent = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: ${theme.spacings.xsmall} ${theme.spacings.small};
        background-color: #102c38;
        border-radius: calc(${theme.border.radius} * 3);
        border-bottom-right-radius: 0;
    `}
`;

export const ChildFooter = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 24px;
    overflow: hidden;
`;

export const ChildButton = styled.button`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        cursor:pointer;
        width: 100%;
        margin: ${theme.spacings.xxsmall} 0;
        padding: ${theme.spacings.xxsmall} ${theme.spacings.small};
        background-color: transparent;

        &:hover {
            background-color: #FFFFFF40;
            border-radius: ${theme.border.radius};
        }

        & label {
            cursor:pointer;
            color: ${theme.colors.white};
            font-size: ${theme.font.sizes.xxxsmall};
            text-transform: uppercase;
            margin-left: ${theme.spacings.xsmall};
        }
    `}
`;
