import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        border-radius: ${theme.border.radius};
        max-height: 80vh;
        min-height: 80vh;
        min-width: 80vw;
        max-width: 80vw;
        background-color: ${theme.colors.white};
        overflow: hidden;
    `}
`;

export const Container = styled.div`
   ${({ theme }) => css`
        background-color: ${theme.colors.white};
        width: 80vw;
        max-height: calc(80vh - 56px - 72px);
        overflow: auto;
        overflow-x: hidden;

        & > .card {
            margin: ${theme.spacings.medium};
            margin-top: ${theme.spacings.large};
        }
  `}
`;

export const Footer = styled.div`
   ${({ theme }) => css`
        height: 72px;
        width: 80vw;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: ${theme.colors.white};
        border-top: 1px solid ${theme.colors.lightGray};
        
    `}
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export const Button = styled.button`
   ${({ theme }) => css`
        outline:none;
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius:${theme.border.radius};
        padding:${theme.spacings.xsmall};
        background-color:${theme.colors.primary};
        margin-bottom:${theme.spacings.small};
        color:${theme.colors.white};
        font-size:${theme.font.sizes.xxsmall};

        &:hover {
            transition:.5s;
            opacity: 0.8;
        }
   `}
`;
