import styled, { css } from 'styled-components';
import soil from '../../assets/images/soil.png';

export const Container = styled.div``;

export const ImageBackground = styled.div`
    ${({ notFound }) => css`
        width:auto;
        height:${notFound ? '20rem' : 'auto'};
        background:url(${soil}) repeat-x;
        background-size:contain;
    `}
`;

export const WrapperLegend = styled.div`
    ${({ theme }) => css`
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        margin-top:${theme.spacings.small};

        div {
            margin-right:${theme.spacings.xsmall};
        }
    `}
`;

export const NotFound = styled.div`
    ${({ theme }) => css`
        background:${theme.colors.white};
        border-radius:1rem;
        box-shadow:2px 2px 3px rgba(0,0,0,0.1);
        padding:${theme.spacings.xxsmall};
        position:relative;
        top:10rem;
        left:.5rem;
        width:50rem;
        margin:0 auto;
        h2{
            color:${theme.colors.gray};
            font-size:${theme.font.sizes.xsmall};
            text-align:center;
        }
    `}
`;
