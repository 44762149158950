import React from 'react';
import { Formik, Field, Form } from 'formik';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import * as Yup from 'yup';

import * as Styles from './styles';

export default ({ handleOnSubmit, handleOnCancel, type }) => {
    const today = new Date();
    const maxDate = moment(today).toISOString().split('T')[0];

    const numberMask = createNumberMask({
        prefix: '', allowDecimal: true, decimalLimit: '', suffix: ' mm', thousandsSeparatorSymbol: '',
    });

    const validationSchemaFields = Yup.object().shape({
        mm: Yup.string().nullable().required('Campo obrigatório')
            .test(
                'interval',
                `Somente números entre 0 e ${type === 'Chuva' ? 150 : 100} são permitidos`,
                (val) => parseFloat(val) >= 0 && parseFloat(val) <= (type === 'Chuva' ? 150 : 100),
            ),
        date: Yup.string().required('Campo obrigatório'),
    });

    return (
        <Styles.FormContainer>
            <Formik
                initialValues={{ mm: '', date: '' }}
                onSubmit={handleOnSubmit}
                validationSchema={validationSchemaFields}
            >
                {(form) => (
                    <Form>
                        <MaskedInput name="mm" id="input" onChange={form.handleChange} mask={numberMask} placeholder={`${type} (mm)`} />
                        {form.errors.mm && form.touched.mm
                            ? <Styles.Errors>{form.errors.mm}</Styles.Errors>
                            : <Styles.Errors />}
                        <Field id="datepicker" type="date" name="date" onChange={form.handleChange} max={maxDate} />
                        {form.errors.date && form.touched.date
                            ? <Styles.Errors>{form.errors.date}</Styles.Errors>
                            : <Styles.Errors />}
                        <div className="buttons-container">
                            <button className="cancel" type="button" onClick={handleOnCancel}>Cancelar</button>
                            <button className="confirm" type="submit">Confirmar</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Styles.FormContainer>
    );
};
