import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FastField, Field, Formik } from 'formik';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import IconSvg from '../../../../IconSvg';
import CardGraph from '../../../../CardGraph';
import GroupFormButton from '../../../../GroupFormButton';
import FormInfos from './FormInfos';
import PeoplesTable from '../../../../PeoplesTable';

import IconClose from '../../../../../assets/svgs/icon_close.svg';
import IconPin from '../../../../../assets/svgs/icon_pin.svg';
import IconPeoples from '../../../../../assets/svgs/icon_peoples.svg';

import * as Styles from './styles';
import * as StylesModal from '../../../styles';

import { handleOnClickAdd } from '../../../../PeoplesTable/Cell/utils/addUser';

import { farmAdd, farmUpdate } from '../../../../../store/modules/farm/actions';

const AddFarmModal = ({ isVisible, setIsVisible, farm = null }) => {
    const dispatch = useDispatch();
    const { data: user } = useSelector((state) => state.user);

    const [newUserRole, setNewUserRole] = useState(null);

    const RegTextWithoutSpecialCharacter = new RegExp(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ0-9' ']+$/);

    useEffect(() => {
        document.title = isVisible ? 'SIM - Sistema Integrado de Manejo | Adicionar Fazenda' : 'SIM - Sistema Integrado de Manejo';
        const roles = {
            Administrador: 'Gerente',
            Gerente: 'Consultor',
            Consultor: 'Cliente',
        };
        setNewUserRole(roles[user.role.name]);

        return () => { document.title = 'SIM - Sistema Integrado de Manejo'; };
    }, [isVisible, user]);

    if (!isVisible) return true;

    const handleCloseModal = ({ values }) => {
        const {
            name, state, city, tableChange,
        } = values;
        if (!!name || !!state || !!city || tableChange) {
            Swal.fire({
                title: 'Você tem certeza?',
                text: 'Após fechar o modal, você irá perder todos os dados.',
                icon: 'warning',
                allowOutsideClick: false,
                showCloseButton: false,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                showConfirmButton: true,
                confirmButtonText: 'Sim',
                confirmButtonColor: '#f1003b',
                reverseButtons: true,
                padding: 32,
                preConfirm: () => {
                    setIsVisible((value) => !value);
                },
            });
            return false;
        }
        setIsVisible((value) => !value);
        return true;
    };

    const validationSchemaFields = Yup.object().shape({
        name: Yup.string().matches(RegTextWithoutSpecialCharacter, 'Somente letras e números são permitidos').required('Campo Obrigatório'),
        state: !farm ? Yup.string().required('Seleção obrigatória') : null,
        city: !farm ? Yup.string().required('Seleção obrigatória') : null,
        producer: !farm ? Yup.string().required('Seleção obrigatória') : null,
    });

    const handleOnClickSave = ({ values, submitForm }) => {
        const {
            name, state, city, producer,
        } = values;

        const handleShowError = (field) => {
            const message = `Por favor ${field}.`;
            Swal.fire('Ops!', message, 'error');
        };

        if (!name) return handleShowError('preencha o nome da fazenda');
        if (!farm) {
            if (!state) return handleShowError('preencha o estado que a fazenda está localizada');
            if (!city) return handleShowError('preencha a cidade que a fazenda está localizada');
            if (!producer) return handleShowError('selecione o Produtor da fazenda');
        }

        return submitForm();
    };

    const handleOnSubmit = (value) => {
        const {
            name, selectedUsers, city: formCity, farmEdit, producer, consultant,
        } = value;

        const { id: customerId } = JSON.parse(producer);
        const { id: consultantId } = JSON.parse(consultant);

        const partners = selectedUsers.map((user) => {
            if (user.role.name === 'Cliente' && user.id !== customerId) return user.id;
            return null;
        }).filter((user) => user !== null);

        let cityId;
        if (farmEdit != null) {
            const { city: { id } } = farmEdit;
            cityId = id;
        } else {
            const { id } = JSON.parse(formCity);
            cityId = id;
        }

        if (farm) dispatch(farmUpdate(farm.id, user, name, cityId, consultantId, customerId, partners));
        else dispatch(farmAdd(user, name, cityId, consultantId, customerId, partners));

        setIsVisible((value) => !value);
    };

    return (
        <StylesModal.Modal>
            <Formik
                initialValues={{
                    farmEdit: farm || null,
                    name: farm?.name ?? '',
                    state: '',
                    city: '',
                    producer: '',
                    consultant: farm?.consultant ? JSON.stringify(farm.consultant) : '',
                    tableChange: false,
                    selectedUsers: farm?.partners ?? [],
                    allUsers: null,
                }}
                onSubmit={handleOnSubmit}
                validationSchema={validationSchemaFields}
            >
                {(props) => (
                    <Styles.Wrapper>
                        <StylesModal.Header>
                            <div onClick={() => handleCloseModal(props)}>
                                <IconSvg svgPath={IconClose} />
                            </div>
                        </StylesModal.Header>

                        <Styles.Container>
                            <CardGraph className="card" title="Informações da Fazenda" svgPath={IconPin}>
                                <Field name="Infos" component={FormInfos} />
                            </CardGraph>

                            <CardGraph className="card" title="Pessoas da Fazenda" svgPath={IconPeoples}>
                                <Styles.ButtonContainer>
                                    <Styles.Button
                                        type="button"
                                        onClick={() => handleOnClickAdd(user, dispatch)}
                                    >
                                        {`Adicionar ${newUserRole}`}
                                    </Styles.Button>
                                </Styles.ButtonContainer>

                                <FastField name="Infos">
                                    {({ form }) => <PeoplesTable form={form} />}
                                </FastField>
                                {/* <Field name="Infos" component={PeoplesTable} /> */}
                            </CardGraph>

                        </Styles.Container>

                        <Styles.Footer>
                            <GroupFormButton
                                addButton
                                label="Cancelar"
                                handleAction={() => handleCloseModal(props)}
                                handleSave={() => handleOnClickSave(props)}
                            />
                        </Styles.Footer>
                    </Styles.Wrapper>
                )}
            </Formik>
        </StylesModal.Modal>
    );
};

export default AddFarmModal;
