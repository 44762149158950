import produce from 'immer';

const INITIAL_STATE = {
    data: {},
    loading: false,
    integrity: null,
    response: '',
};

export default function user(state = INITIAL_STATE, action) {
    switch (action.type) {
    case '@user/USER_LOADING':
        return produce(state, (draft) => {
            draft.loading = true;
        });

    case '@user/USER_REQUEST':
        return produce(state, (draft) => {
            draft.loading = true;
        });

    case '@user/USER_SUCCESS':
        return produce(state, (draft) => {
            draft.data = action.payload.user;
            draft.loading = false;
        });

    case '@user/USER_CLEAR_STATE':
        if (action.payload.user) {
            return produce(state, (draft) => {
                draft.integrity = null;
                draft.response = '';
            });
        }
        return INITIAL_STATE;

    case '@user/USER_ERROR':
        return produce(state, (draft) => {
            draft.loading = false;
            draft.response = action.payload.response;
        });

    case '@user/USER_REQUEST_PASSWORD_RESET_CODE_SUCCESS':
        return produce(state, (draft) => {
            draft.response = 'REQUEST_CODE_SUCCESS';
            draft.loading = false;
        });

    case '@user/USER_VALIDATE_PASSWORD_RESET_CODE_SUCCESS':
        return produce(state, (draft) => {
            draft.response = 'VALIDATE_CODE_SUCCESS';
            draft.loading = false;
            draft.integrity = action.payload.integrity;
        });

    case '@user/USER_UPDATE_PASSWORD_SUCCESS':
        return produce(state, (draft) => {
            draft.integrity = null;
            draft.response = 'UPDATE_PASSWORD_SUCCESS';
            draft.loading = false;
        });

    case '@user/USER_ADD_FINISHED':
        return produce(state, (draft) => {
            draft.loading = false;
        });

    case '@user/USER_REMOVE_FINISHED':
        return produce(state, (draft) => {
            draft.loading = false;
        });

    default:
        return state;
    }
}
