import { gql } from 'graphql-request';

const UPDATE_USER = gql`
    mutation Update($id:ID!, $name:String, $phone: String, $active:Boolean){
        userUpdate(id:$id, name:$name, phone:$phone, active:$active){
            id,
            email,
            name,
            active,
            phone,
            role {
                name
                id
            }
        }
    }
`;

export default UPDATE_USER;
