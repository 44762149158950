import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        display:flex;
        flex-direction: column;
        align-items: center;
        margin-right: ${theme.spacings.xxsmall};
        padding: 0 ${theme.spacings.xxsmall};

        &:last-child{ margin-right:0; }
    `}
`;

export const Date = styled.span`
    ${({ theme }) => css`
        font-weight: bold;
        color:${theme.colors.black};
        font-size:${theme.font.sizes.xsmall};
        margin-bottom:${theme.spacings.xxsmall};
    `}
`;

export const Hour = styled.span`
    ${({ theme }) => css`
        color:${theme.colors.gray};
        font-size:${theme.font.sizes.xsmall};
        margin-bottom:${theme.spacings.medium};
    `}
`;

export const Card = styled.div`
    ${({ theme }) => css`
        display:flex;
        flex-direction:column;
        width: 7rem;
        align-items:center;
        position:relative;
        box-shadow:2px 2px 10px rgba(0,0,0,0.1);
        border-radius: ${theme.border.radius};
        padding: ${theme.spacings.small};
    `}

    img {
        width: 4rem;
        height: 4rem;
        object-fit: cover;
        position: absolute;
        top: -20px;
        border-radius: 100px;
        box-shadow: 0px 0px 20px rgba(0,0,0, .2);
    }
`;

export const Temperature = styled.p`
    ${({ theme }) => css`
        color:${theme.colors.black};
        font-weight:bold;
        font-size:${theme.font.sizes.small};
        margin-top: ${theme.spacings.xxsmall};
    `}
`;

export const Rain = styled.p`
    ${({ theme }) => css`
        font-size:${theme.font.sizes.xxsmall};
        color:${theme.colors.gray};
        text-align:center;
        font-weight:light;
    `}
`;
