import client from '../../../graphql/client';
import { handleSuccess, handleError } from '../../../utils/functions';
import RESET_PASSWORD from '../../../graphql/queries/user/resetPassword';
import UPDATE_USER from '../../../graphql/mutations/user/updateUser';
import { ADD_MANAGER, ADD_CONSULTANT, ADD_CUSTOMER } from '../../../graphql/mutations/user/addUser';
import VALIDATE_CODE from '../../../graphql/queries/user/validateCode';
import UPDATE_PASSWORD from '../../../graphql/mutations/user/updatePassword';
import REMOVE_USER from '../../../graphql/mutations/user/removeUser';

export async function updateUser({ payload }) {
    const {
        id, name, phone, active,
    } = payload;

    try {
        const data = await client.request(UPDATE_USER, {
            id, name, phone, active,
        });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function resetPassword({ payload }) {
    const { email } = payload;

    try {
        const data = await client.request(RESET_PASSWORD, { email });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function addManager({ payload }) {
    const {
        name, email, phone, password, roleId, userCreatorId,
    } = payload.user;
    const newUser = {
        name, email, phone, password, roleId, userCreatorId,
    };

    try {
        const data = await client.request(ADD_MANAGER, newUser);
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function validateCode({ payload }) {
    const { email, code } = payload;

    try {
        const data = await client.request(VALIDATE_CODE, { email, code });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function addConsultant({ payload }) {
    const {
        name, email, phone, password, roleId, userCreatorId,
    } = payload.user;
    const newUser = {
        name, email, phone, password, roleId, userCreatorId,
    };

    try {
        const data = await client.request(ADD_CONSULTANT, newUser);
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function addCustomer({ payload }) {
    const {
        name, email, phone, password, roleId, userCreatorId,
    } = payload.user;
    const newUser = {
        name, email, phone, password, roleId, userCreatorId,
    };

    try {
        const data = await client.request(ADD_CUSTOMER, newUser);
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function updatePassword({ payload }) {
    const { password, confirm, integrity } = payload;
    try {
        const data = await client.request(UPDATE_PASSWORD, { password, confirm, integrity });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function removeUser({ payload }) {
    const { id } = payload;
    try {
        const data = await client.request(REMOVE_USER, { id });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}
