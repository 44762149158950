import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const Container = styled.div``;

export const WrapperGraphs = styled.div`
    width:100%;
`;

export const Content = styled.div`
    ${() => css`
        width:100%;
    `}
`;
