import styled, { css } from 'styled-components';

export const Container = styled.div`
   ${({ theme }) => css`
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom:${theme.spacings.xsmall};

        & p {
            font-size:${theme.font.sizes.xxsmall};
            color:${theme.colors.gray};
            padding-bottom:${theme.spacings.small};
        }
   `}
`;

export const Loader = styled.div`
   ${({ theme }) => css`
        border: 8px solid ${theme.colors.lightGray};
        border-radius: 50%;
        border-top: 8px solid ${theme.colors.primary};
        width: 48px;
        height: 48px;
        animation: spin 1.2s linear infinite;

        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
    `}
`;

export const Button = styled.button`
   ${({ theme }) => css`
        outline:none;
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius:${theme.border.radius};
        padding:${theme.spacings.xsmall};
        background-color:${theme.colors.green};
        color:${theme.colors.white};
        font-size:${theme.font.sizes.xxsmall};

        &:hover {
            transition:.5s;
            opacity: 0.8;
        }
   `}
`;

export const Table = styled.table`
   ${({ theme }) => css`
        width: 100%;
        border-spacing: 0;
        border: 1px solid ${theme.colors.lightGray};
        font-size: ${theme.font.sizes.xxsmall};
        color: ${theme.colors.gray};
        
        th { font-size: ${theme.font.sizes.xsmall}; }
        th, td {
            padding: .6rem 1rem;
            height: 52px;
            vertical-align: middle;
            text-align: left;
            border-bottom: 1px solid ${theme.colors.lightGray};
            
            &:nth-child(1) { 
                min-width: 50px !important; 
                max-width: 50px !important;
                & span { padding-left: 0 !important; }
            }
            &:nth-child(2) { 
                min-width: 64px !important; 
                max-width: 64px !important; 
            }
            &:nth-child(3) { 
                min-width: 100% !important; 
                max-width: 100% !important; 
            }
            &:nth-child(4) { 
                min-width: 50% !important; 
                max-width: 50% !important; 
            }
            &:nth-child(5) { 
                min-width: 100px !important; 
                max-width: 100px !important; 
            }
            &:nth-child(6) { 
                min-width: 140px !important; 
                max-width: 140px !important; 
                width: 140px !important;
            }
        }

        &.consultant {
            th, td {
                &:nth-child(2) { 
                    min-width: 100% !important; 
                    max-width: 100% !important;
                }
                &:nth-child(3) { 
                    min-width: 50% !important; 
                    max-width: 50% !important; 
                }
                &:nth-child(4) { 
                    min-width: 100px !important; 
                    max-width: 100px !important; 
                }
                &:nth-child(5) { 
                    min-width: 140px !important; 
                    max-width: 140px !important;
                    width: 140px !important;
                }
            }
        }

        & .id-container {
            display: flex;
            align-items: center;
            width: 54px !important;
        }

        & .actions-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            min-width: 120px !important; 
            max-width: 120px !important; 
            width: 120px !important;

            & > .action {
                height: 24px !important;
                margin: 4px;

                &.add { background-color: ${theme.colors.green}; }
                &.person { background-color: ${theme.colors.primary}; }
                &.delete { background-color: ${theme.colors.danger}; }
            }
        }
    `}
`;
