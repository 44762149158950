import styled from 'styled-components';

export const Form = styled.div`
    display: grid;
    grid-template-columns: 1fr;

    .wrapper {
        display: grid;
        grid-template-columns: 1fr 3fr;
    }
`;
