import produce from 'immer';

const INITIAL_STATE = {
    name: '',
    props: {
        isOpen: false,
        isEdit: false,
        isPivot: false,
    },
};

export default function modal(state = INITIAL_STATE, action) {
    switch (action.type) {
    case '@modal/SHOW_MODAL':
        return produce(state, (draft) => {
            draft.name = action.payload.name;
            draft.props = action.payload.props;
        });

    case '@modal/HIDE_MODAL':
        return produce(state, (draft) => {
            draft.name = action.payload.name;
            draft.props = action.payload.props;
        });

    default: {
        return state;
    }
    }
}
