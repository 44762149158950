/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pt-br';
import { meteoblue } from '../../utils/helpers/weather';
import iconWind from '../../assets/images/weather/wind.png';
import * as Styles from './styles';

export const CardTemperature = () => {
    const { weatherData, loading } = useSelector((state) => state.farm);

    const [selectFarm, setSelectFarm] = useState('');

    const handleSelectFarm = useCallback((evt) => {
        setSelectFarm(evt.target.value);
    }, []);

    const memoFarmSelected = useMemo(() => {
        const findFarm = weatherData.find((farm) => farm.id === selectFarm);

        if (!findFarm) return null;

        if (!findFarm.areas.length) return null;

        if (!findFarm.areas[0].forecast.length) return null;

        return findFarm;
    }, [selectFarm, weatherData]);

    const memoForecastFormated = useMemo(() => {
        if (!memoFarmSelected) return null;
        const array = memoFarmSelected.areas[0].forecast.map((forecast) => {
            const [date, fullHour] = forecast.label.split('T');
            const [hour] = fullHour.split('-');

            return {
                ...forecast,
                verify: date,
                hour,
            };
        });
        return array;
    }, [memoFarmSelected]);

    useEffect(() => {
        if (weatherData.length) {
            setSelectFarm(weatherData[0].id);
        }
    }, [weatherData]);

    return (
        <Styles.Container>
            <Styles.SelectContainer>
                <div className="container-select">
                    <div className="group">
                        <label>Selecione fazenda:</label>
                        <select onChange={handleSelectFarm}>
                            {loading && <option>Carregando...</option>}
                            {weatherData.map((farm) => <option key={farm.id} value={farm.id}>{farm.name}</option>)}
                        </select>
                    </div>
                </div>
            </Styles.SelectContainer>

            {!memoForecastFormated || !memoForecastFormated.length
                ? (
                    <div className="not-found">
                        <img src={iconWind} />
                        <span>Não existem dados para esta fazenda :(</span>
                    </div>
                )
                : (
                    <Styles.TemperatureContainer>
                        {memoForecastFormated?.map((forecast, index) => (
                            <Styles.Temperature key={index}>

                                <div className="infos">
                                    <span className="date">{moment(new Date(forecast.label)).locale('pt-br').format('ll')}</span>
                                    <span className="temperature">
                                        {forecast.max_temp}
                                        ° C
                                    </span>
                                </div>
                                <div className="hours" style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <span className="percent">
                                        {forecast.hour}
                                    </span>
                                </div>

                                <img src={meteoblue[forecast.is_day][forecast.type - 1]} />

                                <div className="group">
                                    <span className="percent">
                                        {forecast.precip_probabil}
                                        %
                                    </span>
                                    <span className="description none">
                                        Probabilidade
                                        <br />
                                        de chuva
                                    </span>
                                </div>
                                <div className="group">
                                    <span className="intensity">
                                        {forecast.precip_intensity}
                                        mm
                                    </span>
                                    <span className="description none">
                                        Intensidade
                                        <br />
                                        de chuva
                                    </span>
                                </div>
                            </Styles.Temperature>
                        ))}

                    </Styles.TemperatureContainer>
                )}

        </Styles.Container>
    );
};
