import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        border:1px solid ${theme.colors.lightGray};
        padding:${theme.spacings.xsmall};
    `}
`;
export const Title = styled.div`
    ${({ theme }) => css`
        font-size:${theme.font.sizes.small};
        color:${theme.colors.gray};  
        text-align:center;      
    `}
`;
export const Row = styled.div`
    display:flex;
    flex-direction:row;
    align-items:flex-start;
`;
export const Group = styled.div`
    ${({ theme }) => css`
        margin: ${theme.spacings.xxsmall};
    `} 
`;

export const Label = styled.h4`
    ${({ theme }) => css`
        font-size:${theme.font.sizes.xxsmall};
        color:${theme.colors.gray};
    `}
`;

export const Value = styled.span`
    ${({ theme }) => css`
        font-size:${theme.font.sizes.xxsmall};
        color:${theme.colors.gray};
    `}
`;

export const ContainerFooter = styled.div`
    ${() => css`
        display:flex;
        align-items: center;
        justify-content: space-between;
    `}
`;

export const Button = styled.button`
    ${({ theme }) => css`
        width:4rem;
        height:4rem;
        border-radius:2rem;
        background:${theme.colors.green};
    `}
`;

export const Footer = styled.div`
    ${({ theme }) => css`
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    border-top:1px solid ${theme.colors.lightGray};
    padding:${theme.spacings.xxsmall} 0;
    button {
        width:30px;
        height:25px;
        margin-right:${theme.spacings.xxsmall};
        &:nth-child(1){
            background: ${theme.colors.green};
        }
        
        &:nth-child(2){
            background: ${theme.colors.danger};
        }
        span {
            font-size:${theme.font.sizes.xsmall} !important;
            margin-right:0;
        }
    `}
`;
