import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Info from './Info';
import Consultant from './Consultant';
import Farm from './Farm';
import ButtonFloat from '../../ButtonFloat';

import * as Styles from './styles';

const ModalCustomer = () => {
    const { data: user } = useSelector((state) => state.user);

    const [infos, setInfosActive] = useState(true);
    const [consultants, setConsultants] = useState(false);
    const [farms, setFarms] = useState(false);

    const [farmModalIsVisible, setFarmModalIsVisible] = useState(false);

    const handleClickMenu = (menu) => {
        if (menu === 'infos') {
            setInfosActive(true);
            setConsultants(false);
            setFarms(false);
        } else if (menu === 'consultants') {
            setInfosActive(false);
            setConsultants(true);
            setFarms(false);
        } else {
            setInfosActive(false);
            setConsultants(false);
            setFarms(true);
        }
    };

    const handleOpenAddFarmModal = () => setFarmModalIsVisible((value) => !value);

    const tabNameAccess = () => {
        switch (user.role.name) {
        case 'Gerente': return 'Consultores';
        case 'Administrador': return 'Gerentes';
        default: return 'Clientes';
        }
    };

    return (
        <>
            <Styles.Container>
                <Styles.Nav>
                    <Styles.Menu active={infos} onClick={() => handleClickMenu('infos')}>Minhas Informações</Styles.Menu>
                    {user.role.name !== 'Cliente'
                        && (
                            <Styles.Menu active={consultants} onClick={() => handleClickMenu('consultants')}>
                                {tabNameAccess()}
                            </Styles.Menu>
                        )}
                    <Styles.Menu active={farms} onClick={() => handleClickMenu('farms')}>Fazendas</Styles.Menu>
                </Styles.Nav>

                <Styles.Body>
                    {infos && <Info />}
                    {consultants && <Consultant />}
                    {farms && <Farm modalIsVisible={farmModalIsVisible} setModalIsVisible={setFarmModalIsVisible} />}
                </Styles.Body>
            </Styles.Container>

            {farms && user.role.name !== 'Cliente' ? (
                <ButtonFloat
                    style={{
                        right: 'calc(5vw + 2.6rem)',
                        bottom: 'calc(5vh + 0.9rem)',
                    }}
                    onClick={handleOpenAddFarmModal}
                />
            ) : <></>}
        </>
    );
};

export default ModalCustomer;
