import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
    max-width: 130rem;
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin:0 auto;

    h2 {
        font-size:${theme.font.sizes.xlarge};
        color:${theme.colors.gray};
        margin-top:${theme.spacings.medium};
    }

        p {
            color:${theme.colors.gray};
            font-size:${theme.font.sizes.medium};
        }

        img {
            width:20rem;
            height:20rem;
            object-fit:contain;
        }
    `}
`;
