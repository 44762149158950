const toggleAboveSelectedUser = (form, currentProducer, user, selectedUser, data) => {
    if (user.subRows.length !== 0) user.subRows.map((subUser) => toggleAboveSelectedUser(form, currentProducer, subUser, selectedUser, data));
    if (user.id === selectedUser.userCreatorId) {
        user.checked = true;
        data.map((subUser) => toggleAboveSelectedUser(form, currentProducer, subUser, user, data));
        if (user.role.name === 'Cliente' && user.id === currentProducer?.id) {
            form.setFieldValue('producer', '');
            form.setFieldValue('consultant', '');
        }
    }
};

const toggleBelowSelectedUser = (form, currentProducer, user, selectedUser, data, checked) => {
    if (user.subRows.length !== 0) user.subRows.map((subUser) => toggleBelowSelectedUser(form, currentProducer, subUser, selectedUser, data, checked));
    if (user.id === selectedUser.id) user.checked = !checked;
    if (user.userCreatorId === selectedUser.id) {
        user.checked = !checked;
        if (user.subRows.length !== 0) data.map((subUser) => toggleBelowSelectedUser(form, currentProducer, subUser, user, data, checked));
        if (user.role.name === 'Cliente' && user.id === currentProducer?.id) {
            form.setFieldValue('producer', '');
            form.setFieldValue('consultant', '');
        }
    }
};

const getSelectedUsers = (user, users) => {
    if (user.subRows.length !== 0) user.subRows.map((subUser) => getSelectedUsers(subUser, users));
    users.push(user);
};

export const handleOnChangeCheckbox = (selectedUser, data, setData, form) => {
    const newData = [...data];

    let currentProducer = {};
    if (form.values.producer) currentProducer = JSON.parse(form.values.producer);

    newData.map((user) => toggleAboveSelectedUser(form, currentProducer, user, selectedUser, newData));
    newData.map((user) => toggleBelowSelectedUser(form, currentProducer, user, selectedUser, newData, selectedUser.checked));

    form.values.tableChange = newData;
    setData(newData);

    const users = [];
    newData.map((user) => getSelectedUsers(user, users));
    const selectedUsers = users.filter((user) => user.checked);
    form.setFieldValue('selectedUsers', selectedUsers);

    if (selectedUser.id === currentProducer?.id && !selectedUser.checked) {
        form.setFieldValue('producer', '');
        form.setFieldValue('consultant', '');
    }
};
