import { gql } from 'graphql-request';

const UPDATE_PASSWORD = gql`
    mutation UPDATE_PASSWORD($password:String!, $confirm:String!, $integrity:String!){
        updatePassword(password:$password, confirm:$confirm, integrity:$integrity){
            error
            message
            code
        }
    }
`;

export default UPDATE_PASSWORD;
