import React from 'react';

import IosCheckmarkCircle from 'react-ionicons/lib/IosCheckmarkCircle';

import * as Styles from './styles';

const Selector = ({
    on, onClick, children,
}) => (
    <Styles.Container
        onClick={onClick}
    >
        <Styles.ContainerCheck>
            {children[0]}
            {
                on && (
                    <Styles.Check>
                        <IosCheckmarkCircle fontSize="20px" color="#74b700" />
                    </Styles.Check>

                )
            }
        </Styles.ContainerCheck>
        {children[1]}

    </Styles.Container>
);
export default Selector;
