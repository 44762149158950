import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import SignIn from '../pages/Auth/SignIn';
import Main from '../pages/Main';
import Reports from '../pages/Reports';
import Modals from '../components/Modals';
import ReportsBh from '../pages/ReportsBh';

export default function Routes() {
    return (
        <>
            <Switch>
                <Route path="/login" component={SignIn} />
                <PrivateRoute path="/reports" component={Reports} />
                <PrivateRoute path="/reportsbh" component={ReportsBh} />
                <PrivateRoute path="/" component={Main} />
            </Switch>
            <Modals />
        </>

    );
}
