import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        width:5rem;
        height:5rem;
        border-radius:2.5rem;
        border:.2rem solid ${theme.colors.green};
        background-color:#fff;

        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
    `}
`;

export const Wrapper = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 2rem;
    width: 8rem;
`;

export const Label = styled.span`
        ${({
        theme, large, small,
    }) => css`
        color:${theme.colors.black};

        ${large && `font-size:${theme.font.sizes.small}; color:${theme.colors.green};`};
        ${small && `font-size:${theme.font.sizes.xxxsmall}; color:${theme.colors.green};`};
    `}
`;
