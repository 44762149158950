import styled, { css } from 'styled-components';
import { Field } from 'formik';

export const Wrapper = styled.div``;

export const Container = styled.div`
    ${({ theme }) => css`
        input {
            color:${theme.colors.primary}
        }
    `}
`;

export const TextField = styled(Field)`
    width: 97.5%;
`;
