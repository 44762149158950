import { gql } from 'graphql-request';

const ARCHIVED_AREA = gql`
    mutation Archived($id:ID!){
        areaArchive(id:$id){
            error,
            message           
        }
    }
`;

export default ARCHIVED_AREA;
