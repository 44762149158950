import * as Tools from './tools';
import dbcitiesBR from '../../assets/cities/cities-BR.json';

const Geo = {
    toRadians: (degrees) => degrees * Math.PI / 180,
    toDegrees: (radians) => radians * 180 / Math.PI,
    getRegionInKilometer: (region, ratio, delta) => {
        const { latitude, latitudeDelta } = region;
        const lonDelta = latitudeDelta * ratio;
        const mapHeight = latitudeDelta * 40008000 / 360;
        const mapWidth = ((lonDelta - lonDelta * delta)) * 40075160 * Math.cos(Geo.toRadians(latitude)) / 360;

        return { x: mapWidth / 1000, y: mapHeight / 1000 };
    },
    getRegionArea: (lat, lon, dist) => {
        const adjust = 0.008983112; // 1km in degrees at equator.
        const lonRatio = 1 / Math.cos(Geo.toRadians(lat));

        const north = lat + (dist * adjust);
        const south = lat - (dist * adjust);
        const east = lon + (dist * adjust) * lonRatio;
        const west = lon - (dist * adjust) * lonRatio;
        return [north, south, west, east];
    },
    getCity: (keyword = '') => {
        const dbcities = dbcitiesBR;

        if (keyword.indexOf(',') !== -1) {
            const latLon = keyword.replace(' ', '').split(',');
            return {
                lat: latLon[0],
                lng: latLon[1],
            };
        }
        return dbcities.filter((city) => Tools.normalizeString(city.name) === Tools.normalizeString(keyword))[0];
    },
    getPolygonArea: (coordinates = []) => {
        const size = coordinates.length;

        if (size < 3) {
            return 0;
        }

        let total = 0;
        const prev = coordinates[size - 1];
        let prevTanLat = Math.tan((Math.PI / 2 - Geo.toRadians(prev.latitude)) / 2);
        let prevLng = Geo.toRadians(prev.longitude);

        coordinates.forEach((point) => {
            const tanLat = Math.tan((Math.PI / 2 - Geo.toRadians(point.latitude)) / 2);
            const lng = Geo.toRadians(point.longitude);
            total += Geo.polarTriangleArea(tanLat, lng, prevTanLat, prevLng);
            prevTanLat = tanLat;
            prevLng = lng;
        });

        // in square meters, with 6378137 radius earth
        return Geo.getSquareKm(total * (6378137 * 6378137));
    },
    polarTriangleArea(tan1, lng1, tan2, lng2) {
        const deltaLng = lng1 - lng2;
        const t = tan1 * tan2;
        return 2 * Math.atan2(t * Math.sin(deltaLng), 1 + t * Math.cos(deltaLng));
    },
    getSquareKm(meters) {
        return Math.abs(meters) / 1e+6; // Math.round( (Math.abs(meters) / 1e+6) * 100) / 100; // 1e+6 = 10^6 = 1000000
    },
    getPolygonCenter(coordinates) {
        let lat;
        let lon;

        if (!(coordinates.length > 0)) {
            return false;
        }

        const lenCoords = coordinates.length;

        let X = 0.0;
        let Y = 0.0;
        let Z = 0.0;

        for (let i = 0; i < lenCoords; i++) {
            lat = Geo.toRadians(coordinates[i].latitude);
            lon = Geo.toRadians(coordinates[i].longitude);

            const a = Math.cos(lat) * Math.cos(lon);
            const b = Math.cos(lat) * Math.sin(lon);
            const c = Math.sin(lat);

            X += a;
            Y += b;
            Z += c;
        }

        X /= lenCoords;
        Y /= lenCoords;
        Z /= lenCoords;

        lon = Math.atan2(Y, X);
        const hyp = Math.sqrt(X * X + Y * Y);
        lat = Math.atan2(Z, hyp);

        const latitude = Geo.toDegrees(lat);
        const longitude = Geo.toDegrees(lon);

        return { latitude, longitude };
    },
    getBoudingBox(coordinates = []) {
        const coorLen = coordinates.length;

        let minx = Number.POSITIVE_INFINITY;
        let miny = Number.POSITIVE_INFINITY;
        let maxx = Number.NEGATIVE_INFINITY;
        let maxy = Number.NEGATIVE_INFINITY;

        for (let i = 0; i < coorLen; i++) {
            const coordinate = coordinates[i];
            const { latitude } = coordinate;
            const { longitude } = coordinate;

            if (latitude < minx) (minx = latitude);
            if (longitude < miny) (miny = longitude);
            if (latitude > maxx) (maxx = latitude);
            if (longitude > maxy) (maxy = longitude);
        }

        return [
            {
                latitude: minx,
                longitude: miny,
            },
            {
                latitude: maxx,
                longitude: miny,
            },
            {
                latitude: maxx,
                longitude: maxy,
            },
            {
                latitude: minx,
                longitude: maxy,
            },
        ];
    },

};

export default Geo;
