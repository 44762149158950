import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import * as TOOLS from '../../utils/helpers/tools';
import * as Styles from './styles';
import { accessRole } from '../../utils/functions';

const ContainerPlanting = () => {
    const { dataArea, loadingAverage } = useSelector((state) => state.area);
    const { data: user } = useSelector((state) => state.user);

    const memoRole = useMemo(() => user.role.name, [user.role]);

    const {
        bh_yesterday,
        created_at,
    } = dataArea;
    const sizeArea = useMemo(() => dataArea != null && (Number(dataArea.size) * 100).toFixed(2).replace('.', ','), [dataArea]);
    const memoSowingDays = useMemo(() => moment(dataArea?.area_crops?.sowing_date).format('DD/MM/YYYY'), [dataArea.area_crops]);
    const memoHarvestYear = TOOLS.checkForHarvest(dataArea?.area_crops?.sowing_date);
    const memoDateCreateArea = useMemo(() => moment(created_at).format('DD/MM/YYYY'), [created_at]);
    const memoTimeToIrrigate = useMemo(() => (bh_yesterday?.irrigation_time != null ? bh_yesterday?.irrigation_time : null), [bh_yesterday]);
    const memoIrrigation = useMemo(() => `${bh_yesterday?.complementarWater == null ? '0' : bh_yesterday?.complementarWater.toFixed(1).replace('.', ',')}`, [bh_yesterday]);
    const memoPercentimeter = useMemo(() => (bh_yesterday?.percentimeter == null ? null : `${bh_yesterday?.percentimeter.toFixed(1).replace('.', ',')}%`), [bh_yesterday]);
    return (
        <Styles.Container>

            <Styles.Label>
                {'Área Total: '}
                <Styles.Value>{` ≅ ${sizeArea} ha`}</Styles.Value>
            </Styles.Label>

            <Styles.Label>
                {'Temperatura média do ar: '}
                {loadingAverage ? <Skeleton height={15} width={80} /> : <Styles.Value>{`${(Math.round(dataArea?.average_temp_since * 100) / 100).toString().replace('.', ',')}°C`}</Styles.Value> }
            </Styles.Label>

            <Styles.Label>
                {'Data de plantio: '}
                <Styles.Value>{memoSowingDays}</Styles.Value>
            </Styles.Label>
            <Styles.Label>
                {'Data de criação: '}
                <Styles.Value>{memoDateCreateArea}</Styles.Value>
            </Styles.Label>
            <Styles.Label>
                {'Safra: '}
                <Styles.Value>{!memoHarvestYear.error && memoHarvestYear.result}</Styles.Value>
            </Styles.Label>
            {accessRole(memoRole)
            && (
                <Styles.Label>
                    {'Dias de ciclo total: '}
                    <Styles.Value>{dataArea.area_crops?.cycle_days}</Styles.Value>
                </Styles.Label>
            )}
            <Styles.Label>
                {'Dias desde a semeadura: '}
                <Styles.Value>{dataArea?.area_crops?.sowing_days}</Styles.Value>
            </Styles.Label>
            <Styles.Label className="irrigation">
                {'Lâmina a irrigar: '}
                <Styles.Value>
                    {`${memoIrrigation} mm`}
                </Styles.Value>
            </Styles.Label>
            {memoTimeToIrrigate
                && (
                    <Styles.Label className="irrigation">
                        {'Tempo de gotejo: '}
                        <Styles.Value>
                            {memoTimeToIrrigate}
                        </Styles.Value>
                    </Styles.Label>
                )}
            {memoPercentimeter && memoTimeToIrrigate === null
                && (
                    <Styles.Label className="irrigation">
                        {'Percentímetro: '}
                        <Styles.Value>
                            {`${memoPercentimeter}`}
                        </Styles.Value>
                    </Styles.Label>
                )}
            <Styles.Label>
                {'Cultura: '}
                <Styles.Value>
                    {dataArea?.area_crops?.crop?.culture_name}
                </Styles.Value>
            </Styles.Label>
        </Styles.Container>
    );
};

export default ContainerPlanting;
