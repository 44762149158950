import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { isEqual, format } from 'date-fns';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import * as Styles from './styles';

const TableBh = () => {
    const { dataArea } = useSelector((state) => state.area);
    const [rows, setRows] = useState([]);

    const showLabels = useMemo(() => {
        if (dataArea && dataArea?.area_irrigation_system) {
            const irrigationType = dataArea.area_irrigation_system?.irrigation_type?.name;

            switch (irrigationType) {
            case 'Pivô central':
                return false;

            case 'Pivô central LEPA':
                return true;

            case 'Gotejamento':
                return true;

            default:
                return false;
            }
        }

        return false;
    }, [dataArea]);

    const createData = (
        date,
        sowingDays,
        kc,
        root_depth,
        hundred,
        humidity_actual,
        cad_root,
        ATD,
        eto_pm,
        etc,
        etc_adj,
        deficit,
        complementarwater,
        efficiency,
        tempoIrrigarOrPercentimenter,
        irrigation,
        rain,
        kl,
        ks,
        pam,
        pas,
    ) => ({
        date,
        sowingDays,
        kc,
        root_depth,
        hundred,
        humidity_actual,
        cad_root,
        ATD,
        eto_pm,
        etc,
        etc_adj,
        deficit,
        complementarwater,
        efficiency,
        tempoIrrigarOrPercentimenter,
        irrigation,
        rain,
        kl,
        ks,
        pam,
        pas,
    });

    const waterDateMatchBhDate = useCallback((dateBh) => {
        if (!dataArea.water) {
            return {
                irrigation: 0,
                rain: 0,
            };
        }

        const findDataWaterDate = dataArea?.water?.filter((water) => {
            const findDate = isEqual(new Date(water.date), new Date(dateBh));

            if (findDate) {
                return {
                    ...water,
                };
            }
            return null;
        });

        if (findDataWaterDate.length) return findDataWaterDate[0];
        return {
            irrigation: 0,
            rain: 0,
        };
    }, [dataArea]);

    useEffect(() => {
        if (dataArea && dataArea.bh) {
            setRows(
                dataArea?.bh?.map((bh) => (
                    createData(
                        format(new Date(bh._date), 'dd/MM/yyyy'),
                        bh?.sowingdays,
                        bh?.kc,
                        bh?.root_depth,
                        100,
                        ((bh?.ATD * 100) / bh?.cadRoot)?.toFixed(1),
                        bh?.cadRoot,
                        bh?.ATD?.toFixed(1),
                        bh?.eto_pm?.toFixed(1),
                        bh?.etc?.toFixed(1),
                        bh?.etc_adj?.toFixed(1),
                        bh?.deficit?.toFixed(1),
                        bh?.complementarWater?.toFixed(1),
                        dataArea?.area_irrigation_system?.efficiency,
                        showLabels ? bh?.irrigation_time : bh?.percentimeter,
                        waterDateMatchBhDate(bh._date)?.irrigation,
                        waterDateMatchBhDate(bh._date)?.rain,
                        showLabels && dataArea.kl,
                        showLabels && dataArea.ks,
                        showLabels && dataArea.pam,
                        showLabels && dataArea.pas,
                    )
                )),
            );
        }
    }, [dataArea, showLabels, waterDateMatchBhDate]);

    return (
        <Styles.Container>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Data</TableCell>
                            <TableCell align="left">Dias desde a semeadura</TableCell>
                            <TableCell align="left">Kc</TableCell>
                            <TableCell align="left">Profundidade Radicular(cm)</TableCell>
                            <TableCell align="left">CAD Máxima (%)</TableCell>
                            <TableCell align="left">Umidade Atual</TableCell>
                            <TableCell align="left">CAD Máxima (mm)</TableCell>
                            <TableCell align="left">Umidade Atual (mm)</TableCell>
                            <TableCell align="left">ETo (mm)</TableCell>
                            <TableCell align="left">ETc (mm)</TableCell>
                            <TableCell align="left">ETr (mm)</TableCell>
                            <TableCell align="left">Défict (mm)</TableCell>
                            <TableCell align="left">Lâmina a irrigar (mm)</TableCell>
                            <TableCell align="left">Eficiência (%)</TableCell>
                            {showLabels ? <TableCell align="left">Tempo a irrigar</TableCell> : <TableCell align="left">Percentímetro</TableCell>}
                            <TableCell align="left">Irrigação (mm)</TableCell>
                            <TableCell align="left">Chuva (mm)</TableCell>
                            {showLabels
                                && (
                                    <>
                                        <TableCell align="left">Kl</TableCell>
                                        <TableCell align="left">Ks</TableCell>
                                        <TableCell align="left">PAM(%)</TableCell>
                                        <TableCell align="left">PAS(%)</TableCell>
                                    </>
                                )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {row.date}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.sowingDays}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.kc}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.root_depth}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.hundred}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.humidity_actual}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.cad_root}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.ATD}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.eto_pm}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.etc}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.etc_adj}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.deficit}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.complementarwater}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.efficiency}
                                </TableCell>

                                <TableCell component="th" scope="row">
                                    {row.tempoIrrigarOrPercentimenter}
                                </TableCell>

                                <TableCell component="th" scope="row">
                                    {row.irrigation}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.rain}
                                </TableCell>
                                {showLabels
                                    && (
                                        <>
                                            <TableCell component="th" scope="row">
                                                {row.kl}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.ks}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.pam}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.pas}
                                            </TableCell>
                                        </>
                                    )}

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Styles.Container>
    );
};

export default TableBh;
