import clearDay from '../../assets/images/weather/clear-day.png';
import clearNight from '../../assets/images/weather/clear-night.png';
import rain from '../../assets/images/weather/rain.png';
import snow from '../../assets/images/weather/snow.png';
import sleet from '../../assets/images/weather/sleet.png';
import wind from '../../assets/images/weather/wind.png';
import fog from '../../assets/images/weather/fog.png';
import cloudy from '../../assets/images/weather/cloudy.png';
import partlyCloudyDay from '../../assets/images/weather/partly-cloudy-day.png';
import partlyCloudyNight from '../../assets/images/weather/partly-cloudy-night.png';

import meteo1 from '../../assets/images/weather/meteoblue/01_day.png';
import meteo2 from '../../assets/images/weather/meteoblue/02_day.png';
import meteo3 from '../../assets/images/weather/meteoblue/03_day.png';
import meteo4 from '../../assets/images/weather/meteoblue/04_day.png';
import meteo5 from '../../assets/images/weather/meteoblue/05_day.png';
import meteo6 from '../../assets/images/weather/meteoblue/06_day.png';
import meteo7 from '../../assets/images/weather/meteoblue/07_day.png';
import meteo8 from '../../assets/images/weather/meteoblue/08_day.png';
import meteo9 from '../../assets/images/weather/meteoblue/09_day.png';
import meteo10 from '../../assets/images/weather/meteoblue/10_day.png';
import meteo11 from '../../assets/images/weather/meteoblue/11_day.png';
import meteo12 from '../../assets/images/weather/meteoblue/12_day.png';
import meteo13 from '../../assets/images/weather/meteoblue/13_day.png';
import meteo14 from '../../assets/images/weather/meteoblue/14_day.png';
import meteo15 from '../../assets/images/weather/meteoblue/15_day.png';
import meteo16 from '../../assets/images/weather/meteoblue/16_day.png';
import meteo17 from '../../assets/images/weather/meteoblue/17_day.png';
import meteo18 from '../../assets/images/weather/meteoblue/18_day.png';
import meteo19 from '../../assets/images/weather/meteoblue/19_day.png';
import meteo20 from '../../assets/images/weather/meteoblue/20_day.png';
import meteo21 from '../../assets/images/weather/meteoblue/21_day.png';
import meteo22 from '../../assets/images/weather/meteoblue/22_day.png';
import meteo23 from '../../assets/images/weather/meteoblue/23_day.png';
import meteo24 from '../../assets/images/weather/meteoblue/24_day.png';
import meteo25 from '../../assets/images/weather/meteoblue/25_day.png';
import meteo26 from '../../assets/images/weather/meteoblue/26_day.png';
import meteo27 from '../../assets/images/weather/meteoblue/27_day.png';
import meteo28 from '../../assets/images/weather/meteoblue/28_day.png';
import meteo29 from '../../assets/images/weather/meteoblue/29_day.png';
import meteo30 from '../../assets/images/weather/meteoblue/30_day.png';
import meteo31 from '../../assets/images/weather/meteoblue/31_day.png';
import meteo32 from '../../assets/images/weather/meteoblue/32_day.png';
import meteo33 from '../../assets/images/weather/meteoblue/33_day.png';
import meteo34 from '../../assets/images/weather/meteoblue/34_day.png';
import meteo35 from '../../assets/images/weather/meteoblue/35_day.png';

import meteo1n from '../../assets/images/weather/meteoblue/01_night.png';
import meteo2n from '../../assets/images/weather/meteoblue/02_night.png';
import meteo3n from '../../assets/images/weather/meteoblue/03_night.png';
import meteo4n from '../../assets/images/weather/meteoblue/04_night.png';
import meteo5n from '../../assets/images/weather/meteoblue/05_night.png';
import meteo6n from '../../assets/images/weather/meteoblue/06_night.png';
import meteo7n from '../../assets/images/weather/meteoblue/07_night.png';
import meteo8n from '../../assets/images/weather/meteoblue/08_night.png';
import meteo9n from '../../assets/images/weather/meteoblue/09_night.png';
import meteo10n from '../../assets/images/weather/meteoblue/10_night.png';
import meteo11n from '../../assets/images/weather/meteoblue/11_night.png';
import meteo12n from '../../assets/images/weather/meteoblue/12_night.png';
import meteo13n from '../../assets/images/weather/meteoblue/13_night.png';
import meteo14n from '../../assets/images/weather/meteoblue/14_night.png';
import meteo15n from '../../assets/images/weather/meteoblue/15_night.png';
import meteo16n from '../../assets/images/weather/meteoblue/16_night.png';
import meteo17n from '../../assets/images/weather/meteoblue/17_night.png';
import meteo18n from '../../assets/images/weather/meteoblue/18_night.png';
import meteo19n from '../../assets/images/weather/meteoblue/19_night.png';
import meteo20n from '../../assets/images/weather/meteoblue/20_night.png';
import meteo21n from '../../assets/images/weather/meteoblue/21_night.png';
import meteo22n from '../../assets/images/weather/meteoblue/22_night.png';
import meteo23n from '../../assets/images/weather/meteoblue/23_night.png';
import meteo24n from '../../assets/images/weather/meteoblue/24_night.png';
import meteo25n from '../../assets/images/weather/meteoblue/25_night.png';
import meteo26n from '../../assets/images/weather/meteoblue/26_night.png';
import meteo27n from '../../assets/images/weather/meteoblue/27_night.png';
import meteo28n from '../../assets/images/weather/meteoblue/28_night.png';
import meteo29n from '../../assets/images/weather/meteoblue/29_night.png';
import meteo30n from '../../assets/images/weather/meteoblue/30_night.png';
import meteo31n from '../../assets/images/weather/meteoblue/31_night.png';
import meteo32n from '../../assets/images/weather/meteoblue/32_night.png';
import meteo33n from '../../assets/images/weather/meteoblue/33_night.png';
import meteo34n from '../../assets/images/weather/meteoblue/34_night.png';
import meteo35n from '../../assets/images/weather/meteoblue/35_night.png';

export const weather = {
    'clear-day': clearDay,
    'clear-night': clearNight,
    rain,
    snow,
    sleet,
    wind,
    fog,
    cloudy,
    'partly-cloudy-day': partlyCloudyDay,
    'partly-cloudy-night': partlyCloudyNight,
};

export const meteoblue = [
    [
        meteo1n,
        meteo2n,
        meteo3n,
        meteo4n,
        meteo5n,
        meteo6n,
        meteo7n,
        meteo8n,
        meteo9n,
        meteo10n,
        meteo11n,
        meteo12n,
        meteo13n,
        meteo14n,
        meteo15n,
        meteo16n,
        meteo17n,
        meteo18n,
        meteo19n,
        meteo20n,
        meteo21n,
        meteo22n,
        meteo23n,
        meteo24n,
        meteo25n,
        meteo26n,
        meteo27n,
        meteo28n,
        meteo29n,
        meteo30n,
        meteo31n,
        meteo32n,
        meteo33n,
        meteo34n,
        meteo35n,
    ],
    [
        meteo1,
        meteo2,
        meteo3,
        meteo4,
        meteo5,
        meteo6,
        meteo7,
        meteo8,
        meteo9,
        meteo10,
        meteo11,
        meteo12,
        meteo13,
        meteo14,
        meteo15,
        meteo16,
        meteo17,
        meteo18,
        meteo19,
        meteo20,
        meteo21,
        meteo22,
        meteo23,
        meteo24,
        meteo25,
        meteo26,
        meteo27,
        meteo28,
        meteo29,
        meteo30,
        meteo31,
        meteo32,
        meteo33,
        meteo34,
        meteo35,
    ],
];
