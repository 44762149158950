import styled, { css, keyframes } from 'styled-components';

const animate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;
export const Container = styled.div`
    ${({ theme }) => css`
        text-align:center;
        button {
            cursor:pointer;
            color:${theme.colors.primary};
            background:transparent;
            padding:${theme.spacings.xxsmall} ${theme.spacings.small};
            border-radius:5px;
            font-weight:bold;
            &:hover {
                transition:.5s;
                background:${theme.colors.primary};
                color:${theme.colors.white};
                box-shadow:2px 2px 8px rgba(0,0,0,0.1);
            }
            &:disabled{
                cursor:not-allowed;
                width: 14.8rem; 
                height: 3.1rem;
                background:${theme.colors.primary};
                color:${theme.colors.white};
                box-shadow:2px 2px 8px rgba(0,0,0,0.1);
                svg{
                    animation:  ${animate} 2s linear infinite;
                }
            }
           
        }
    
    `}
`;
