import { FRAGMENT_GET_WEATHER_BY_FARM } from '../../fragments/farms';

const GET_WEAHTHER_BY_FARM = /* GraphQL */ `
    query GET_WEAHTER_BY_FARM($userId:ID!){
        users(id:$userId){
            farms {
                ${FRAGMENT_GET_WEATHER_BY_FARM}
            }
        }
    }
`;

export default GET_WEAHTHER_BY_FARM;
