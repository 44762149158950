import styled, { css } from 'styled-components';
import { Field } from 'formik';

export const WrapperInput = styled.div`
    ${({ theme }) => css`
        input {
            color:${theme.colors.primary};
        }
    `}
`;
export const TextField = styled(Field)`
    width: 97.5%;
`;

export const ErrorMessageSelect = styled.div`
    font-size: 1.071rem;
    margin-top: 0.63rem;
    text-align: left;
    font-family: Lato,Poppins,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
    font-weight: 400;
    line-height: 1.66;
    color: #f44336;
`;
