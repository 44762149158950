import { FRAGMENT_AREA_BY_ID, FRAGMENT_AVERAGE_TEMP, FRAGMENT_BH } from '../../fragments/area';

export const GET_AREA_ID = /* GraphQL */ `

query GET_AREA_ID($id:ID!){
  areas(id:$id){
    ${FRAGMENT_AREA_BY_ID}
    }
  }
`;

export const GET_AREA_ID_BH = /* GraphQL */ `
query GET_AREA_ID($id:ID!){
  areas(id:$id){
    ${FRAGMENT_BH}
    }
  }
`;

export const GET_AREA_ID_AVERAGE = /* GraphQL */ `
query GET_AREA_ID($id:ID!){
  areas(id:$id){
    ${FRAGMENT_AVERAGE_TEMP}
    }
  }
`;
