import client from '../../../graphql/client';
import GET_AREA from '../../../graphql/queries/getArea';
import { GET_AREA_ID, GET_AREA_ID_AVERAGE, GET_AREA_ID_BH } from '../../../graphql/queries/area/getAreaById';
import REMOVE_AREA from '../../../graphql/mutations/area/removeById';
import ARCHIVED_AREA from '../../../graphql/mutations/area/archivedById';
import AREA_ADD from '../../../graphql/mutations/area/areaAdd';
import { IRRIGATION_ADD, IRRIGATION_ADD_AREAS } from '../../../graphql/mutations/area/irrigationAdd';
import { RAIN_ADD, RAIN_ADD_AREAS } from '../../../graphql/mutations/area/rainAdd';
import REFRESH_AREA from '../../../graphql/queries/area/refreshArea';
import AREA_UPDATE from '../../../graphql/mutations/area/areaUpdate';
import { handleSuccess, handleError } from '../../../utils/functions';
import { QUERY_CALCULATE_BH } from '../../../graphql/queries/area/calculateBh';
import { QUERY_NDVI_REQUEST, QUERY_NDVI_REQUEST_LABEL } from '../../../graphql/queries/ndvi/ndviRequest';
import { QUERY_GET_CROPS } from '../../../graphql/queries/area/getCrops';
import { GET_AREAS_MAP } from '../../../graphql/queries/getAreasMap';

export async function requestAreaByUser({ payload }) {
    const { id, filter } = payload;
    let query = { id };
    if (filter) {
        query = {
            ...query,
            filter: {
                areaFilter: {
                    ...filter,
                },
            },
        };
    }

    try {
        const data = await client.request(GET_AREA, query);
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function queryRequestAreasMap({ payload }) {
    const { id } = payload;

    const query = { id };

    try {
        const data = await client.request(GET_AREAS_MAP, query);
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestByIdNdvi({ payload }) {
    try {
        const data = await client.request(QUERY_NDVI_REQUEST, { id: payload.id });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestByIdNdviLabel() {
    try {
        const data = await client.request(QUERY_NDVI_REQUEST_LABEL);
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

// Request's area by id
export async function requestAreaById({ payload }) {
    const { id } = payload;

    try {
        const data = await client.request(GET_AREA_ID, { id });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestAreaByIdBH({ payload }) {
    const { id } = payload;

    try {
        const data = await client.request(GET_AREA_ID_BH, { id });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestAreaByIdAverage({ payload }) {
    const { id } = payload;

    try {
        const data = await client.request(GET_AREA_ID_AVERAGE, { id });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}
// End

export async function requestRemoveById({ payload }) {
    const { id } = payload;

    try {
        const data = await client.request(REMOVE_AREA, { id });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestRefresh({ payload }) {
    const { id } = payload;

    try {
        const data = await client.request(REFRESH_AREA, { id });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestArchived({ payload }) {
    const { id } = payload;

    try {
        const data = await client.request(ARCHIVED_AREA, { id });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestIrrigationAdd({ payload }) {
    const {
        rain, irrigation, _areaId, _createdBy, _createdAt,
    } = payload;

    try {
        let data;
        if (rain !== null) {
            data = await client.request(RAIN_ADD, {
                rain, _areaId, _createdBy, _createdAt,
            });
        } else if (irrigation !== null) {
            data = await client.request(IRRIGATION_ADD, {
                irrigation, _areaId, _createdBy, _createdAt,
            });
        }

        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestIrrigationAddAreas({ payload }) {
    const {
        rain, irrigation, areas, _createdBy, _createdAt,
    } = payload;

    try {
        let data;
        if (rain !== null) {
            data = await client.request(RAIN_ADD_AREAS, {
                rain, areas, _createdBy, _createdAt,
            });
        } else if (irrigation !== null) {
            data = await client.request(IRRIGATION_ADD_AREAS, {
                irrigation, areas, _createdBy, _createdAt,
            });
        }

        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestAreaAdd({ payload }) {
    const { user, ...rest } = payload;
    try {
        const data = await client.request(AREA_ADD, rest);
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestAreaUpdate({ payload }) {
    const { user, ...rest } = payload;
    try {
        const data = await client.request(AREA_UPDATE, rest);
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestBhCalculateByArea({ payload }) {
    const { areaId, filters } = payload;

    try {
        const data = await client.request(QUERY_CALCULATE_BH, { id: areaId, filters });
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export async function requestAllCrops() {
    try {
        const data = await client.request(QUERY_GET_CROPS);
        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}
