export function consultantsUserRequest(userCreatorId) {
    return {
        type: '@consultant/CONSULTANTS_USER_REQUEST',
        payload: { userCreatorId },
    };
}

export function consultantsUserSuccess(data) {
    return {
        type: '@consultant/CONSULTANTS_USER_SUCCESS',
        payload: { data },
    };
}

export function consultantsUserError(message) {
    return {
        type: '@consultant/CONSULTANTS_USER_ERROR',
        payload: {
            message,
        },
    };
}
