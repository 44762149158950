import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import format from 'date-fns/format';
import moment from 'moment';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { ThemeProvider } from '@material-ui/core/styles';

import { provider } from '../../../styles/theme';
import * as Styles from './styles';

import {
    Area, Cultivate, Ground, Irrigation, Others,
} from '../../CrudArea';
import GroupFormButton from '../../GroupFormButton';

import ModalMap from '../ModalMap';
import * as AREA from '../../../utils/helpers/area';

import { areaAdd, areaUpdate } from '../../../store/modules/area/actions';
import { getCropId, getIrrigationTypeId, getSoilTypeId } from '../../../utils/functions/area';

const ModalArea = ({ setShowAlert, handleCloseModal }) => {
    const [showForm, setShowForm] = useState(false);
    const [dataMap, setDataMap] = useState([]);
    const dispatch = useDispatch();

    const modal = useSelector((state) => state.modal);
    const user = useSelector((state) => state.user);
    const { dataArea } = useSelector((state) => state.area);

    useEffect(() => {
        setShowAlert(showForm);
    }, [setShowAlert, showForm]);

    const validationSchemaFields = Yup.object().shape({

        // Others fields
        name: Yup.string().min(3, 'Nome deve ter pelo menos 3 caracteres').required('Campo Obrigatório'),
        farmId: Yup.string().required('Seleção obrigatória'),
        consultantId: Yup.string().required(''),
        customerId: Yup.string().required(''),

        // Fields culture
        culture: Yup.string(),
        cycleDays: Yup.string().nullable().when('culture', {
            is: 'annual',
            then: Yup.string().required('Campo obrigatório').test('interval', 'Somente números inteiros e maiores que zero são permitidos', (val) => parseInt(val) > 0),
        }),
        spacing: Yup.string().nullable().required('Campo obrigatório'),
        population: Yup.string().nullable().required('Campo obrigatório'),
        sowingDate: Yup.string().required('Campo obrigatório'),
        age: Yup.string().nullable().when('culture', {
            is: 'perennial',
            then: Yup.string().required('Campo obrigatório'),
        }),
        street: Yup.string().nullable().when('culture', {
            is: 'perennial',
            then: Yup.string().required('Campo obrigatório'),
        }),

        // Fields soil
        tetaUnit: Yup.string(),
        tetaCC: Yup.string().nullable().required('Campo obrigatório').test('interval', 'Somente números entre 0 e 1 são permitidos', (val) => parseFloat(val) >= 0 && parseFloat(val) <= 1),
        tetaPMP: Yup.string().nullable().required('Campo obrigatório').test('interval', 'Somente números entre 0 e 1 são permitidos', (val) => parseFloat(val) >= 0 && parseFloat(val) <= 1),
        soilHumidity: Yup.string().nullable().required('Campo obrigatório').test('interval', 'Somente números entre 5 e 100 são permitidos', (val) => parseFloat(val) >= 5 && parseFloat(val) <= 100),
        soilDepth: Yup.string().nullable().required('Campo obrigatório').test('interval', 'Somente números entre 0 e 500 são permitidos', (val) => parseFloat(val) >= 0 && parseFloat(val) <= 500),
        soilDensity: Yup.string().nullable().when('tetaUnit', {
            is: 'g',
            then: Yup.string().required('Campo obrigatório'),
        }),

        // Fields irrigation
        irrigationType: Yup.string().matches(/^[A-Za-z]\w+/),
        leaf: Yup.string().when('irrigationType', (irrigationType) => {
            if (irrigationType === 'Pivô central' || irrigationType === 'Gotejamento'
                || irrigationType === 'Pivô central (LEPA)' || irrigationType === 'Aspersão convencional') {
                return Yup.string().nullable().required('Campo obrigatório');
            }
            return Yup.string().nullable();
        }),
        efficiency: Yup.string().when('irrigationType', (irrigationType) => {
            if (irrigationType === 'Pivô central' || irrigationType === 'Gotejamento'
                || irrigationType === 'Pivô central (LEPA)' || irrigationType === 'Aspersão convencional') {
                return Yup.string().nullable().required('Campo obrigatório').test('interval', 'Somente números entre 0 e 100 são permitidos', (val) => parseFloat(val) >= 0 && parseFloat(val) <= 100);
            }
            return Yup.string().nullable();
        }),
        drippingSpacing: Yup.string().nullable().when('irrigationType', {
            is: 'Gotejamento',
            then: Yup.string().required('Campo obrigatório'),
        }),
        drippingFlow: Yup.string().nullable().when('irrigationType', {
            is: 'Gotejamento',
            then: Yup.string().required('Campo obrigatório'),
        }),
        pas: Yup.string().when('irrigationType', (irrigationType) => {
            if (irrigationType === 'Gotejamento' || irrigationType === 'Pivô central (LEPA)') {
                return Yup.string().nullable().required('Campo obrigatório').test('interval', 'Somente números entre 0 e 100 são permitidos', (val) => parseFloat(val) >= 0 && parseFloat(val) <= 100);
            }
            return Yup.string().nullable();
        }),
        pam: Yup.string().when('irrigationType', (irrigationType) => {
            if (irrigationType === 'Gotejamento' || irrigationType === 'Pivô central (LEPA)') {
                return Yup.string().nullable().required('Campo obrigatório').test('interval', 'Somente números entre 0 e 100 são permitidos', (val) => parseFloat(val) >= 0 && parseFloat(val) <= 100);
            }
            return Yup.string().nullable();
        }),
        kl: Yup.string().when('irrigationType', (irrigationType) => {
            if (irrigationType === 'Gotejamento' || irrigationType === 'Pivô central (LEPA)') {
                return Yup.string().nullable().required('Campo obrigatório').test('interval', 'Somente números entre 0 e 100 são permitidos', (val) => parseFloat(val) >= 0 && parseFloat(val) <= 100);
            }
            return Yup.string().nullable();
        }),
        ks: Yup.string().nullable().when('irrigationType', {
            is: 'Gotejamento',
            then: Yup.string().required('Campo obrigatório').test('interval', 'Somente números entre 0 e 100 são permitidos', (val) => parseFloat(val) >= 0 && parseFloat(val) <= 100),
        }),

        // Field variety
        variety: Yup.string().required('Campo Obrigatório'),
    });

    const validationSchemaById = Yup.object().shape({
        threshold_min: Yup
            .string()
            .nullable()
            .required('Campo obrigatório')
            .test('interval', 'Somente números entre 0 e 1 são permitidos', (val) => parseFloat(val) >= 0 && parseFloat(val) <= 1),
        threshold_max: Yup
            .string()
            .nullable()
            .required('Campo obrigatório')
            .test('interval', 'Somente números entre 0 e 1 são permitidos', (val) => parseFloat(val) >= 0 && parseFloat(val) <= 1),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const validationSchema = useMemo(() => (dataArea ? validationSchemaFields.concat(validationSchemaById) : validationSchemaFields), [dataArea]);

    const getCulture = (auxCulture) => (AREA.annual.find((culture) => culture.type === auxCulture) ? 'Anual' : 'Perene');

    return (
        <Styles.Wrapper>

            <ModalMap
                createArea
                valueSuccess={showForm}
                mapSuccess={(value) => setShowForm(value)}
                sendDataMap={(value) => setDataMap(value)}
            />
            <Styles.Separator />
            <ThemeProvider theme={provider}>
                <Formik
                    initialValues={{
                        boundary: '',
                        square: '',
                        center: '',
                        size: '',
                        name: modal.props.isEdit ? dataArea?.name : '',
                        farmId: modal.props.isEdit ? dataArea?.farm?.id : '',
                        consultantId: modal.props.isEdit ? dataArea?.farm?.consultant?.id ?? '' : '',
                        customerId: modal.props.isEdit ? dataArea?.farm?.customer?.id ?? '' : '',
                        culture: modal.props.isEdit ? getCulture(dataArea?.area_crops?.crop?.culture_name) : 'Anual',
                        cultureType: modal.props.isEdit ? dataArea?.area_crops?.crop?.culture_name ?? '' : '',
                        cycleDays: modal.props.isEdit ? dataArea?.area_crops?.cycle_days ?? null : null,
                        spacing: modal.props.isEdit ? dataArea?.area_crops?.spacing ?? null : null,
                        population: modal.props.isEdit ? dataArea?.area_crops?.population ?? null : null,
                        sowingDate: modal.props.isEdit ? dataArea?.area_crops?.sowing_date ? format(new Date(dataArea?.area_crops?.sowing_date), 'yyyy-MM-dd') : '' : '',
                        // Perene
                        age: modal.props.isEdit ? dataArea?.area_crops?.age ?? null : null,
                        street: modal.props.isEdit ? dataArea?.area_crops?.street_spacing ?? null : null,

                        soilType: modal.props.isEdit ? dataArea?.soil?.soil_type?.name ?? '' : '',
                        tetaUnit: modal.props.isEdit ? dataArea?.soil?.tetaUnit ?? '' : 'cm',
                        tetaCC: modal.props.isEdit ? dataArea?.soil?.tetaCC ?? null : null,
                        tetaPMP: modal.props.isEdit ? dataArea?.soil?.tetaPMP ?? null : null,
                        soilHumidity: modal.props.isEdit ? dataArea?.soil?.soil_humidity ?? null : null,
                        soilDepth: modal.props.isEdit ? dataArea?.soil?.soil_depth ?? null : null,
                        soilDensity: modal.props.isEdit ? dataArea?.soil?.soil_density ?? null : null,

                        irrigationSystem: modal.props.isEdit ? dataArea?.area_irrigation_system?.irrigation_system?.name ?? '' : 'Irrigado',
                        irrigationType: modal.props.isEdit ? dataArea?.area_irrigation_system?.irrigation_type?.name ?? '' : '',
                        leaf: modal.props.isEdit ? dataArea?.area_irrigation_system?.leaf ?? null : null,
                        efficiency: modal.props.isEdit ? dataArea?.area_irrigation_system?.efficiency * 100 ?? null : null,

                        threshold_min: modal.props.isEdit ? dataArea?.area_irrigation_system?.threshold_min ?? null : null,
                        threshold_max: modal.props.isEdit ? dataArea?.area_irrigation_system?.threshold_max ?? null : null,

                        drippingSpacing: modal.props.isEdit ? dataArea?.area_irrigation_system?.drippingSpacing ?? null : null,
                        drippingFlow: modal.props.isEdit ? dataArea?.area_irrigation_system?.drippingFlow ?? null : null,
                        pas: modal.props.isEdit ? dataArea?.pas ?? null : null,
                        pam: modal.props.isEdit ? dataArea?.pam ?? null : null,
                        kl: modal.props.isEdit ? dataArea?.kl ?? null : null,
                        ks: modal.props.isEdit ? dataArea?.ks * 100 ?? null : null,
                        variety: modal.props.isEdit ? dataArea?.area_crops?.variety ?? '' : '',
                        user: modal.props.isEdit ? user : '',
                        _customerId: modal.props.isEdit ? parseInt(user.data.id) : '',
                        id: modal.props.isEdit ? dataArea?.id : '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        if (values.cultureType === '') {
                            Swal.fire('Ops!', 'Selecione o cultivo', 'error');
                        } else if (values.soilType === '') {
                            Swal.fire('Ops!', 'Selecione o tipo de solo', 'error');
                        } else if (values.irrigationType === '') {
                            Swal.fire('Ops!', 'Selecione o tipo de irrigação', 'error');
                        } else {
                            values.boundary = dataMap?.boundary?.map((e) => ({
                                latitude: e.latitude.toString(),
                                longitude: e.longitude.toString(),
                            }));
                            values.square = dataMap?.square?.map((e) => ({
                                latitude: e.latitude.toString(),
                                longitude: e.longitude.toString(),
                            }));
                            values.center = {
                                latitude: dataMap?.center?.latitude.toString(),
                                longitude: dataMap?.center?.longitude.toString(),
                            };

                            values.size = dataMap?.size.toString();
                            values.farmId = parseInt(values.farmId);
                            values.consultantId = parseInt(values.consultantId);
                            values.customerId = parseInt(values.customerId);
                            const sowingDate = moment(values.sowingDate).toISOString();
                            let ks = null;

                            if (values.irrigationType === 'Gotejamento') ks = (parseFloat(values.ks) / 100).toString();

                            if (modal.props.isEdit) {
                                dispatch(areaUpdate(
                                    user,
                                    values.id,
                                    values.name,
                                    values.farmId,
                                    values.boundary,
                                    {
                                        soil_density: Number(values?.soilDensity),
                                        soil_depth: Number(values?.soilDepth),
                                        teta_cc: Number(values?.tetaCC),
                                        teta_pmp: Number(values?.tetaPMP),
                                        teta_unit: values?.tetaUnit,
                                        soil_humidity: Number(values.soilHumidity),
                                        soil_type_id: getSoilTypeId(values.soilType),

                                    },
                                    {
                                        active: true,
                                        max_rate: 1,
                                        leaf: Number(values?.leaf),
                                        threshold_min: Number(values?.threshold_min),
                                        threshold_max: Number(values?.threshold_max),
                                        dripping_flow: Number(values?.drippingFlow),
                                        dripping_spacing: Number(values?.drippingSpacing),
                                        efficiency: parseFloat(values?.efficiency) / 100,
                                        irrigation_system_id: values.irrigationSystem === 'Irrigado' || values.irrigationSystem === 'IRRIGATED' ? 1 : 2,
                                        irrigation_type_id: getIrrigationTypeId(values.irrigationType),
                                    },
                                    {
                                        sowing_date: sowingDate,
                                        age: Number(values?.age),
                                        cycle_days: Number(values?.cycleDays),
                                        population: Number(values?.population),
                                        spacing: Number(values?.spacing),
                                        street_spacing: Number(values?.street),
                                        spare: '',
                                        variety: values?.variety,
                                        crop_id: getCropId(values.culture, values.cultureType),
                                    },
                                    Number(values?.kl),
                                    Number(ks),
                                    Number(values?.pas),
                                    Number(values?.pam),
                                    Number(values.size),

                                ));
                            } else {
                                dispatch(areaAdd(
                                    user,
                                    values.name,
                                    values.farmId,
                                    {
                                        soil_density: Number(values?.soilDensity),
                                        soil_depth: Number(values?.soilDepth),
                                        teta_cc: Number(values?.tetaCC),
                                        teta_pmp: Number(values?.tetaPMP),
                                        teta_unit: values?.tetaUnit,
                                        soil_humidity: Number(values.soilHumidity),
                                        soil_type_id: getSoilTypeId(values.soilType),

                                    },
                                    {
                                        active: true,
                                        max_rate: 1,
                                        leaf: Number(values?.leaf),

                                        dripping_flow: Number(values?.drippingFlow),
                                        dripping_spacing: Number(values?.drippingSpacing),
                                        efficiency: parseFloat(values?.efficiency) / 100,
                                        irrigation_system_id: values.irrigationSystem === 'Irrigado' || values.irrigationSystem === 'IRRIGATED' ? 1 : 2,
                                        irrigation_type_id: getIrrigationTypeId(values.irrigationType),
                                    },
                                    {
                                        sowing_date: sowingDate,
                                        age: Number(values?.age),
                                        cycle_days: Number(values?.cycleDays),
                                        population: Number(values?.population),
                                        spacing: Number(values?.spacing),
                                        street_spacing: Number(values?.street),
                                        spare: '',
                                        variety: values?.variety,
                                        crop_id: getCropId(values.culture, values.cultureType),
                                    },
                                    Number(values?.kl),
                                    Number(ks),
                                    Number(values?.pas),
                                    Number(values?.pam),
                                    values.boundary,
                                    Number(values.size),
                                ));
                            }
                        }
                    }}
                >
                    {(props) => (
                        <>
                            {showForm
                                ? (
                                    <>
                                        <Field name="customArea" component={Area} />
                                        <Styles.Separator />
                                        <Styles.Grid>
                                            <Field name="customCultivate" component={Cultivate} />
                                            <Field name="customGround" component={Ground} />
                                            <Field name="customIrrigation" component={Irrigation} />
                                            <Field name="customOther" component={Others} />

                                        </Styles.Grid>
                                        <Styles.Footer>
                                            <GroupFormButton
                                                addButton
                                                label="Cancelar"
                                                handleAction={handleCloseModal}
                                                handleSave={props.handleSubmit}
                                            />
                                        </Styles.Footer>
                                    </>
                                ) : null}
                        </>
                    )}
                </Formik>
            </ThemeProvider>
        </Styles.Wrapper>
    );
};

export default ModalArea;
