export function userLoading() {
    return {
        type: '@user/USER_LOADING',
    };
}

export function userSuccess(user) {
    return {
        type: '@user/USER_SUCCESS',
        payload: { user },
    };
}

export function userClearState(user) {
    return {
        type: '@user/USER_CLEAR_STATE',
        payload: { user },
    };
}

export function userError(message) {
    return {
        type: '@user/USER_ERROR',
        payload: { message },
    };
}

// Get Customer
export function userRequest(id) {
    return {
        type: '@user/USER_REQUEST',
        payload: { id },
    };
}

// Update Customer
export function userUpdate(id, name, phone, active) {
    return {
        type: '@user/USER_UPDATE',
        payload: {
            id,
            name,
            phone,
            active,
        },
    };
}

// Reset Password
export function userRequestPasswordResetCode(email) {
    return {
        type: '@user/USER_REQUEST_PASSWORD_RESET_CODE',
        payload: { email },
    };
}

export function userRequestPasswordResetCodeSuccess() {
    return {
        type: '@user/USER_REQUEST_PASSWORD_RESET_CODE_SUCCESS',
    };
}

export function userValidatePasswordResetCode(email, code) {
    return {
        type: '@user/USER_VALIDATE_PASSWORD_RESET_CODE',
        payload: { email, code },
    };
}

export function userValidatePasswordResetCodeSuccess(integrity) {
    return {
        type: '@user/USER_VALIDATE_PASSWORD_RESET_CODE_SUCCESS',
        payload: { integrity },
    };
}

export function userUpdatePassword(password, confirm, integrity) {
    return {
        type: '@user/USER_UPDATE_PASSWORD',
        payload: { password, confirm, integrity },
    };
}

export function userUpdatePasswordSuccess() {
    return {
        type: '@user/USER_UPDATE_PASSWORD_SUCCESS',
    };
}

// Add Users
export function userAddManager(user) {
    return {
        type: '@user/USER_ADD_MANAGER',
        payload: { user },
    };
}

export function userAddConsultant(user) {
    return {
        type: '@user/USER_ADD_CONSULTANT',
        payload: { user },
    };
}

export function userAddCustomer(user) {
    return {
        type: '@user/USER_ADD_CUSTOMER',
        payload: { user },
    };
}

export function userAddFinished() {
    return { type: '@user/USER_ADD_FINISHED' };
}

// Remove Users
export function userRemove(id) {
    return {
        type: '@user/USER_REMOVE',
        payload: { id },
    };
}

export function userRemoveFinished() {
    return { type: '@user/USER_REMOVE_FINISHED' };
}
