import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { FaCloudRain } from 'react-icons/fa';

import moment from 'moment';
import withReactContent from 'sweetalert2-react-content';
import ButtonAction from '../../../../ButtonAction';
import EditFarmModal from '../AddFarmModal';

import * as Styles from './styles';
import theme from '../../../../../styles/theme';

import { farmDisableById } from '../../../../../store/modules/farm/actions';
import WaterChart from '../../../../Chart/WaterChart';
import Temperature from '../Temperature';
import IrrigationAddForm from '../../../../IrrigationAddForm';
import { areaIrrigationAdd } from '../../../../../store/modules/area/actions';

const CardFarm = ({ farm, compact, handleSelectFarmsToArea }) => {
    const html = `
    <p style="font-size:14px; line-height:22px;">Ao excluir a fazenda, todas as áreas também serão deletadas. </br> Deseja continuar?</p>

<div class="group-label">
    <input id="swal-input3"  hidden class="swal2-input" type="checkbox" style="width:35%;border:0; box-shadow:none;">
    <label for="swal-input3" class="label-modal" style="flex:1; font-size:14px; text-align:left;"></label>
    <label for="swal-input3" style="margin-left:40px; font-size:16px;">
        Remover Áreas
    </label>
</div>

<p id="message-error" style="display:none; color:red">Todas as opções devem ser selecionadas</p>
`;

    const ReactSwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const { data: user } = useSelector((state) => state.user);

    const [modalIsVisible, setModalIsVisible] = useState(false);

    const handleDeleteFarm = useCallback((farm) => {
        Swal.fire({
            title: `Deseja mesmo excluir a fazenda ${farm.name}?`,
            html,
            icon: 'warning',
            confirmButtonText: 'Sim',
            confirmButtonColor: theme.colors.danger,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            preConfirm: () => {
                const inputAreas = document.querySelector('#swal-input3').checked;
                const messageError = document.querySelector('#message-error');

                if (inputAreas) {
                    messageError.style.display = 'none';
                    return true;
                }

                messageError.style.display = 'block';
                return false;
            },
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(farmDisableById(farm.id, user));
            }
        });
    }, [dispatch, user, html]);

    const handleOnSubmit = (values, areaSelected) => {
        const date = moment(values.date).toISOString();
        const mm = parseFloat(values.mm);
        const areaId = parseInt(areaSelected.id);
        const userId = parseInt(user.id);

        dispatch(areaIrrigationAdd(null, mm, areaId, userId, date));
        ReactSwal.clickConfirm();
    };

    const handleOnCancel = () => ReactSwal.clickCancel();

    // Capturo os dados da area clicada no grafico
    const handleSelectAreaByFarm = (areaByFarm) => {
        const filterArea = farm.areas.filter((area) => area.id === areaByFarm?.id)[0];
        const type = 'Irrigação';

        if (filterArea) {
            ReactSwal.fire({
                title: <p style={{ padding: '1rem', paddingBottom: '0', fontWeight: 'bold' }}>{`Dados de ${type}`}</p>,
                html: <IrrigationAddForm type={type} handleOnSubmit={(values) => handleOnSubmit(values, filterArea)} handleOnCancel={handleOnCancel} />,
                width: 360,
                allowOutsideClick: false,
                showCloseButton: false,
                showCancelButton: false,
                showConfirmButton: false,
                reverseButtons: true,
            });
        }
    };

    return (
        <Styles.Container>
            <EditFarmModal isVisible={modalIsVisible} setIsVisible={setModalIsVisible} farm={farm} />
            <Styles.Title>{farm.name}</Styles.Title>
            <Styles.Row>
                <Temperature temperature={farm.areas[1]?.forecast[0]?.max_temp} />

                {farm?.customer
                    ? (
                        <Styles.Group>
                            <Styles.Label>Produtor</Styles.Label>
                            <Styles.Value>{farm.customer.name}</Styles.Value>
                        </Styles.Group>
                    ) : null}

                {farm?.city
                    ? (
                        <Styles.Group>
                            <Styles.Label>Cidade</Styles.Label>
                            <Styles.Value>{`${farm.city.name} - ${farm.city.state.uf}`}</Styles.Value>
                        </Styles.Group>
                    ) : null}

            </Styles.Row>

            {!compact && <WaterChart data={farm.areas.filter((area) => area.active)} handleSelectAreaByFarm={handleSelectAreaByFarm} />}

            {user.role.name !== 'Cliente'
                && (
                    <Styles.ContainerFooter>
                        <Styles.Button onClick={() => handleSelectFarmsToArea(farm.areas)}>
                            <FaCloudRain size={24} color="white" />
                        </Styles.Button>
                        <Styles.Footer>
                            <ButtonAction iconName="create" onClick={() => setModalIsVisible((value) => !value)} />
                            <ButtonAction iconName="delete" onClick={() => handleDeleteFarm(farm)} />
                        </Styles.Footer>
                    </Styles.ContainerFooter>
                )}
        </Styles.Container>
    );
};

export default CardFarm;
