import _ from 'lodash';
import moment from 'moment';
import * as AREA from '../../helpers/area';
import { lastDays } from '../../helpers/dateUtils';

export const COLOR_INFO = '#444';
export const COLOR_EXTRA = '#62EDFF';
export const COLOR_IDEAL = '#55F15C';
export const COLOR_ATENCAO = '#F7CA18';
export const COLOR_DEFICIT = '#F44336';

const formatTempAverage = ({ tempAverage, _createdAt }) => ({
    tempAverage: parseFloat(tempAverage),
    date: moment.utc(_createdAt).format('DD/MM'),
});

const formatCadRoot = (bh, sowingDate) => {
    let blue;
    let white;
    let yellow;

    const date = moment.utc(sowingDate);
    date.add(bh?.sowingdays, 'days');

    if (bh?.ATD != null) {
        blue = bh?.AFD;
        white = bh?.cadRoot - bh?.ATD;
        yellow = bh?.ADD;
    } else {
        blue = parseFloat(bh?.AFDf);
        yellow = (parseFloat(bh?.cadRoot) - parseFloat(bh?.AFD));
        white = Math.abs((parseFloat(bh?.AFD) - parseFloat(bh?.AFDf)));
    }

    const cadRoot = parseFloat(bh?.cadRoot);

    let percentageBlue = Math.floor((blue * parseFloat(100)) / cadRoot);
    percentageBlue = percentageBlue < 15 ? 15 : percentageBlue;

    const percentageYellow = Math.floor((yellow * parseFloat(100)) / cadRoot);
    let percentageWhite = Math.floor((white * parseFloat(100)) / cadRoot);
    const total = percentageBlue + percentageYellow + percentageWhite;

    if (total < 100 || total > 100) {
        percentageWhite += (100 - total);
    }

    return {
        blue: !Number.isNaN(blue) ? blue : 0,
        yellow: !Number.isNaN(yellow) ? yellow : 0,
        white: !Number.isNaN(white) ? white : 0,
        percentageBlue: !Number.isNaN(percentageBlue) ? percentageBlue : 20,
        percentageYellow: !Number.isNaN(percentageYellow) ? percentageYellow : 20,
        percentageWhite: !Number.isNaN(percentageWhite) ? percentageWhite : 60,
        cadRoot: !Number.isNaN(cadRoot) ? cadRoot : 0,
        date: date.format('DD/MM').toString(),
    };
};

const formattedBh = (bh, sowingDate) => {
    const date = moment.utc(sowingDate);
    date.add(bh?.sowingdays, 'days');

    return {
        ...bh,
        date: date.format('DD/MM').toString(),
    };
};

const formatIrrigation = ({ irrigation, rain, date }) => {
    const dateFormat = moment.utc(date);
    return {
        irrigation,
        rain,
        date: dateFormat.format('DD/MM').toString(),
    };
};

export const getLastsCadRoot = (bhs, sowingDate, limit = 5) => {
    const lastDates = lastDays(limit);
    const date = moment.utc(sowingDate);

    if (limit === 1) {
        const yesterday = moment().endOf('days').subtract(1, 'days');
        const formattedCadRoot = [];
        formattedCadRoot.push(formatCadRoot(bhs[0], yesterday));
        return formattedCadRoot;
    }

    if (!bhs?.length) {
        return {
            blue: 0,
            yellow: 0,
            white: 0,
            percentageBlue: (25),
            percentageYellow: (25),
            percentageWhite: (25),
            cadRoot: 0,
            date: date.format('DD/MM').toString(),
        };
    }

    const formattedCadRootList = _.map(bhs || [], (item) => formatCadRoot(item, sowingDate));

    const finalList = lastDates.map((date) => {
        const findDate = formattedCadRootList.find((x) => x.date === date);

        if (findDate) {
            return findDate;
        }
        return {
            blue: 0,
            yellow: 0,
            white: 0,
            percentageBlue: (20),
            percentageYellow: (20),
            percentageWhite: (60),
            cadRoot: 0,
            date,
        };
    });

    return finalList;
};

export const getMetCondition = ({
    etc, irrigation, effectiveRain, sowingdays,
}, sowingDate) => {
    const date = moment.utc(sowingDate);
    date.add(sowingdays || 0, 'days');
    return {
        etc: etc || 0.0,
        irrigation: irrigation || 0.0,
        effectiveRain: effectiveRain || 0.0,
        date: date.format('DD/MM'),
    };
};

export const getLastBhMetConditions = (bhs, sowingDate, limit = 30) => {
    const lastDates = lastDays(limit);
    const formattedBhList = (bhs || []).filter((item) => item?.sowingdays).map((item) => getMetCondition(item, sowingDate));

    const finalList = lastDates.map((date) => {
        const findDate = formattedBhList.find((x) => x.date === date);
        if (findDate) {
            return findDate;
        }
        return {
            etc: 0.0,
            irrigation: 0.0,
            effectiveRain: 0.0,
            date,
        };
    });
    return finalList;
};

export const getLastTempAverages = (data, limit = 30) => {
    const lastDates = lastDays(limit);
    const formattedDataList = _.map(data || [], (item) => formatTempAverage(item));

    const finalList = lastDates.map((date) => {
        const findDate = formattedDataList.find((x) => x.date === date);
        if (findDate) {
            return findDate;
        }
        return {
            tempAverage: 0.0,
            date,
        };
    });

    return finalList;
};

export const getLastBh = (bhs, sowingDate) => {
    const lastDates = lastDays(1);
    const formattedDataList = _.map(bhs || [], (item) => formattedBh(item, sowingDate));
    const finalList = lastDates.map((date) => {
        const findDate = formattedDataList.find((x) => x.date === date);
        if (findDate) {
            return findDate;
        }
        return undefined;
    });
    return finalList && finalList.length > 0 ? finalList[0] : undefined;
};

export const getLastIrrigations = (irrigations) => {
    const lastDates = lastDays(30);
    const formattedIrrigation = irrigations?.map((item) => formatIrrigation(item));
    const finalList = lastDates.map((date) => {
        const findDate = formattedIrrigation?.find((x) => x.date === date);
        if (findDate) {
            return findDate;
        }
        return {
            irrigation: 0,
            rain: 0,
            date,
        };
    });
    return finalList;
};

export const getLastComputedIrrigationToLabel = (bh) => {
    if ((bh?.length >= 2 && bh[1]?.complementarWater) || bh != null) {
        const last = bh.length ? bh[1]?.complementarWater : bh;
        return {
            irrigation: last.toFixed(3),
            _createdAt: new Date(last?._createdAt).toLocaleDateString('pt-BR', { dateStyle: 'short' }),
        };
    }

    return {
        irrigation: 0,
        _createdAt: new Date(),
    };
};

export const getCultureIcon = (type) => {
    const annual = AREA.cultures.annual.find(
        (culture) => culture.type === type,
    );
    const perennial = AREA.cultures.perennial.find(
        (culture) => culture.type === type,
    );

    return annual || perennial;
};

export const getSoilIcon = (type) => AREA.soil.find((soil) => soil.type === type);

export const getTimeToIrrigate = (bh) => bh[1]?.irrigation_time;

export const getCropId = (tab, type) => {
    let find;
    if (tab === 'Anual') {
        find = AREA.annual.find((culture) => culture.type === type);
    } else {
        find = AREA.perennial.find((culture) => culture.type === type);
    }

    return Number(find?.id);
};

export const getIrrigationTypeId = (value) => {
    const find = AREA.irrigationType.find((irrigation) => irrigation.type === value);

    if (find) return Number(find?.id);

    return null;
};

export const getSoilTypeId = (value) => {
    const find = AREA.soil.find((soil) => soil.type === value);
    return Number(find?.id);
};

export const irrigationCondition = (atd = null, cadRoot) => {
    const ninetyPercent = cadRoot * (90 / 100);
    const seventyFivePercent = cadRoot * (75 / 100);
    const fiftyPercent = cadRoot * (50 / 100);

    if (atd == null || atd === undefined) {
        return {
            color: COLOR_INFO,
            label: 'Sem Info',
            type: 'info',
            percent: 0,
        };
    }

    if (atd > ninetyPercent) {
        return {
            color: COLOR_EXTRA,
            label: 'Extra',
            type: 'extra',
            percent: 100,
        };
    }

    if (atd >= seventyFivePercent && atd <= ninetyPercent) {
        return {
            color: COLOR_IDEAL,
            label: 'Ideal',
            type: 'ideal',
            percent: 75,
        };
    }

    if (atd >= fiftyPercent && atd <= seventyFivePercent) {
        return {
            color: COLOR_ATENCAO,
            label: 'Atenção',
            type: 'atencao',
            percent: 50,
        };
    }

    if (atd < fiftyPercent) {
        return {
            color: COLOR_DEFICIT,
            label: 'Déficit',
            type: 'deficit',
            percent: 25,
        };
    }

    return {
        color: COLOR_INFO,
        label: 'Sem Info',
        type: 'info',
        percent: 0,
    };
};
