import React, { useState } from 'react';
import moment from 'moment';
import * as Styles from './styles';

const NDVI = ({ NDVIS = null, handleClickImage, onclose }) => {
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(!show);

    if (NDVIS == null || !NDVIS.length) {
        return <h2>Sem resultado</h2>;
    }

    const formateDate = (date) => {
        const result = moment(date).format('DD/MM/YYYY');
        return result;
    };

    const handleClose = () => {
        handleShow();
        onclose();
    };

    return (
        <Styles.Wrapper>
            <button type="button" onClick={handleShow}>
                NDVI
            </button>

            <Styles.Container show={show}>
                {NDVIS.map((item, index) => (
                    <Styles.Area
                        key={index}
                        onClick={() => {
                            handleClickImage(item);
                            handleShow();
                        }}
                    >
                        <Styles.Image src={`${item.url}`} />
                        <Styles.Date>{formateDate(item.date)}</Styles.Date>
                    </Styles.Area>
                ))}
                <span className="closeNDVI" onClick={handleClose}>Remover NDVI</span>
            </Styles.Container>
        </Styles.Wrapper>
    );
};

export default NDVI;
