import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Styles from './styles';

import floatPivotFooter from '../../assets/svgs/float_pivot_footer.svg';
import iconRain from '../../assets/svgs/icon_rain_pivot.svg';
import iconIrrigation from '../../assets/svgs/icon_irrigation_pivot.svg';
import iconArea from '../../assets/svgs/icon_area.svg';

import IconSvg from '../IconSvg';
import Modal from './Modal';

import { showModal } from '../../store/modules/modal/actions';

const ButtonFloatModal = () => {
    const dispatch = useDispatch();
    const { data: user } = useSelector((state) => state.user);

    const [openMenu, setOpenMenu] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalType, setModalType] = useState(null);

    const memoMenuClassName = useMemo(() => {
        if (openMenu === '') return '';
        return openMenu ? 'open' : 'close';
    }, [openMenu]);

    const handleToggleClass = () => setOpenMenu((value) => !value);
    const handleToggleModalIsOpen = (type) => {
        setModalType(type);
        setModalIsOpen((value) => !value);
    };

    const handleModalArea = () => dispatch(showModal('ModalArea', { isOpen: true }));

    return (
        <>
            {modalIsOpen && <Modal isVisible={modalIsOpen} setIsVisible={setModalIsOpen} type={modalType} />}

            <Styles.Container onClick={handleToggleClass}>

                <Styles.ChildButtonsContainer className={memoMenuClassName}>

                    <Styles.ChildButtonsContent>

                        <Styles.ChildButton onClick={() => handleToggleModalIsOpen('Chuva')}>
                            <div id="button-2">
                                <IconSvg svgPath={iconRain} />
                            </div>
                            <label htmlFor="button-2">Chuva</label>
                        </Styles.ChildButton>

                        <Styles.ChildButton onClick={() => handleToggleModalIsOpen('Irrigação')}>
                            <div id="button-1">
                                <IconSvg svgPath={iconIrrigation} />
                            </div>
                            <label htmlFor="button-1">Irrigação</label>
                        </Styles.ChildButton>

                        {user.role.name !== 'Cliente'
                        && (
                            <Styles.ChildButton onClick={handleModalArea}>
                                <div id="button-0">
                                    <IconSvg svgPath={iconArea} />
                                </div>
                                <label htmlFor="button-1">Área</label>
                            </Styles.ChildButton>
                        ) }

                    </Styles.ChildButtonsContent>

                    <Styles.ChildFooter>
                        <IconSvg svgPath={floatPivotFooter} />
                    </Styles.ChildFooter>

                </Styles.ChildButtonsContainer>

                <Styles.Button className={memoMenuClassName} />

            </Styles.Container>
        </>
    );
};

export default ButtonFloatModal;
