import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        background:${theme.colors.white};
        position:absolute;
        z-index:900;
        max-width:24rem;
        right:0rem;
        border-radius:3rem 0rem 0rem 3rem;
        height:auto;
        top:5rem;
        padding:1rem;
        box-shadow:-5px -3px 10px rgb(0,0,0, 0.3);
        display:flex;
        flex-direction:column;
        text-align:center;
        transition:height 1s;
    `}
`;

export const List = styled.ul`
    ${() => css`
        list-style-type:none;
        display:flex;
        flex-direction:column;
        height:100%;
        justify-content:space-between;
    `}
`;

export const Group = styled.li`
    ${({ theme, first, expanded }) => css`
        display:flex;
        flex-direction:${first ? 'column' : 'row'};
        align-items:center;
        justify-content:flex-start;
        margin-top:${expanded ? '10px' : '0px'};
        margin-bottom:${expanded ? '10px' : '0px'};
        img {
            max-width:${first ? '80%' : '20%'};
        }

        span {
            margin-left:${first ? '0' : '.5rem'};
            display:inline-block;
            font-size:${theme.font.sizes.xxsmall};
            color:black;
        }
    `}
`;
