const RESET_PASSWORD = /* GraphQL */ `
query RESET_PASSWORD($email:String!){
    resetPassword(email:$email){
        error
        message
        code
    }
  }
`;

export default RESET_PASSWORD;
