import React from 'react';
import TableReport from '../../components/TableReport';
import Layout from '../../components/Layout';
import * as Styles from './styles';

const Reports = () => (
    <Layout fullScreen>
        <Styles.WrapperGraphs>
            <TableReport />
        </Styles.WrapperGraphs>
    </Layout>
);

export default Reports;
