import styled, { css } from 'styled-components';

export const Checkbox = styled.input`
   ${({ theme, size }) => css`
        position: relative;
        cursor: pointer;
        width: 16px;
        height: 16px;
        margin-right: ${theme.spacings.xsmall}; 

        &:before {
            content: "";
            display: block;
            position: absolute;
            width: ${size}px;
            height: ${size}px;
            top: 0;
            left: 0;
            background-color: ${theme.colors.white}; 
            border: 1px solid ${theme.colors.primary};
        }

        &:checked:before {
            content: "";
            display: block;
            position: absolute;
            width: ${size}px;
            height: ${size}px;
            top: 0;
            left: 0;
            background-color: ${theme.colors.white}; 
        }
        &:checked:after {
            content: "";
            display: block;
            width: ${size / 4}px;
            height: ${size / 2}px;
            border: solid ${theme.colors.primary};
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: ${(size / 4) - 1}px;
            left: ${(size / 2) - 2}px;
        }
    `}
`;
