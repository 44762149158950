import { FRAGMENT_GET_AREA } from '../area';

export const FRAGMENT_GET_FARMS = `
    id
    name
    city{
        id
        name
        state {
            id
            name
            uf
        }   
    }
    customer {
        id
        name
    }
    consultant {
        id
        name
    }
    partners {
        id
        name
        role {
            name
            id
        }
    }
    active
    areas {
      ${FRAGMENT_GET_AREA}
        forecast {
            max_temp
          }
        bh_yesterday {
            complementarWater:complementar_water
        }
    }
`;

export const FRAGMENT_GET_WEATHER_BY_FARM = `
    id,
    name,
    areas {
        id,
        name,
        forecast {
            precip_intensity
            precip_probabil
            is_day
            type
            max_temp
            label
          }

    }
`;
