import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import {
    format,
    intervalToDuration,
    addDays,
    getDaysInMonth,
} from 'date-fns';
import moment from 'moment-timezone';
import { HeaderPlot } from '../PlotTemplate/components/HeaderPlot';
import 'moment/locale/pt-br';
import { layoutBar, layoutLine } from './configLayout';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import * as Styles from './styles';

const TableReport = () => {
    const PlotlyComponent = createPlotlyComponent(Plotly);
    const { dataGraph } = useSelector((state) => state.area);
    const [widthGraph, setWidthGraph] = useState(600);
    const [heightGraph, setHeightGraph] = useState(600);

    const { width } = useWindowDimensions();

    const [valueStart, setValueStart] = useState('');
    const [valueEnd, setValueEnd] = useState('');
    const [arrayIntervalDates, setArrayIntervalDates] = useState([]);

    const [dataEtC, setDataEtC] = useState([]);
    const [sumEtC, setSumEtC] = useState(0);
    const [sumRain, setSumRain] = useState(0);
    const [sumIrrigation, setSumIrrigation] = useState(0);

    const [soilHumidity, setSoilHumidity] = useState([]);

    const [dataTemp, setDataTemp] = useState([]);

    const [dataIrrigation, setDataIrrigation] = useState([]);
    const [dataRain, setDataRain] = useState([]);
    const containerRef = useRef();

    useEffect(() => {
        if (containerRef.current) {
            setWidthGraph(containerRef?.current?.offsetWidth);
            const valueHeightContainer = containerRef.current?.offsetHeight - 75;
            setHeightGraph(valueHeightContainer / 2);
        }
    }, [width]);

    const formatedArrayIntervalDates = (start, end) => {
        const monthOfDays = getDaysInMonth(end);
        const duration = intervalToDuration({ start, end });
        const dates = [];

        if (duration.months > 0) {
            duration.days = monthOfDays * duration.months + duration.days;
        }

        if (duration.years > 0) {
            duration.days = (371 * duration.years) + duration.days;
        }

        for (let d = 0; d < duration.days; d++) {
            const date = addDays(start, d);
            dates.push(date);
        }
        return dates;
    };

    useEffect(() => {
        if (valueStart && valueEnd) {
            const arrayOfDates = formatedArrayIntervalDates(valueStart, valueEnd);
            if (arrayOfDates.length) {
                setArrayIntervalDates(arrayOfDates.map((date) => format(date, 'dd/MM/yyyy')));
            }
        }
    }, [valueStart, valueEnd]);

    useEffect(() => {
        if (arrayIntervalDates.length && dataGraph) {
            // Array com datas e valores
            const dataBhWithinInterval = arrayIntervalDates.map((item) => dataGraph.bh.filter((bh) => {
                // Formatar o date do Bh igual o date de intervalos
                const dateFormated = moment.tz(bh._date, 'YYYY-M-D', 'America/Sao_Paulo').format('DD/MM/YYYY');

                // Se um intervalo for igual ao valor de dateFormated
                if (dateFormated === item) {
                    return {
                        ...item,
                    };
                }
            })[0] ?? null);

            // Array com datas e valores
            if (dataGraph.weather.length) {
                const dataWeatherWithinInterval = arrayIntervalDates.map((item) => dataGraph.weather.filter((weather) => {
                    const dateFormated = moment.tz(weather.date, 'YYYY-M-D', 'America/Sao_Paulo').format('DD/MM/YYYY');
                    if (dateFormated === item) {
                        return {
                            ...item,
                        };
                    }
                })[0] ?? []);
                setDataTemp(dataWeatherWithinInterval);
            }

            setDataEtC(dataBhWithinInterval?.map((bh) => bh?.etc) ?? 0);
            setSoilHumidity(dataBhWithinInterval.map((bh) => (bh?.ATD / bh?.cadRoot) * 100) ?? 0);

            setDataIrrigation(dataBhWithinInterval.map((bh) => bh?.irrigation ?? 0));
            setDataRain(dataBhWithinInterval.map((bh) => bh?.effectiveRain ?? 0));

            const sumEtcAndRainAndIrrigationFormated = dataBhWithinInterval.map((item) => {
                if (!item) {
                    return {
                        etc: 0,
                        irrigation: 0,
                        effectiveRain: 0,
                    };
                }
                return {
                    ...item,
                };
            });

            setSumEtC(
                sumEtcAndRainAndIrrigationFormated
                    .reduce((acumulator, actual) => acumulator + actual?.etc, 0)
                    .toFixed(1),
            );
            setSumRain(
                sumEtcAndRainAndIrrigationFormated
                    .reduce((acumulator, actual) => acumulator + actual?.effectiveRain, 0)
                    .toFixed(1),
            );
            setSumIrrigation(
                sumEtcAndRainAndIrrigationFormated
                    .reduce((acumulator, actual) => acumulator + actual?.irrigation, 0)
                    .toFixed(1),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGraph, arrayIntervalDates]);

    return (
        <Styles.Container ref={containerRef}>
            <HeaderPlot
                changeStartValue={setValueStart}
                changeEndValue={setValueEnd}
            />

            <Styles.Wrapper>
                {dataGraph
                    && (
                        <PlotlyComponent
                            useResizeHandler
                            data={[
                                {
                                    x: arrayIntervalDates,
                                    y: dataEtC.map((item) => {
                                        if (item > 10) {
                                            return 10;
                                        }
                                        return item;
                                    }),
                                    type: 'scatter',
                                    mode: 'lines',
                                    marker: { color: 'green' },
                                    name: `Etc acumulado ${sumEtC}mm`,
                                    yaxis: 'y2',
                                },

                                {
                                    x: arrayIntervalDates,
                                    y: soilHumidity.map((item) => {
                                        if (item > 100) {
                                            return 100;
                                        }
                                        return item;
                                    }),
                                    type: 'scatter',
                                    mode: 'lines+markers',
                                    marker: { color: 'black' },
                                    name: 'Umidade do solo',
                                },

                                {
                                    x: arrayIntervalDates,
                                    y: dataTemp.length ? dataTemp.map((temp) => temp.temp_max) : arrayIntervalDates.map(() => 0),
                                    type: 'scatter',
                                    mode: 'lines+markers',
                                    marker: { color: 'red' },
                                    name: 'Tmax',
                                },

                                {
                                    x: arrayIntervalDates,
                                    y: dataTemp.length ? dataTemp.map((temp) => temp.temp_min) : arrayIntervalDates.map(() => 0),
                                    type: 'scatter',
                                    mode: 'lines+markers',
                                    name: 'Tmin',
                                    marker: { color: 'orange' },
                                },

                            ]}
                            layout={{
                                ...layoutLine,
                                font: { size: 9 },
                                width: widthGraph,
                                height: heightGraph,

                            }}
                            config={{ displaylogo: false }}
                        />
                    )}
            </Styles.Wrapper>

            <Styles.Wrapper>
                {dataGraph
                    && (
                        <PlotlyComponent
                            data={[
                                {
                                    x: arrayIntervalDates,
                                    y: dataIrrigation.map((item) => {
                                        if (item > 20) return 20;
                                        return item;
                                    }),
                                    type: 'bar',
                                    name: `Irrigação Acumulada - ${sumIrrigation}`,
                                    marker: {
                                        color: '#3095e3',
                                    },
                                },
                                {
                                    x: arrayIntervalDates,
                                    y: dataRain.map((item) => {
                                        if (item > 125) return 125;
                                        return item;
                                    }),

                                    type: 'bar',
                                    yaxis: 'y2',
                                    name: `Chuva Acumulada - ${sumRain}`,
                                    marker: {
                                        color: '#08016e',
                                    },
                                },

                            ]}
                            layout={{
                                ...layoutBar,
                                font: { size: 9 },
                                barmode: 'stack',
                                width: widthGraph,
                                height: heightGraph,
                            }}
                            config={{ displaylogo: false }}
                        />
                    )}
            </Styles.Wrapper>

        </Styles.Container>
    );
};

export default TableReport;
