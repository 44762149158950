import {
    all, takeLatest, call, put,
} from 'redux-saga/effects';
import Swal from 'sweetalert2';

import GET_LOGIN from '../../../graphql/queries/getLogin';
import client from '../../../graphql/client';
import history from '../../../services/history';
import { handleSuccess, handleError } from '../../../utils/functions';
import { userSuccess } from '../user/actions';

async function requestLogin({ payload }) {
    const { username, password } = payload;

    try {
        const data = await client.request(GET_LOGIN, { username, password });

        return handleSuccess(JSON.stringify(data, undefined, 2));
    } catch (err) {
        const error = JSON.stringify(err, undefined, 2);
        return handleError(error);
    }
}

export function* signIn(action) {
    const res = yield call(requestLogin, action);
    if (!res.ok) {
        Swal.fire({
            title: 'Ops!', text: `${res.response.errors[0].message}`, icon: 'error', allowEnterKey: false,
        });
    } else {
        const { userLogin } = res;
        yield put(userSuccess(userLogin));
        history.push('/');
    }
}

export default all([
    takeLatest('@auth/SIGN_IN_REQUEST', signIn),
]);
