import { FRAGMENT_AREA_BY_ID } from '../../fragments/area';

const REFRESH_AREA = /* GraphQL */ `

mutation REFRESH_AREA($id:ID!){
    areaRefresh(id:$id){
      ${FRAGMENT_AREA_BY_ID}
    }
  }
`;

export default REFRESH_AREA;
