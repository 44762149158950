import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { areaById } from '../../store/modules/area/actions';
import { farmsConsultantRequest } from '../../store/modules/farm/actions';
import TableBh from './components/TableBh';
import * as Styles from './styles';

const ReportBhTemplate = () => {
    const dispatch = useDispatch();
    const { data: user } = useSelector((state) => state.user);
    const { dataArea, loadingArea } = useSelector((state) => state.area);
    const { data: farms, loading } = useSelector((state) => state.farm);

    const [areasFarmSelected, setAreasFarmSelected] = useState([]);

    useEffect(() => {
        dispatch(farmsConsultantRequest(user.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (farms.length && farms[0]?.areas.length) {
            setAreasFarmSelected(farms[0].areas);
            const firstArea = farms[0]?.areas[0];
            dispatch(areaById(firstArea.id));
        }
    }, [farms, dispatch]);

    useEffect(() => {
        if (areasFarmSelected.length) {
            dispatch(areaById(areasFarmSelected[0].id));
        }

        dispatch({
            type: '@area/CLEAR_DATA_AREA',
        });
    }, [areasFarmSelected, dispatch]);

    const handleSelectAreaByFarm = (evt) => {
        const farmId = evt.target.value;
        const farmSelected = farms.filter((farm) => farm.id === farmId)[0];

        if (!farmSelected) return;

        setAreasFarmSelected(farmSelected.areas);
    };

    const handleSelectArea = (evt) => {
        const areaId = evt.target.value;
        dispatch(areaById(areaId));
    };

    return (
        <Styles.Container>
            <Styles.SelectContainer>
                <Styles.GroupSelect>
                    <label>Selecione uma fazenda:</label>
                    <select onChange={handleSelectAreaByFarm}>
                        {loading && <option>Carregando fazendas...</option>}
                        {farms.length && farms.map((farm) => <option key={farm.id} value={farm.id}>{farm.name}</option>)}
                    </select>
                </Styles.GroupSelect>

                <Styles.GroupSelect>
                    <label>Selecione uma área:</label>
                    <select onChange={handleSelectArea} disabled={!areasFarmSelected.length}>
                        {areasFarmSelected.length ? areasFarmSelected.map((area) => <option key={area.id} value={area.id}>{area.name}</option>) : <option>Selecione uma área</option>}
                    </select>
                </Styles.GroupSelect>
            </Styles.SelectContainer>
            {loadingArea && <Skeleton width="100%" height="800" />}
            {dataArea && !loadingArea && <TableBh />}
        </Styles.Container>
    );
};

export default ReportBhTemplate;
