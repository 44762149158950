import { gql } from 'graphql-request';

const REMOVE_AREA = gql`
    mutation Remove($id:ID!){
        areaRemove(id:$id){
            removed
        }
    }
`;

export default REMOVE_AREA;
