import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Polygon } from 'react-leaflet';

import * as Styles from './styles';
import * as StylesCard from '../../../CardArea/styles';
import 'leaflet/dist/leaflet.css';

import { getSoilIcon, getCultureIcon } from '../../../../utils/functions/area';

const CustomMarker = ({ data, handlePivot }) => {
    const [MARKER, setMARKER] = useState(null);
    const history = useHistory();

    const handleOpenArea = useCallback((id) => {
        history.replace(`/pivot/${id}`);
    }, [history]);

    const getParent = (element, className) => {
        let parent = element.parentNode;
        while (parent !== null) {
            if (parent?.className && parent?.classList?.contains(className)) return parent;
            parent = parent.parentNode;
        }
        return false;
    };

    const popupMouseOut = (event) => {
        MARKER._popup.onmouseout = null;
        const target = event.toElement || event.relatedTarget;
        if (!target) return true;
        if (getParent(target, 'leaflet-popup')) return true;
        if (target === MARKER._icon) return true;

        MARKER.closePopup();
        return true;
    };

    const handleOnMouseOverMarker = (event) => {
        const marker = event.target;
        setMARKER(event.target);
        marker._popup.options.autoPan = false;

        const target = event.originalEvent.fromElement || event.originalEvent.relatedTarget;
        if (!target) return true;
        const parent = getParent(target, 'leaflet-popup');

        if (parent === marker._popup._container) return true;
        marker.openPopup();
        return true;
    };

    const handleOnMouseOutMarker = (event) => {
        const target = event.originalEvent.toElement || event.originalEvent.relatedTarget;
        if (!target) return true;
        const parent = getParent(target, 'leaflet-popup');

        if (parent) {
            MARKER._popup._container.onmouseout = popupMouseOut;
            return true;
        }
        MARKER.closePopup();
        return true;
    };

    return (
        <Polygon
            onMouseOver={handleOnMouseOverMarker}
            onMouseOut={handleOnMouseOutMarker}
            onclick={handlePivot ? () => handleOpenArea(data.id) : null}
            key={data.id}
            positions={data.data.map((square) => [square.latitude, square.longitude])}
            color={data.color}
        >
            <Styles.CustomPopup
                closeButton={false}
            >
                <div onClick={handlePivot ? () => handleOpenArea(data.id) : null}>
                    <Styles.Header>
                        <Styles.IconsContainer>
                            <img src={getCultureIcon(data?.cultureType)?.icon} alt="icon" />
                            <img src={getSoilIcon(data?.soilType)?.icon} alt="icon" />
                        </Styles.IconsContainer>
                        <StylesCard.Title>{data.name}</StylesCard.Title>
                        <StylesCard.Value className="size">{data.sizeArea.toString().replace('.', ',')}</StylesCard.Value>
                    </Styles.Header>

                    <Styles.InfosContainer>
                        <StylesCard.Label>
                            {'Data de plantio: '}
                            <StylesCard.Value>{data.memoSowingDays}</StylesCard.Value>
                        </StylesCard.Label>
                        <StylesCard.Label>
                            {'Safra: '}
                            <StylesCard.Value>{!data.harvestYear.error && data.harvestYear.result}</StylesCard.Value>
                        </StylesCard.Label>
                        <StylesCard.Label>
                            {'Dias de ciclo total: '}
                            <StylesCard.Value>{data.cycleDays}</StylesCard.Value>
                        </StylesCard.Label>
                        <StylesCard.Label>
                            {'Dias desde a semeadura: '}
                            <StylesCard.Value>{data.sowingDays}</StylesCard.Value>
                        </StylesCard.Label>
                        <StylesCard.Label className="irrigation">
                            {'Lâmina a irrigar: '}
                            <StylesCard.Value>{data.memoIrrigation.toString().replace('.', ',')}</StylesCard.Value>
                        </StylesCard.Label>
                        <StylesCard.Label>
                            {'Cultura: '}
                            <StylesCard.Value>{data.cultureType}</StylesCard.Value>
                        </StylesCard.Label>
                    </Styles.InfosContainer>
                </div>
            </Styles.CustomPopup>
        </Polygon>
    );
};

export default CustomMarker;
