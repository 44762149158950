import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: .5rem;
    width:100%;

    @media only screen and (max-width:1400px){
        grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
    }

    @media only screen and (max-width:768px){
        grid-template-columns: repeat(auto-fit, minmax(38rem, 1fr));
    }
`;

export const Container = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;

    @media only screen and (max-width:1024px){
       max-height: auto;
    }

`;

export const Section = styled.div`
    ${({ theme }) => css`
        background:${theme.colors.green};
        border-radius:100px;
        width:fit-content;
        padding:${theme.spacings.xxsmall} ${theme.spacings.xsmall};
        margin-bottom:${theme.spacings.xsmall};
    `}
`;

export const WrapperIcon = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;

    img {
        width:13px;
        height:13px;
    }
`;

export const Title = styled.p`
    ${({ theme }) => css`
        text-transform:uppercase;
        color:${theme.colors.white};
        font-size:1.2rem;
    `}
`;

export const Content = styled.div`
    display:grid;
    grid-template-columns:repeat(auto-fill, minmax(12rem, 1fr));
    gap:1rem;
`;

export const Group = styled.div`
    ${() => css`
        display:flex;
        margin-bottom:.8rem;
        flex-direction:column;
        align-items:flex-start;
    `}
`;

export const Label = styled.span`
    ${({ theme }) => css`
        font-weight:bold;
        color:${theme.colors.green};
        font-size:${theme.font.sizes.xxsmall};
    `}
`;

export const Value = styled.span`
    ${({ theme }) => css`
        color:${theme.colors.gray};
        font-size:${theme.font.sizes.xxsmall};
    `}
`;

export const ImgPerfil = styled.img`
    ${({ theme }) => css`
        width: 70px;
        height: 70px;
        border-radius: 50%;
        object-fit: cover;
        box-shadow: ${theme.box.shadow};
        margin: ${theme.spacings.xxsmall} 0;
    `}

`;
