import React from 'react';
import * as Styles from '../../ContainerWeather/styles';
import CardWeatherSkeleton from './components/CardWeatherSkeleton';

function ContainerWeatherSkeleton() {
    return (
        <Styles.Container style={{ width: '200px' }}>
            <Styles.Content>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((w) => <CardWeatherSkeleton key={w} />)}
            </Styles.Content>
        </Styles.Container>
    );
}

export default ContainerWeatherSkeleton;
