import {
    all, takeLatest, call, put,
} from 'redux-saga/effects';
import { requestConsultantsUser } from './utils';
import { consultantsUserError, consultantsUserSuccess } from './actions';

export function* request(action) {
    const res = yield call(requestConsultantsUser, action);
    if (!res.ok) {
        yield put(consultantsUserError(res.response.errors[0].message));
    } else {
        const { consultantsFromCreator } = res;
        yield put(consultantsUserSuccess(consultantsFromCreator));
    }
}

export default all([
    takeLatest('@consultant/CONSULTANTS_USER_REQUEST', request),
]);
