import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display:flex;
        justify-content:space-between;
        align-items:center;
        padding:${theme.spacings.small} ${theme.spacings.medium};
    `}
`;

export const Logo = styled.span`
    ${({ theme }) => css`
        cursor:pointer;
        display:flex;
        font-size:${theme.font.sizes.xsmall};
        color:${theme.colors.white};
    `}
`;
