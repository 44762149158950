/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
import React from 'react';
import Error404 from '../Error404';

class ErrorBoundary extends React.Component {
    state = {
        hasError: false,
        errorMessage: '',
    }

    static getDerivedStateFromError(error) {
        // Atualiza o state para que a próxima renderização mostre a UI alternativa.
        return { hasError: true, errorMessage: error.message };
    }

    render() {
        if (this.state.hasError) {
        // Você pode renderizar qualquer UI alternativa
            return (
                <Error404 errorMessage="Tente novamente mais tarde." />
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
