import styled, { css } from 'styled-components';

export const Button = styled.button`
    ${({ theme }) => css`
        padding:${theme.spacings.xsmall};
        border-radius:${theme.border.radius};  
    `}
`;

export const Label = styled.span`
    ${({ theme }) => css`
        font-size:${theme.font.sizes.xxsmall};
    `}
`;
