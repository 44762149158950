import React from 'react';

import * as Styles from './styles';

const ButtonFloat = ({ ...rest }) => (
    <Styles.Button {...rest}>
        +
    </Styles.Button>
);

export default ButtonFloat;
