import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const unitAge = createNumberMask({
    prefix: '', allowDecimal: false, decimalLimit: '', suffix: ' meses', thousandsSeparatorSymbol: '',
});
const unitMeters = createNumberMask({
    prefix: '', allowDecimal: true, decimalLimit: '', suffix: ' m', thousandsSeparatorSymbol: '',
});
const unitPlants = createNumberMask({
    prefix: '', allowDecimal: true, decimalLimit: '', suffix: ' plantas/ha', thousandsSeparatorSymbol: '',
});
const unitDays = createNumberMask({
    prefix: '', allowDecimal: false, decimalLimit: '', suffix: ' dias', thousandsSeparatorSymbol: '',
});
const unitTetaCm = createNumberMask({
    prefix: '', allowDecimal: true, decimalLimit: '', suffix: ' cm³/cm³', thousandsSeparatorSymbol: '',
});
const unitTetaG = createNumberMask({
    prefix: '', allowDecimal: true, decimalLimit: '', suffix: ' g/g', thousandsSeparatorSymbol: '',
});
const unitCM = createNumberMask({
    prefix: '', allowDecimal: true, decimalLimit: '', suffix: ' cm', thousandsSeparatorSymbol: '',
});
const unitSoilDensity = createNumberMask({
    prefix: '', allowDecimal: true, decimalLimit: '', suffix: ' g/cm³', thousandsSeparatorSymbol: '',
});
const unitPercent = createNumberMask({
    prefix: '', allowDecimal: true, decimalLimit: '', suffix: ' %', thousandsSeparatorSymbol: '',
});
const unitMM = createNumberMask({
    prefix: '', allowDecimal: true, decimalLimit: '', suffix: ' mm', thousandsSeparatorSymbol: '',
});
const unitDrippingFlow = createNumberMask({
    prefix: '', allowDecimal: true, decimalLimit: '', suffix: ' l/h', thousandsSeparatorSymbol: '',
});

const unitThresold = createNumberMask({
    prefix: '', allowDecimal: true, decimalLimit: '', suffix: '', thousandsSeparatorSymbol: '',
});

export const maskCycleDays = (props) => {
    const { inputRef, ...others } = props;
    return (
        <MaskedInput
            {...others}
            ref={(ref) => {
                inputRef(ref ? (ref).inputElement : null);
            }}
            mask={unitDays}
        />
    );
};

export const maskSpacing = (props) => {
    const { inputRef, ...others } = props;
    return (
        <MaskedInput
            {...others}
            ref={(ref) => {
                inputRef(ref ? (ref).inputElement : null);
            }}
            mask={unitMeters}
        />
    );
};

export const maskPopulation = (props) => {
    const { inputRef, ...others } = props;
    return (
        <MaskedInput
            {...others}
            ref={(ref) => {
                inputRef(ref ? (ref).inputElement : null);
            }}
            mask={unitPlants}
        />
    );
};

export const maskAge = (props) => {
    const { inputRef, ...others } = props;
    return (
        <MaskedInput
            {...others}
            ref={(ref) => {
                inputRef(ref ? (ref).inputElement : null);
            }}
            mask={unitAge}
        />
    );
};

export const maskTetaWithCm = (props) => {
    const { inputRef, ...others } = props;
    return (
        <MaskedInput
            {...others}
            ref={(ref) => {
                inputRef(ref ? (ref).inputElement : null);
            }}
            mask={unitTetaCm}
        />
    );
};

export const maskWithThresold = (props) => {
    const { inputRef, ...others } = props;
    return (
        <MaskedInput
            {...others}
            ref={(ref) => {
                inputRef(ref ? (ref).inputElement : null);
            }}
            mask={unitThresold}
        />
    );
};

export const maskTetaWithG = (props) => {
    const { inputRef, ...others } = props;
    return (
        <MaskedInput
            {...others}
            ref={(ref) => {
                inputRef(ref ? (ref).inputElement : null);
            }}
            mask={unitTetaG}
        />
    );
};

export const maskPercent = (props) => {
    const { inputRef, ...others } = props;
    return (
        <MaskedInput
            {...others}
            ref={(ref) => {
                inputRef(ref ? (ref).inputElement : null);
            }}
            mask={unitPercent}
        />
    );
};

export const maskCm = (props) => {
    const { inputRef, ...others } = props;
    return (
        <MaskedInput
            {...others}
            ref={(ref) => {
                inputRef(ref ? (ref).inputElement : null);
            }}
            mask={unitCM}
        />
    );
};

export const maskSoilDensity = (props) => {
    const { inputRef, ...others } = props;
    return (
        <MaskedInput
            {...others}
            ref={(ref) => {
                inputRef(ref ? (ref).inputElement : null);
            }}
            mask={unitSoilDensity}
        />
    );
};

export const maskLeaf = (props) => {
    const { inputRef, ...others } = props;
    return (
        <MaskedInput
            {...others}
            ref={(ref) => {
                inputRef(ref ? (ref).inputElement : null);
            }}
            mask={unitMM}
        />
    );
};

export const maskDrippingFlow = (props) => {
    const { inputRef, ...others } = props;
    return (
        <MaskedInput
            {...others}
            ref={(ref) => {
                inputRef(ref ? (ref).inputElement : null);
            }}
            mask={unitDrippingFlow}
        />
    );
};

const dynamicPhoneMask = (value) => {
    const rawValue = value.replace(/\D/g, '');
    const cellPhoneMask = ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    const phoneMask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    if (rawValue.length > 10) {
        return cellPhoneMask;
    }
    return phoneMask;
};

export const MaskPhone = (props) => (
    <MaskedInput
        {...props}
        mask={dynamicPhoneMask}
    />
);
