import { FRAGMENT_GET_AREA } from '../fragments/area';

const GET_AREA = /* GraphQL */ `
query GET_ALL_AREA($id:ID, $filter:UserFilterInput){
    users(id:$id, filters:$filter){
        customers {
            name
            id
        }
        areas {
            ${FRAGMENT_GET_AREA}
        }
  }
}
`;

export default GET_AREA;
