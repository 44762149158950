import { gql } from 'graphql-request';

export const IRRIGATION_ADD = gql`
    mutation irrigationAdd($irrigation:Float, $_areaId:Int!, $_createdBy:Int!, $_createdAt:String!){
        irrigationAdd(irrigation:$irrigation, area_id:$_areaId, created_by:$_createdBy, date:$_createdAt){
            error,
            message
        }
    }
`;

export const IRRIGATION_ADD_AREAS = gql`
    mutation irrigationAdd($irrigation:Float, $areas:[Int], $_createdBy:Int!, $_createdAt:String!){
        irrigationAdd(irrigation:$irrigation, areas:$areas, created_by:$_createdBy, date:$_createdAt){
            error,
            message
        }
    }
`;
