import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FaSpinner } from 'react-icons/fa';
import * as Yup from 'yup';

import * as Styles from './styles';

import IconSvg from '../../../IconSvg';
import { MaskPhone } from '../../../../utils/helpers/mask';

import IconPerfil from '../../../../assets/svgs/icon_perfil.svg';
import IconPhone from '../../../../assets/svgs/icon_phone.svg';
import IconEmail from '../../../../assets/svgs/icon_email.svg';
import IconPassword from '../../../../assets/svgs/icon_password.svg';

import { userAddManager, userAddConsultant, userAddCustomer } from '../../../../store/modules/user/actions';

const ReactSwal = withReactContent(Swal);

export const handleOnClickAdd = async (user, dispatch) => {
    const CustomMaskedInput = ({
        iconPath, error, touched, ...rest
    }) => (
        <Styles.InputWrapper>
            <Styles.InputContainer>
                {iconPath && <IconSvg svgPath={iconPath} />}
                <Styles.InputContent>
                    <MaskPhone {...rest} />
                </Styles.InputContent>
            </Styles.InputContainer>
            {touched && error ? <Styles.InputErrors>{error}</Styles.InputErrors> : <Styles.InputErrors />}
        </Styles.InputWrapper>
    );

    const CustomInput = ({
        iconPath, error, touched, ...rest
    }) => (
        <Styles.InputWrapper>
            <Styles.InputContainer>
                {iconPath && <IconSvg svgPath={iconPath} />}
                <Styles.InputContent>
                    <input {...rest} />
                </Styles.InputContent>
            </Styles.InputContainer>
            {touched && error ? <Styles.InputErrors>{error}</Styles.InputErrors> : <Styles.InputErrors />}
        </Styles.InputWrapper>
    );

    const roles = { Administrador: 'Gerente', Gerente: 'Consultor', Consultor: 'Cliente' };
    const newUserRole = roles[user.role.name];

    const RegexWithoutSpecialCharacter = new RegExp(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ' ']+$/);

    const validationSchemaFields = Yup.object().shape({
        name: Yup.string().matches(RegexWithoutSpecialCharacter, 'Caracteres especiais não são permitidos').required('Campo Obrigatório'),
        phone: Yup.string().min(10, 'Complete o número').required('Campo Obrigatório'),
        email: Yup.string().email().required('Campo Obrigatório'),
        password: Yup.string().required('Campo Obrigatório'),
    });

    const FormComponent = () => {
        const [loading, setLoading] = useState(false);

        const handleOnSubmit = (values) => {
            setLoading(true);
            const {
                name, phone, email, password,
            } = values;
            const formattedPhone = phone.replaceAll('_', '');
            const newUser = {
                name, phone: formattedPhone, email, password, roleId: user.role.id, userCreatorId: user.id,
            };

            const addUser = {
                Administrador: () => dispatch(userAddManager({ ...newUser, roleId: 2 })),
                Gerente: () => dispatch(userAddConsultant({ ...newUser, roleId: 3 })),
                Consultor: () => dispatch(userAddCustomer({ ...newUser, roleId: 4 })),
            };

            addUser[user.role.name]();

            return ReactSwal.clickConfirm();
        };

        const onClickCancel = () => ReactSwal.clickCancel();

        return (
            <Styles.FormContainer>
                <Formik
                    initialValues={{
                        name: '',
                        phone: '',
                        email: '',
                        password: '',
                    }}
                    validationSchema={validationSchemaFields}
                    onSubmit={handleOnSubmit}
                >
                    {(form) => (
                        <Form>
                            <Field
                                type="text"
                                name="name"
                                as={CustomInput}
                                iconPath={IconPerfil}
                                placeholder="Nome"
                                autoComplete="off"
                                onChange={form.handleChange}
                                value={form.values.name}
                                error={form.errors.name}
                                touched={form.touched.name}
                            />
                            <Field
                                type="email"
                                name="email"
                                as={CustomInput}
                                iconPath={IconEmail}
                                placeholder="Email"
                                autoComplete="off"
                                onChange={form.handleChange}
                                value={form.values.email}
                                error={form.errors.email}
                                touched={form.touched.email}
                            />
                            <Field
                                type="text"
                                name="phone"
                                as={CustomMaskedInput}
                                iconPath={IconPhone}
                                placeholder="Telefone"
                                autoComplete="off"
                                onChange={(e) => {
                                    form.setFieldValue('phone', e.target.value.replace(/[^0-9,]+/g, ''));
                                }}
                                value={form.values.phone}
                                error={form.errors.phone}
                                touched={form.touched.phone}
                            />
                            <Field
                                type="text"
                                name="password"
                                as={CustomInput}
                                iconPath={IconPassword}
                                placeholder="Senha"
                                autoComplete="off"
                                onChange={form.handleChange}
                                value={form.values.password}
                                error={form.errors.password}
                                touched={form.touched.password}
                            />
                            <div className="buttons-container">
                                <button className="cancel" type="button" onClick={onClickCancel}>Cancelar</button>
                                {loading
                                    // eslint-disable-next-line jsx-a11y/control-has-associated-label
                                    ? <button className="confirm" type="submit" disabled><FaSpinner color="FFF" size={16} /></button>
                                    : <button className="confirm" type="submit">Adicionar</button>}
                            </div>
                        </Form>
                    )}
                </Formik>
            </Styles.FormContainer>
        );
    };

    ReactSwal.fire({
        title: <p>{`Adicionar ${newUserRole}`}</p>,
        html: <FormComponent />,
        padding: '4rem 2rem',
        width: '40vw',
        allowOutsideClick: false,
        showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
        allowEnterKey: false,
    });
};
