import React from 'react';
import Skeleton from 'react-loading-skeleton';
import * as Styles from '../../Modals/ModalCustomer/Farm/styles';

function CardFarmSkeleton() {
    return (
        <>
            <Styles.Container>
                {Array(3)
                    .fill()
                    .map((item, index) => (
                        <li key={index}>
                            <Skeleton height={177.33} />
                        </li>
                    ))}
            </Styles.Container>
        </>
    );
}

export default CardFarmSkeleton;
