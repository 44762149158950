import produce from 'immer';

const INITIAL_STATE = {
    states: [],
    cities: [],
    loading: false,
    message: '',
};

export default function states(state = INITIAL_STATE, action) {
    switch (action.type) {
    case '@state/START_REQUEST':
        return produce(state, (draft) => {
            draft.loading = true;
        });
    case '@state/END_REQUEST':
        return produce(state, (draft) => {
            draft.loading = false;
        });
    case '@state/GET_STATE_SUCCESS':
        return produce(state, (draft) => {
            draft.states = action.payload.data;
        });

    case '@state/GET_CITIES_SUCCESS':
        return produce(state, (draft) => {
            draft.cities = action.payload.data;
        });
    case '@state/STATE_ERROR':
        return produce(state, (draft) => {
            draft.loading = false;
            draft.message = action.payload.message;
        });

    default:
        return state;
    }
}
