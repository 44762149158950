import React from 'react';
import {
    VictoryStack,
    VictoryBar,
    VictoryLabel,
    VictoryChart,
    VictoryAxis,
} from 'victory';
import _ from 'lodash';
import * as Styles from './styles';

import { lastDays } from '../../utils/helpers/dateUtils';
import { LegendChart } from '../Chart';

const StackChart = ({
    bh,
    useTitle,
    height = 120,
    width = 100,
    widthBar = 130,
    padding = {
        top: -10, bottom: 0, left: 60, right: 50,
    },
    backgroundStyle = { width: '100%', height: 240, flex: 1 },
    labelPosition = 20,
    fontSize = 16,
    minStackHeight = 20,
}) => {
    const dates = lastDays(5);

    if (!bh?.length) {
        return (
            <Styles.ImageBackground notFound>
                <Styles.NotFound>
                    <h2>Não foi possível gerar o gráfico.</h2>
                </Styles.NotFound>
            </Styles.ImageBackground>
        );
    }

    const yellow = _.map(bh, (item) => ({
        x: item.date,
        y: item.percentageYellow < minStackHeight ? minStackHeight : item.percentageYellow,
        label: `${item.yellow.toFixed(2)}mm`.replace('.', ','),
    }));
    const blue = _.map(bh, (item) => ({
        x: item.date,
        y: item.percentageBlue < minStackHeight ? minStackHeight : item.percentageBlue,
        label: `${item.blue.toFixed(2)}mm`.replace('.', ','),
    }));

    const getWhiteHeight = ({ percentageYellow, percentageBlue }) => {
        if (percentageYellow <= minStackHeight && percentageBlue <= minStackHeight) return 100 - (minStackHeight * 2);
        if (percentageYellow < minStackHeight && percentageBlue > minStackHeight) return 100 - Math.abs(percentageBlue + minStackHeight);
        if (percentageYellow > minStackHeight && percentageBlue < minStackHeight) return 100 - Math.abs(percentageYellow + minStackHeight);
        return 100 - Math.abs(percentageYellow + percentageBlue);
    };

    const white = _.map(bh, (item) => ({
        x: item.date,
        y: getWhiteHeight(item),
        label: '',
    }));

    return (
        <Styles.Container>
            <Styles.ImageBackground>
                <VictoryChart
                    width={width}
                    height={height}
                    padding={padding}
                    style={{
                        ticks: { strokeWidth: 0 },
                        tickLabels: {
                            fill: '#FFFFFF',
                            fontFamily: 'inherit',
                            fontSize: 10,
                        },
                    }}
                >
                    <VictoryStack
                        style={{
                            parent: {
                                border: '5px solid #ccc',
                            },
                        }}
                    >
                        <VictoryBar
                            labelComponent={<VictoryLabel dy={labelPosition} />}
                            cornerRadius={{ bottom: 5 }}
                            style={{
                                data: {
                                    fill: '#E4AE66',
                                    width: widthBar,
                                },
                                labels: {
                                    fill: '#F5E36D',
                                    fontWeight: 'bold',
                                    fontSize,
                                },
                            }}
                            data={yellow}
                        />

                        <VictoryBar
                            labelComponent={<VictoryLabel dy={labelPosition} />}
                            style={{
                                data: {
                                    fill: '#99D2F3',
                                    width: widthBar,
                                },
                                labels: {
                                    fill: '#6395D6',
                                    fontWeight: 'bold',
                                    fontSize,
                                    textAlign: 'center',
                                },
                            }}
                            data={blue}
                        />

                        <VictoryBar
                            labelComponent={<VictoryLabel dy={labelPosition} />}
                            cornerRadius={{ top: 5 }}
                            style={{
                                data: { fill: '#F2F2F2', width: widthBar },
                                labels: { fontSize: 0 },
                            }}
                            data={white}
                        />
                    </VictoryStack>

                    <VictoryAxis
                        tickValues={bh.length === 1 ? null : dates}
                        style={{
                            axis: { stroke: 'none', strokeWidth: 0 },
                            tickLabels: {
                                fill: '#FFF',
                                fontSize: 18,
                                fontWeight: 'bold',
                            },
                        }}
                    />
                </VictoryChart>
            </Styles.ImageBackground>
            {useTitle ? (
                <Styles.WrapperLegend style={backgroundStyle}>
                    <LegendChart color="#F2F2F2" title="Água para completar a CC " />
                    <LegendChart color="#99D2F3" title="Água de fácil absorção" />
                    <LegendChart color="#E4AE66" title="Água de absorção dificultada" />
                </Styles.WrapperLegend>
            ) : null}
        </Styles.Container>
    );
};

export default StackChart;
