import React from 'react';
import * as Styles from './styles';
import IconSvg from '../IconSvg';

function InfoCard({
    title, description, iconPath, ...rest
}) {
    return (
        <Styles.Container {...rest}>
            <Styles.IconContainer>
                <IconSvg svgPath={iconPath} />
            </Styles.IconContainer>
            <Styles.Data>
                <Styles.Title>{title}</Styles.Title>
                <Styles.Description>{description}</Styles.Description>
            </Styles.Data>
        </Styles.Container>
    );
}

export default InfoCard;
