const GET_CITIES = /* GraphQL */ `
    query GET_CITIES($id:ID!){
        cities (state_id: $id) { 
            id
            name
        }
    }
`;

export default GET_CITIES;
