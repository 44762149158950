import Swal from 'sweetalert2';
import { userRemove } from '../../../../store/modules/user/actions';

export const handleOnClickDelete = (user, dispatch) => {
    const html = `
        <p style="font-size:14px; line-height:22px;">Ao excluir um ${user.role?.name}, todos os clientes, fazendas e áreas também serão removidas. </br> Deseja continuar?</p>
        <div class="group-label">
            <input id="swal-input1"  hidden class="swal2-input" type="checkbox" style="width:35%;border:0; box-shadow:none;">
            <label for="swal-input1" class="label-modal" style="flex:1; font-size:14px; text-align:left;"></label>
            <label for="swal-input1" style="margin-left:40px; font-size:16px;">
                Remover Usuários
            </label>
        </div>


        <div class="group-label">
        <input id="swal-input2"  hidden class="swal2-input" type="checkbox" style="width:35%;border:0; box-shadow:none;">
        <label for="swal-input2" class="label-modal" style="flex:1; font-size:14px; text-align:left;"></label>
        <label for="swal-input2" style="margin-left:40px; font-size:16px;">
            Remover Fazendas
        </label>
    </div>

    <div class="group-label">
        <input id="swal-input3"  hidden class="swal2-input" type="checkbox" style="width:35%;border:0; box-shadow:none;">
        <label for="swal-input3" class="label-modal" style="flex:1; font-size:14px; text-align:left;"></label>
        <label for="swal-input3" style="margin-left:40px; font-size:16px;">
            Remover Áreas
        </label>
    </div>

    <p id="message-error" style="display:none; color:red">Todas as opções devem ser selecionadas</p>
    `;

    Swal.fire({
        title: `Deseja mesmo excluir ${user.name}?`,
        html,
        width: '43em',
        text: user.role.name !== 'Cliente'
            ? `Ao excluir um ${user.role.name} todos os usuários abaixo dele também serão excluídos.`
            : '',
        icon: 'warning',
        confirmButtonText: 'Sim',
        confirmButtonColor: '#f1003b',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        preConfirm: () => {
            const inputUsers = document.querySelector('#swal-input1').checked;
            const inputFarms = document.querySelector('#swal-input2').checked;
            const inputAreas = document.querySelector('#swal-input3').checked;
            const messageError = document.querySelector('#message-error');

            if (inputUsers && inputFarms && inputAreas) {
                messageError.style.display = 'none';
                return true;
            }

            messageError.style.display = 'block';
            return false;
        },
    }).then((result) => {
        if (result.isConfirmed) {
            dispatch(userRemove(user.id));
        }
    });
};
