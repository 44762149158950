import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import StackChart from '../StackChart';

import { checkForHarvest } from '../../utils/helpers/tools';
import {
    getSoilIcon, getCultureIcon, getLastsCadRoot, irrigationCondition,
} from '../../utils/functions/area';

import * as Styles from './styles';
import { accessRole } from '../../utils/functions';
import ProgressBar from '../ProgressBar';

export default React.memo(({ onClick, data }) => {
    const {
        name, image_file, size, bh_yesterday,
    } = data;

    const { data: user } = useSelector((state) => state.user);

    const {
        sowing_date: sowingDate, sowing_days: sowingDays, cycle_days: cycleDays,
    } = data?.area_crops;

    const { soil_type: soilType } = data.soil;

    const bh = [data?.bh_yesterday] ?? [];
    const memoSize = useMemo(() => (`≅ ${(parseFloat(size) * 100).toFixed(2).replace('.', ',')} ha`), [size]);
    const memoSowingDays = useMemo(() => new Date(sowingDate).toLocaleDateString('pt-BR', { dateStyle: 'short' }), [sowingDate]);
    const memoIrrigation = useMemo(() => `${bh_yesterday?.complementarWater == null ? '0' : bh_yesterday?.complementarWater.toFixed(1).replace('.', ',')} mm`, [bh_yesterday]);
    const memoHarvestYear = useMemo(() => checkForHarvest(sowingDate), [sowingDate]);
    const memoTimeToIrrigate = useMemo(() => (bh_yesterday?.irrigation_time != null ? bh_yesterday?.irrigation_time : null), [bh_yesterday]);
    const memoPercentimeter = useMemo(() => (bh_yesterday?.percentimeter == null ? null : `${bh_yesterday?.percentimeter.toFixed(1).replace('.', ',')}%`), [bh_yesterday]);
    const getColor = (title) => {
        if (title === 'Déficit' || title === 'Sem Info') return 'white';
        return 'black';
    };
    return (
        <Styles.Container>
            <Styles.Area
                onClick={onClick}
                src={image_file}
                lazy="loading"
            />

            <Styles.PivotContainer>

                <Styles.InfosContainer>
                    <ProgressBar
                        style={{ marginTop: 5, marginBottom: 5 }}
                        percent={irrigationCondition(bh_yesterday?.ATD, bh_yesterday?.cadRoot).percent}
                        bgColor={irrigationCondition(bh_yesterday?.ATD, bh_yesterday?.cadRoot).color}
                    />
                    <Styles.Title>{name}</Styles.Title>
                    <Styles.Value className="size">{memoSize}</Styles.Value>
                    <Styles.Label>
                        {'Data de plantio: '}
                        <Styles.Value>{memoSowingDays}</Styles.Value>
                    </Styles.Label>
                    <Styles.Label>
                        {'Safra: '}
                        <Styles.Value>{!memoHarvestYear.error && memoHarvestYear.result}</Styles.Value>
                    </Styles.Label>
                    {accessRole(user?.role?.name)
                    && (
                        <Styles.Label>
                            {'Dias de ciclo total: '}
                            <Styles.Value>{cycleDays}</Styles.Value>
                        </Styles.Label>
                    )}
                    <Styles.Label>
                        {'Dias desde a semeadura: '}
                        <Styles.Value>{sowingDays}</Styles.Value>
                    </Styles.Label>
                    <Styles.Label className="irrigation">
                        {'Lâmina a irrigar: '}
                        <Styles.Value>{memoIrrigation}</Styles.Value>
                    </Styles.Label>
                    {memoTimeToIrrigate != null
                        && (
                            <Styles.Label className="irrigation">
                                {'Tempo de gotejo: '}
                                <Styles.Value>{memoTimeToIrrigate}</Styles.Value>
                            </Styles.Label>
                        )}
                    {memoPercentimeter && memoTimeToIrrigate == null
                        && (
                            <Styles.Label className="irrigation">
                                {'Percentímetro: '}
                                <Styles.Value>{`${memoPercentimeter}`}</Styles.Value>
                            </Styles.Label>
                        )}
                    <Styles.Label>
                        {'Cultura: '}
                        <Styles.Value>{data.area_crops?.crop?.culture_name}</Styles.Value>
                    </Styles.Label>

                </Styles.InfosContainer>

                <Styles.ChartContainer>
                    <Styles.IconsContainer>
                        <div className="column">
                            <Styles.Title
                                color={getColor(irrigationCondition(bh_yesterday?.ATD, bh_yesterday?.cadRoot).label)}
                                backgroundColor={irrigationCondition(bh_yesterday?.ATD, bh_yesterday?.cadRoot).color}
                            >
                                {irrigationCondition(bh_yesterday?.ATD, bh_yesterday?.cadRoot).label}

                            </Styles.Title>
                            <Styles.Type src={getCultureIcon(data.area_crops?.crop?.culture_name)?.icon} />
                            <Styles.Type src={getSoilIcon(soilType?.name).icon} />
                        </div>
                    </Styles.IconsContainer>

                    <StackChart
                        bh={getLastsCadRoot(bh, sowingDate, 1)}
                        height={180}
                        width={200}
                        padding={{
                            top: 25, bottom: 0, left: 50, right: 50,
                        }}
                        backgroundStyle={{ width: '100%', height: 150, flex: 1 }}
                        fontSize={20}
                        labelPosition={25}
                    />

                </Styles.ChartContainer>
            </Styles.PivotContainer>
        </Styles.Container>
    );
});
