import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${() => css`
        height:98vh;
    `}
`;

export const Wrapper = styled.div`
    ${() => css`
        width:100%;

        * {
            cursor: crosshair !important
        }
    `}
`;
