import React, { useEffect, useState } from 'react';

import CardArea from '../../../CardArea';
import IconSvg from '../../../IconSvg';

import IconCheck from '../../../../assets/svgs/icon_check.svg';

import * as Styles from '../../Modal/styles';

export default ({ area, setAreasList, farmAreas }) => {
    const [isSelected, setIsSelected] = useState(false);

    const handleToggleIsSelected = () => {
        if (isSelected) {
            setAreasList((value) => value.filter((areaId) => areaId !== area.id));
        } else {
            setAreasList((value) => [...value, area.id]);
        }
        setIsSelected((value) => !value);
    };

    useEffect(() => {
        if (farmAreas && farmAreas?.length) {
            setAreasList(farmAreas.map((value) => value.id));
            setIsSelected(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!area.active) return null;

    return (
        <Styles.CardAreaWrapper
            onClick={handleToggleIsSelected}
            className={isSelected ? 'selected' : ''}
        >
            <Styles.CardAreaSelect>
                {isSelected ? <IconSvg svgPath={IconCheck} /> : null}
            </Styles.CardAreaSelect>
            <CardArea
                key={area.id}
                data={area}
            />
        </Styles.CardAreaWrapper>

    );
};
