import styled, { keyframes, css } from 'styled-components';

const slideOut = keyframes`
    from {
        margin-right: 0%;
        width: 10%; 
    }
    to {
        margin-right: -100%;
        width: -300%;
    }
`;

const fadeOut = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 0; }
`;

export const Modal = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 1050;

    & > #modal {
        border-radius: .4rem;
        max-height: 100vh;
        min-height: 100vh;
        width: 100%;

        background-color: white;
        overflow: hidden;

    }

    &.close-modal{
        -moz-animation: ${fadeOut} .8s;
        -webkit-animation: ${fadeOut} .8s;
        animation: ${fadeOut} .8s;

        & > #modal {
            -moz-animation: ${slideOut} .4s;
            -webkit-animation: ${slideOut} .4s;
            animation: ${slideOut} .4s;
        }
    }
`;

export const Container = styled.div`
   ${({ theme }) => css`
        background-color: ${theme.colors.white};
        width: 100%;
        max-height: 100vh;
        overflow: auto;
        overflow-x: hidden;
  `}
`;

export const Button = styled.button`
   ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 2.5rem;
        right:5rem;
        bottom: 0.9rem;
        background: ${theme.colors.gray};
        width: 6rem;
        height: 6rem;
        border-radius: 3rem;
        z-index:9;
  `}
`;
