import React, { forwardRef } from 'react';

import * as Styles from './styles';
import * as StylesInput from '../Input/styles';

const Select = ({ error, touched, ...rest }, ref) => (
    <Styles.Wrapper>
        <Styles.Container>
            <Styles.Select ref={ref} {...rest} />
        </Styles.Container>
        {touched && error ? <StylesInput.Errors>{error}</StylesInput.Errors> : <StylesInput.Errors />}
    </Styles.Wrapper>
);

export default forwardRef(Select);
