import styled, { css } from 'styled-components';
import Input from '../../../../Input';

export const Container = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-evenly;
`;

export const Form = styled.form`
        display:flex;
        flex-direction:column;
        align-items:flex-start;
`;

export const ImageProfile = styled.img`
        width:15rem;
        height:15rem;
        border-radius:15rem;
        box-shadow:2px 2px 5px rgba(0,0,0,0.5);
`;

export const CustomInput = styled(Input)`
    ${({ theme }) => css`
        color:${theme.colors.primary};
        span{
            margin-left: 1rem;
        }
    `}
`;
