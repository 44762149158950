const GET_STATES = /* GraphQL */ `
    query GET_STATES($id:ID!){
        states (country_id: $id) { 
            id
            name
            uf
        }
    }
`;

export default GET_STATES;
