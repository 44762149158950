import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardGraph from '../../../CardGraph';
import PeoplesTable from '../../../PeoplesTable';

import IconPeoples from '../../../../assets/svgs/icon_peoples.svg';

import * as Styles from '../Farm/AddFarmModal/styles';

import { handleOnClickAdd } from '../../../PeoplesTable/Cell/utils/addUser';

const Consultant = () => {
    const dispatch = useDispatch();

    const { data: user } = useSelector((state) => state.user);

    const [newUserRole, setNewUserRole] = useState(null);

    useEffect(() => {
        const roles = {
            Administrador: 'Gerente',
            Gerente: 'Consultor',
            Consultor: 'Cliente',
        };
        setNewUserRole(roles[user.role.name]);
    }, [user]);

    return (
        <CardGraph className="card" svgPath={IconPeoples}>
            <Styles.ButtonContainer>
                <Styles.Button
                    type="button"
                    onClick={() => handleOnClickAdd(user, dispatch)}
                >
                    {`Adicionar ${newUserRole}`}
                </Styles.Button>
            </Styles.ButtonContainer>
            <PeoplesTable className="consultant" />
        </CardGraph>
    );
};

export default Consultant;
