import { FRAGMENT_GET_FARMS } from '../fragments/farms';

const GET_FARMS_CONSULTANT = /* GraphQL */ `
    query GET_FARMS_CONSULTANT($consultantId:ID!){
        users(id:$consultantId){
            farms {
                ${FRAGMENT_GET_FARMS}
            }
        }
    }
`;

export default GET_FARMS_CONSULTANT;
