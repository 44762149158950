import React from 'react';
import * as Styles from './styles';

function NotFound({ text }) {
    return (
        <Styles.Container>
            <Styles.Icon src={require('../../assets/images/no-result-found.png')} />
            <Styles.Text>{text}</Styles.Text>
        </Styles.Container>
    );
}

export default NotFound;
