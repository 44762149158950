export const FRAGMENT_GET_USER = `
    name,
    email,
    id,
    active,
    role {
     id,
     name
    }
`;
