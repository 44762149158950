import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        margin-right:${theme.spacings.medium};
        display:flex;
        flex-direction:column;
        align-items:center;

        &:last-child{
            margin-right:0;
        }
    `}
`;

export const Date = styled.span`
    ${({ theme }) => css`
        margin-bottom:${theme.spacings.xsmall};
    `}
`;

export const Hour = styled.span`
    ${({ theme }) => css`
        margin-bottom:${theme.spacings.medium};
    `}
`;

export const Card = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
`;

export const Temperature = styled.p`
    ${({ theme }) => css`
        color:${theme.colors.black};
        font-weight:bold;
        font-size:${theme.font.sizes.small};
        margin:${theme.spacings.small} 0;
        margin-bottom:${theme.spacings.xsmall};
    `}
`;

export const Rain = styled.p`
    ${({ theme }) => css`
        font-size:${theme.font.sizes.xxsmall};
        color:${theme.colors.gray};
        text-align:center;
        font-weight:light;
    `}
`;
