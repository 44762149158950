import React, { useContext } from 'react';
import { FaUndo, FaMapMarkedAlt } from 'react-icons/fa';
import { ThemeContext } from 'styled-components';
import * as Styles from './styles';

const Control = ({ sizeArea, backClick, nextClick }) => {
    const { colors, font } = useContext(ThemeContext);
    return (
        <Styles.Container>
            <Styles.BackButton onClick={backClick}>
                <FaUndo size={font.sizes.xsmall} color={colors.gray} />
            </Styles.BackButton>
            <Styles.SizeArea>{sizeArea}</Styles.SizeArea>
            <Styles.NextButton onClick={nextClick}>
                <FaMapMarkedAlt size={font.sizes.xsmall} color={colors.gray} />
            </Styles.NextButton>
        </Styles.Container>
    );
};

export default Control;
