import React, {
    useEffect, useCallback, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as SwalAlert from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
import * as Styles from './styles';

import ButtonAction from '../../../components/ButtonAction';
import ContainerPlanting from '../../../components/ContainerPlanting';
import ContainerWeather from '../../../components/ContainerWeather';
import ContainerFarm from '../../../components/ContainerFarm';
import PagePivotSkeleton from '../../../components/Skeletons/PagePivotSkeleton';
import ButtonFloatPivot from '../../../components/ButtonFloatPivot';
import NDVI from '../../../components/NDVI';

import {
    getCultureIcon, getLastBhMetConditions, getLastIrrigations, getLastsCadRoot, getSoilIcon,
} from '../../../utils/functions/area';
import {
    areaById, areaRemoveById, areaArchivedById, areaRefresh,
} from '../../../store/modules/area/actions';
import { showModal } from '../../../store/modules/modal/actions';
import ModalMap from '../../../components/Modals/ModalMap';
import StackChart from '../../../components/StackChart';
import CombinedChart from '../../../components/Chart/Combined';
import ContainerGraphSkeleton from '../../../components/Skeletons/ContainerGraphSkeleton';
import Error404 from '../../../components/Error404';

const Pivot = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const {
        dataArea, loadingArea, loadingAreaBh, error,
    } = useSelector((state) => state.area);

    const { data: user } = useSelector((state) => state.user);

    const [dropdown, setDropdown] = useState(false);
    const [imageNDVI, setImageNDVI] = useState({
        show: false,
        coords: [],
        image: null,
    });

    const [closedNDVI, setClosedNDVI] = useState(false);

    useEffect(() => {
        document.title = !loadingArea && dataArea != null
            ? `SIM - Sistema Integrado de Manejo | ${dataArea.name}`
            : 'SIM - Sistema Integrado de Manejo';
        return () => { document.title = 'SIM - Sistema Integrado de Manejo'; };
    }, [loadingArea, dataArea]);

    useEffect(() => {
        if (id != null && id !== 'main') dispatch(areaById(id));
    }, []);

    const handleRefresh = useCallback(() => {
        SwalAlert.fire({
            title: 'Você tem certeza?',
            text: 'Refaremos todos os cálculos do Balanço Hidrico!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) dispatch(areaRefresh(id, user.id));
        });
    }, [id, dispatch, user.id]);

    const handleArchived = () => {
        if (!dataArea.active) {
            return SwalAlert.fire({
                title: 'Você tem certeza?',
                text: 'Após confirmar, sua área será desarquivada.',
                icon: 'warning',
                allowOutsideClick: false,
                showCloseButton: false,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                showConfirmButton: true,
                confirmButtonText: 'Sim',
                confirmButtonColor: '#f1003b',
                reverseButtons: true,
                preConfirm: () => dispatch(areaArchivedById(id, user, !dataArea.active)),
            });
        }
        return SwalAlert.fire({
            title: 'Você tem certeza?',
            text: 'Após arquivar, sua área será armazenada em outro espaço.',
            icon: 'warning',
            allowOutsideClick: false,
            showCloseButton: false,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            showConfirmButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: '#f1003b',
            reverseButtons: true,
            padding: 32,
            preConfirm: () => dispatch(areaArchivedById(id, user, dataArea.active)),
        });
    };

    const handleDelete = () => {
        SwalAlert.fire({
            title: 'Você tem certeza?',
            text: 'Após deletar a área, será necessário cria-la novamente.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: '#f1003b',
            cancelButtonText: 'Cancelar',
            preConfirm: () => dispatch(areaRemoveById(id, user)),
        });
    };

    const handleEdit = () => dispatch(showModal('ModalArea', { isOpen: true, isEdit: true }));
    const calculateLatLong = () => {
        const lat = Math.round(dataArea?.boundary?.center?.latitude * 10000) / 10000;
        const long = Math.round(dataArea?.boundary?.center?.longitude * 10000) / 10000;

        return { lat, long };
    };

    if ((dataArea == null || loadingArea) && !error) return <PagePivotSkeleton />;

    if (error && dataArea == null) return <Error404 message="Área não foi encontrada, tente novamente com outra área." />;

    const handleClickNDVI = (image) => {
        const array = [];

        dataArea.ndvi.bounds.forEach((item) => {
            array.push({
                latitude: item.miny,
                longitude: item.minx,
            }, {
                latitude: item.maxy,
                longitude: item.maxx,
            });
        });

        setImageNDVI({
            image,
            coords: array,
            show: true,
        });
    };

    const handleSetImageNDVI = (data) => {
        setImageNDVI({
            ...data,
            show: false,
        });
        setClosedNDVI(false);
    };

    const onCloseNDVI = () => setClosedNDVI(true);

    return (
        <>
            <Styles.CustomContainer>
                <Styles.Header>
                    <Styles.InfosAreaContainer>
                        <Styles.InfosContainer>
                            <img src={getCultureIcon(dataArea?.area_crops?.crop?.culture_name)?.icon} alt="icon" />
                            <img src={getSoilIcon(dataArea?.soil?.soil_type?.name)?.icon} alt="icon" />
                            <p>{`${dataArea?.name} - ${dataArea?.farm?.name}`}</p>

                            {user.role.name !== 'Cliente'
                                && (
                                    <>

                                        <Styles.GroupButton>
                                            <ButtonAction
                                                iconName="more_vert"
                                                iconColor="gray"
                                                style={{ backgroundColor: dropdown ? '#eee' : 'transparent' }}
                                                iconSize={24}
                                                onClick={() => setDropdown(!dropdown)}
                                                hasBackground={false}
                                            />
                                            {dropdown
                                                && (
                                                    <ul className="dropdown">

                                                        <li>
                                                            <ButtonAction
                                                                label="Atualizar"
                                                                iconName="refresh"
                                                                iconColor="gray"
                                                                iconSize={24}
                                                                onClick={handleRefresh}
                                                                hasBackground={false}
                                                            />
                                                        </li>

                                                        <li>
                                                            <ButtonAction
                                                                label={!dataArea.active ? 'Desarquivar' : 'Arquivar'}
                                                                iconName="move_to_inbox"
                                                                iconColor="gray"
                                                                iconSize={24}
                                                                onClick={handleArchived}
                                                                hasBackground={false}
                                                            />
                                                        </li>

                                                        <li>
                                                            <ButtonAction
                                                                label="Editar"
                                                                iconName="edit"
                                                                iconColor="gray"
                                                                iconSize={24}
                                                                onClick={handleEdit}
                                                                hasBackground={false}
                                                            />
                                                        </li>

                                                        <li>
                                                            <ButtonAction
                                                                label="Remover"
                                                                iconName="close"
                                                                iconSize={24}
                                                                onClick={handleDelete}
                                                            />
                                                        </li>
                                                    </ul>
                                                )}
                                        </Styles.GroupButton>
                                    </>
                                )}
                        </Styles.InfosContainer>
                        {!loadingAreaBh
                            ? (
                                <ModalMap
                                    showOne
                                    customHeight="100%"
                                    showControl={false}
                                    mapSuccess={() => false}
                                    sendDataMap={() => []}
                                    imageNDVI={imageNDVI}
                                    closedNDVI={closedNDVI}
                                    handleSetImageNDVI={handleSetImageNDVI}
                                />
                            ) : <Skeleton width={600} height={600} style={{ marginTop: 50 }} />}
                        <span>{`lat, lon (central): ${calculateLatLong().lat}, ${calculateLatLong().long}`}</span>
                        {dataArea.ndvi?.images?.length && <NDVI NDVIS={dataArea.ndvi.images} handleClickImage={handleClickNDVI} onclose={onCloseNDVI} />}

                        {imageNDVI?.show && dataArea?.ndvi?.labels && <img alt="label" className="labelNDVI" src={dataArea?.ndvi?.labels.horizontal} />}
                    </Styles.InfosAreaContainer>
                    <Styles.DescriptionContainer>
                        <ContainerWeather />

                        <Styles.ContainerInfos>
                            <ContainerPlanting />
                            <ContainerFarm />
                        </Styles.ContainerInfos>

                    </Styles.DescriptionContainer>
                </Styles.Header>

                <Styles.ContainerGraphs>
                    {loadingAreaBh ? <ContainerGraphSkeleton />
                        : (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '40%',
                                marginLeft: 50,
                            }}
                            >
                                <>
                                    <label>
                                        Água disponível na zona radicular
                                    </label>
                                    <StackChart
                                        bh={getLastsCadRoot(dataArea?.bh, dataArea?.area_crops?.sowing_date)}
                                        useTitle
                                        height={250}
                                        width={780}
                                        backgroundStyle={{ height: 'auto' }}
                                        fontSize={18}
                                        padding={{
                                            top: 40, bottom: 50, left: 120, right: 125,
                                        }}
                                    />
                                </>
                            </div>
                        )}

                    {loadingAreaBh ? <ContainerGraphSkeleton />
                        : (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '40%',
                            }}
                            >

                                <>
                                    <label>
                                        Condições Meteorológicas
                                    </label>
                                    <CombinedChart
                                        bh={getLastBhMetConditions(dataArea?.bh, dataArea?.area_crops?.sowing_date)}
                                        irrigations={getLastIrrigations(dataArea?.water)}
                                    />
                                </>
                            </div>
                        )}
                </Styles.ContainerGraphs>
            </Styles.CustomContainer>

            <ButtonFloatPivot />
        </>
    );
};

export default Pivot;
