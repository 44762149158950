import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    width:100%;
`;

export const Container = styled.div`
    ${({ theme, fullScreen }) => css`
        margin-left: ${fullScreen ? '130px' : '0'};
        padding: ${theme.spacings.small} ${theme.spacings.large};
    `}
`;
