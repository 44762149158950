import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: ${theme.border.radius};
        max-height: 90vh;
        min-height: 90vh;
        min-width: 90vw;
        max-width: 90vw;
        background-color: ${theme.colors.white};
        overflow: hidden;
    `}
`;

export const Container = styled.div`
   ${({ theme }) => css`
        display: flex;
        background-color: ${theme.colors.white};
        color: black;
        min-height: calc(90vh - 56px - 72px);
        max-height: calc(90vh - 56px - 72px);
        min-width: 90vw;
        max-width: 90vw;
        overflow: auto;
        overflow-x: hidden;

        & > .card {
            width: 100%;
            height: max-content;
            min-height: ${`calc(98% - ${theme.spacings.large})`};
            margin: ${theme.spacings.medium};
        }
  `}
`;

export const Footer = styled.div`
   ${({ theme }) => css`
        height: 72px;
        min-width: 90vw;
        max-width: 90vw;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: ${theme.colors.white};
        border-top: 1px solid ${theme.colors.lightGray};
    `}
`;

export const CardAreaWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    opacity: .6;

    &.selected {
        opacity: 1;
    }
`;

export const CardAreaSelect = styled.div`
    ${({ theme }) => css`
        cursor: pointer;
        position: absolute;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        z-index: 8;
        border-radius: ${theme.border.radius};
        overflow: hidden;

        & img {
            background-color: #00000080;
            border-bottom-left-radius: ${theme.border.radius};
            padding:${theme.spacings.xsmall};
        }
    `}
`;

export const ListArea = styled.div`
    ${({ theme }) => css`
        width: 100%;
        height: max-content;
        padding:${theme.spacings.xsmall};
        display:grid;
        gap: ${theme.spacings.medium};
        grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
    `}
`;

export const ListAreaSkeleton = styled.div`
    ${({ theme }) => css`
        width: 100%;

        & > .list-area-skeleton {
            padding:${theme.spacings.xsmall};
        }
    `}
`;
