import { GraphQLClient } from 'graphql-request';

const API_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT === 'prod' ? process.env.REACT_APP_PROD_API : process.env.REACT_APP_DEV_API;

const endpoint = '/graphql';

const client = new GraphQLClient(API_ENVIRONMENT + endpoint, {
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        'Access-Control-Allow-Headers': '*',
    },
});

export default client;
