import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';

import * as Styles from './styles';

import IconSvg from '../IconSvg';
import IrrigationAddForm from '../IrrigationAddForm';

import iconRain from '../../assets/svgs/icon_rain_pivot.svg';
import floatPivotFooter from '../../assets/svgs/float_pivot_footer.svg';
import iconIrrigation from '../../assets/svgs/icon_irrigation_pivot.svg';

import { areaIrrigationAdd } from '../../store/modules/area/actions';

export default () => {
    const dispatch = useDispatch();

    const ReactSwal = withReactContent(Swal);

    const { dataArea } = useSelector((state) => state.area);
    const { data } = useSelector((state) => state.user);

    const [openMenu, setOpenMenu] = useState('');

    const memoMenuClassName = useMemo(() => {
        if (openMenu === '') return '';
        return openMenu ? 'open' : 'close';
    }, [openMenu]);

    const handleToggleClass = () => setOpenMenu((value) => !value);

    const handleOnClickChildButton = useCallback(async (type) => {
        const handleOnSubmit = (values) => {
            const date = moment(values.date).toISOString();
            const mm = parseFloat(values.mm);
            const areaId = parseInt(dataArea.id);
            const userId = parseInt(data.id);

            if (type === 'Chuva') dispatch(areaIrrigationAdd(mm, null, areaId, userId, date));
            if (type === 'Irrigação') dispatch(areaIrrigationAdd(null, mm, areaId, userId, date));
            ReactSwal.clickConfirm();
        };

        const handleOnCancel = () => ReactSwal.clickCancel();

        ReactSwal.fire({
            title: <p style={{ padding: '1rem', paddingBottom: '0', fontWeight: 'bold' }}>{`Dados de ${type}`}</p>,
            html: <IrrigationAddForm type={type} handleOnSubmit={handleOnSubmit} handleOnCancel={handleOnCancel} />,
            width: 360,
            allowOutsideClick: false,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            reverseButtons: true,
        });
    }, [ReactSwal, dataArea.id, data.id, dispatch]);

    return (
        <Styles.Container onClick={handleToggleClass}>

            <Styles.ChildButtonsContainer className={memoMenuClassName}>

                <Styles.ChildButtonsContent>

                    <Styles.ChildButton onClick={() => handleOnClickChildButton('Chuva')}>
                        <div id="button-2">
                            <IconSvg svgPath={iconRain} />
                        </div>
                        <label htmlFor="button-2">Chuva</label>
                    </Styles.ChildButton>

                    <Styles.ChildButton onClick={() => handleOnClickChildButton('Irrigação')}>
                        <div id="button-1">
                            <IconSvg svgPath={iconIrrigation} />
                        </div>
                        <label htmlFor="button-1">Irrigação</label>
                    </Styles.ChildButton>

                </Styles.ChildButtonsContent>

                <Styles.ChildFooter>
                    <IconSvg svgPath={floatPivotFooter} />
                </Styles.ChildFooter>

            </Styles.ChildButtonsContainer>

            <Styles.Button className={memoMenuClassName} />

        </Styles.Container>
    );
};
