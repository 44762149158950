import React from 'react';
import Skeleton from 'react-loading-skeleton';
import * as StylesPivot from '../../../pages/Main/Pivot/styles';
import ContainerFarmSkeleton from '../ContainerFarmSkeleton';
import ContainerWeatherSkeleton from '../ContainerWeatherSkeleton';
import ContainerGraphSkeleton from '../ContainerGraphSkeleton';

const PagePivotSkeleton = () => {
    const stylesTextArea = { height: '16', width: '220px', marginTop: '8px' };
    const stylesIconsContainer = { display: 'flex' };
    const stylesIcon = { marginRight: '.8rem' };
    const stylesImage = { marginTop: '2rem' };

    return (
        <>
            <StylesPivot.CustomContainer>
                <StylesPivot.Header>
                    <StylesPivot.InfosAreaContainer>
                        <Skeleton width={600} height={100} style={{ ...stylesImage, marginTop: 50 }} />
                        <Skeleton width={600} height={500} style={{ ...stylesImage, marginTop: 50 }} />
                        <Skeleton style={{ ...stylesTextArea, marginTop: 20 }} />
                    </StylesPivot.InfosAreaContainer>

                    <StylesPivot.DescriptionContainer>
                        <ContainerWeatherSkeleton />
                        <StylesPivot.InfosContainer>
                            <div style={stylesIconsContainer}>
                                <Skeleton height={350} width={200} style={stylesIcon} />
                            </div>
                            <ContainerFarmSkeleton />
                        </StylesPivot.InfosContainer>

                    </StylesPivot.DescriptionContainer>
                </StylesPivot.Header>
                <div style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
                }}
                >
                    <ContainerGraphSkeleton />
                    <ContainerGraphSkeleton />
                </div>

            </StylesPivot.CustomContainer>
        </>
    );
};

export default PagePivotSkeleton;
