import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';

export const Wrapper = styled.div`
`;

export const SelectIrrigation = styled.div`
    ${({ theme }) => css`
        span {
            display:block;
            margin-bottom:${theme.spacings.xsmall};
            color:${theme.colors.gray};
            font-size:${theme.font.sizes.xsmall};
        }
    `}
`;

export const Options = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;

    div{
        .tooltip .tooltip-inner {
            width: 7em;
            white-space: normal;
          }
    }
`;

export const Errors = styled.span`
    ${({ theme }) => css`
        display:block;
        color:${theme.colors.danger};
        font-size:${theme.font.sizes.xxxsmall};
        margin: 0 ${theme.spacings.xxsmall};
    `}
`;

export const useStyles = makeStyles(() => ({
    customTooltip: {
        backgroundColor: 'black',
        width: 250,
        fontSize: '1.2rem',
    },
    customArrow: {
        '&:before': {
            backgroundColor: 'black',
        },
    },
}));

export const TextField = styled(Field)`
    ${({ theme }) => css`
    width: 100%;
    color:${theme.colors.primary};
    `}
`;

export const WrapperInput = styled.div`
    ${({ theme }) => css`
        input {
            color:${theme.colors.primary};
        }
    `}
`;
