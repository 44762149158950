export const QUERY_NDVI_REQUEST = /* GraphQL */ `

query GET_NDVI($id:ID!){
    ndvi(id:$id){
        bounds {
            minx,
            miny,
            maxx,
            maxy
        }
            images {
            date,
            url
            }
        }
  }
`;

export const QUERY_NDVI_REQUEST_LABEL = /* GraphQL */ `

query GET_NDVI_LABEL{
    ndviLabel {
        horizontal,
        vertical,
        colormap
      }
  }
`;
