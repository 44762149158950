const REQUEST_EMPLOYEES = /* GraphQL */ `

query REQUEST_EMPLOYEES($id:ID!){
    employees(id:$id){
        id
        name
        email
        active
        role {
          id
          name
        }
        reports_to
        employees {
          id
          name
          active
          email
          role {
            id
            name
          }
          reports_to
          employees {
            id
            name
            active
            email
            role {
              id
              name
            }
            reports_to
            employees {
              id
              name
              active
              email
              role {
                id
                name
              }
              reports_to
              employees {
                id
                name
                active
                email
                role {
                  id
                  name
                }
                reports_to
              }
            }
          }
        }
    }
  }
`;

export default REQUEST_EMPLOYEES;
