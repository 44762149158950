import styled from 'styled-components';

export const UpdateImageDiv = styled.div`
  cursor: pointer;
  position: relative;
  .MuiButton-root {
    min-width: auto;
    line-height: 1rem;
    position: absolute;
    right: 0.5rem;
    bottom: 2rem;
    background: #fff;
    padding: 0.5rem;
    box-shadow: 2px 2px 3px rgb(0, 0, 0, 0.2);
    border-radius: 100%;
  }
  &:hover {
    label {
      box-shadow: unset;
      background: #fff;
    }
    svg {
      transition: all 0.5s ease;
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const ImageProfile = styled.img`
  margin: 0 auto;
  width: 15rem;
  height: 15rem;
  border-radius: 15rem;
  box-shadow:2px 2px 5px rgba(0,0,0,0.5);
`;
