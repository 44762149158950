import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        display:flex;
        align-items:center;
        flex-direction:column;
        margin: 1rem;
        img {
            cursor:pointer;
            width:50px;
            height:50px;
            border-radius:50%;
            background:${theme.colors.white};
            border:1px solid ${theme.colors.gray};

            &:hover {
                box-shadow:2px 2px 5px ${theme.colors.gray};
            }
        } 
        span {
            margin-top:${theme.spacings.xxsmall};
            font-size:${theme.font.sizes.xxsmall};
        }       
    `}
`;

export const ContainerCheck = styled.div`
    position: relative;
`;

export const Check = styled.div`
    position: absolute;
    top: 0;
    left: -0.7rem;
 `;
