import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
    display:flex;
    flex-direction:row;
    align-items: center;
`;

export const NotFound = styled.div`
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
`;
