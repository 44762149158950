import styled, { css } from 'styled-components';
import Background from '../../../components/Background';
import Input from '../../../components/Input';

export const BgContainer = styled(Background)`
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
`;

export const CustomInput = styled(Input)`
    ${({ theme }) => css`
        color:${theme.colors.primary};
    `}
`;

export const Content = styled.div`
    position:relative;
    display:flex;
    min-height:30rem;
    flex-direction:column;
    align-items:center;
    justify-content:center;    
    flex:1;
`;

export const ContainerCode = styled.div`
    ${({ theme }) => css`
        display:flex;
        max-width:30rem;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        
        p{
            color:${theme.colors.primary};
            font-size: 1.2rem;
            margin: ${theme.spacings.xxsmall};
        }
    `}
`;

export const WrapperButton = styled.div`
    ${({ theme }) => css`
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: ${theme.spacings.xxxsmall};
    `}
`;

export const WrapperRecoverButton = styled.div`
    ${({ theme }) => css`
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: ${theme.spacings.xsmall};
    `}
`;

export const RecoverButton = styled.button`
    ${({ theme }) => css`
        cursor:pointer;
        font-size: ${theme.font.sizes.xxxsmall};
        color:${theme.colors.gray};
        background:transparent;
        border-radius:5px;

        &:hover {
            text-decoration: underline;
        }
   
    `}
`;

export const GroupButton = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        padding:${theme.spacings.xxsmall} 0;
        align-items:center;
        justify-content:center;
    `}
`;
