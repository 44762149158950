import { FRAGMENT_GET_FARMS } from '../fragments/farms';

const GET_FARMS_CUSTOMER = /* GraphQL */ `
    query GET_FARMS_CUSTOMER($customerId:ID!){
        users(id:$customerId){
            farms {
                ${FRAGMENT_GET_FARMS}
            }
        }
    }
`;

export default GET_FARMS_CUSTOMER;
