import styled, { css } from 'styled-components';

const ContainerFullProps = () => `
    ${css`
        width:100px;

        .label-full {
            display:none;
        }

        &:hover {
            .labels {
                display:block;
                padding-left:1.5rem;
            }

            width:180px;
            .label-full {
                display:block;
            }
        }
    `}
`;

export const Container = styled.div`
    ${({ theme, fullScreen }) => css`
        position: fixed;
        box-shadow: ${theme.box.shadow};
        left: 0px;
        background-color: ${theme.colors.primary};
        border-top-right-radius: ${theme.border.radius};
        border-bottom-right-radius: ${theme.border.radius};
        top: ${fullScreen ? '0px' : 'calc(( 50% - 181px))'};
        width: ${fullScreen ? '180px' : '70px'};
        height: ${fullScreen ? '100%' : 'auto'};
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: ${theme.spacings.xsmall} 0;
        z-index: 950;
        transition: width 0.15s;

        .user-name{
            display: none;
        }

        .labels{
            display: none;   
        }

        ${fullScreen && ContainerFullProps()}
      
        &.extended-side-menu{
            width: 18rem;
            z-index: 950;
            transition: width 0.3s;
            .user-name{
                display: block;
                text-align:center;
                font-size: ${theme.font.sizes.xxsmall};
                margin-bottom: ${theme.spacings.xxsmall};
                width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .labels{
                display: block;
                margin-top: 0.2rem;
                margin-left: ${theme.spacings.xsmall};
                white-space: nowrap;
                overflow: hidden;
                
            }
        }
    `}
`;

export const ImgPerfil = styled.img`
    ${({ theme, fullScreen }) => css`
        cursor:pointer;
        width: ${fullScreen ? '80px !important' : '52px !important'};
        height: ${fullScreen ? '80px !important' : '52px !important'};
        border-radius: 50%;
        object-fit: cover;
        box-shadow: ${theme.box.shadow};
        margin: ${theme.spacings.xxsmall} 0;
        margin-bottom: ${fullScreen ? theme.spacings.small : '3.3rem'};
        &.extendedMarginImg{
            margin-bottom: ${theme.spacings.xxsmall};
        }
    `}

`;

export const List = styled.ul`
    ${({ fullScreen }) => css`
        width: 100%;
        display: flex;
        flex-direction: ${fullScreen ? 'column' : 'column'};
        justify-content: ${fullScreen ? 'space-evenly' : 'center'};
        align-items: ${fullScreen ? 'center' : 'flex-start'};
        list-style-type: none;
        
    `}
`;

export const ListFullScreen = styled.ul`
    ${({ theme }) => css`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        list-style-type: none;
        margin: ${theme.spacings.xxsmall} 0;
    `}
`;

export const Divider = styled.div`
    ${({ theme }) => css`
        width: 80%;
        height: 1px;
        background-color: ${theme.colors.white};
        opacity: 0.3;
        margin: ${theme.spacings.xsmall} 0.5rem;

        &.content-divider{
            width: 90%;
        }
    `}
`;

export const MenuItem = styled.li`
    ${({ theme }) => css`
        text-align:center;
        display: inline;
        position: relative;
        float: left;
        & img {
            width: 2rem;
            height: 2rem;
        }
        
        & a, div {
            text-decoration:none;
            font-size: ${theme.font.sizes.xxsmall};
            color:#fff;
            display:flex;
            justify-content: center;
            align-items: center;
            padding: ${theme.spacings.xsmall};
            margin: ${theme.spacings.xxsmall};
            border-radius:${theme.border.radius};
            cursor:pointer;
            transition:.5s;

            &:hover{
                transition:.5s;
                background-color:${theme.colors.secondary};
            }
            &.actived-link { 
                background-color:${theme.colors.secondary};
            }
            
        }
        
        &.content-menu{
            width: 100%;
            & a, div {
                justify-content: center;
                margin: ${theme.spacings.xxsmall} ;
            } 
        }
    `}

`;

export const MenuItemFullScreen = styled(MenuItem)`
    ${({ theme }) => css`
        width: 80%;
        margin: .4rem 0;

        & a, div {
            margin: 1.3rem;
        }

        & p {
            font-size: ${theme.font.sizes.xxsmall};
            padding-left:1.5rem;
        }
    `}
`;
